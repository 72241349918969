import axios from 'axios'
import config from '../config/config'
import * as qs from 'qs'
import { generateCodeChallenge, generateCodeVerifier } from '../utilities/encoding'

export const redirectToCognitoPage = () => {
  const codeVerifier = generateCodeVerifier()
  const codeChallenge = generateCodeChallenge(codeVerifier)
  localStorage.removeItem('token')
  localStorage.removeItem('refresh')
  localStorage.removeItem('lastAuth')
  localStorage.removeItem('expiresIn')
  localStorage.setItem('authenticated', 'false')
  localStorage.setItem('codeVerifier', codeVerifier)
  localStorage.setItem('codeChallenge', codeChallenge)
  const authURL = `${config.cognito.BASE_URL}/oauth2/authorize?response_type=code&client_id=${config.cognito.APP_CLIENT_ID}&redirect_uri=${config.cognito.REDIRECT}&code_challenge=${codeChallenge}&code_challenge_method=S256`

  window.location.replace(authURL)
}

export const awsLogout = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('refresh')
  localStorage.removeItem('lastAuth')
  localStorage.removeItem('expiresIn')
  localStorage.setItem('authenticated', 'false')
  const authURL = `${config.cognito.BASE_URL}/logout?response_type=code&client_id=${config.cognito.APP_CLIENT_ID}&redirect_uri=${config.cognito.REDIRECT}`

  window.location.replace(authURL)
}

export async function awsCallTokenEndpoint(grantType, accessToken, codeVerifier) {
  const data = {
    grant_type: grantType,
    client_id: config.cognito.APP_CLIENT_ID,
    code: accessToken,
    scope: 'profile',
    redirect_uri: config.cognito.REDIRECT,
    code_verifier: codeVerifier,
  }

  const p = {
    method: 'post',
    url: `${config.cognito.BASE_URL}/oauth2/token`,
    data: qs.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }
  const awsResponse = await axios(p)

  if (awsResponse.status === 200) {
    return awsResponse?.data
  } else {
    console.log('error')
  }
}

export async function awsCallTokenRefresh(grantType, refreshToken) {
  const data = {
    grant_type: grantType,
    client_id: config.cognito.APP_CLIENT_ID,
    refresh_token: refreshToken,
  }

  const p = {
    method: 'post',
    url: `${config.cognito.BASE_URL}/oauth2/token`,
    data: qs.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }
  const awsResponse = await axios(p)

  if (awsResponse.status === 200) {
    return awsResponse?.data
  } else {
    console.log('error')
  }
}

export const getAwsToken = async (requestCallback) => {
  const data = await requestCallback()

  localStorage.setItem('token', data.access_token)
  if (data && data.refresh_token) localStorage.setItem('refresh', data.refresh_token)
  localStorage.setItem('authenticated', 'true')
  localStorage.setItem('lastAuth', JSON.stringify(Number(new Date())))
  localStorage.setItem('expiresIn', JSON.stringify(data.expires_in * 1000))
}

export const isAuthExpired = () => {
  const lastAuth = localStorage.getItem('lastAuth')
  const expiresIn = localStorage.getItem('expiresIn')

  if (new Date() - lastAuth > expiresIn - 300000) return true
  else return false
}
