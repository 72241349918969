import instance from './axiosConfig'

class SuspendingService {
  async suspendSystem() {
    return await instance.post(`/api/suspend/system`)
  }

  async activateSystem() {
    return await instance.delete(`/api/suspend/system`)
  }

  async suspendClient(cid) {
    return await instance.post(`/api/suspend/client/${cid}`)
  }

  async activateClient(cid) {
    return await instance.delete(`/api/suspend/client/${cid}`)
  }

  async suspendClientSymbol(cid, symbol) {
    return await instance.post(`/api/suspend/client/${cid}/symbol/${symbol}`)
  }

  async activateClientSymbol(cid, symbol) {
    return await instance.delete(`/api/suspend/client/${cid}/symbol/${symbol}`)
  }

  async suspendSpeculation() {
    return await instance.post(`/api/suspend/speculation`)
  }

  async activateSpeculation() {
    return await instance.delete(`/api/suspend/speculation`)
  }
}

const suspendingService = new SuspendingService()
export default suspendingService
