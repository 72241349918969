import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import SecurityIcon from '@material-ui/icons/Security'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { BUY, SELL } from '../../../../model/OrderSide'
import { LONG, SHORT } from '../../../../model/RequestType'
import ClosePositionButton from './ClosePositionButton/ClosePositionButton'
import MarginDialog from './MarginDialog/MarginDialog'
import s from './OpenPosition.module.css'
import { Add, Remove } from '@material-ui/icons'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import { TYPE_FUTURES, TYPE_SPOT } from '../../../../model/Exchange'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Collapse } from '@material-ui/core'
import marketService from '../../../../service/MarketService'

const POSITION_ACTION = {
  ADD: 'add',
  REMOVE: 'remove',
}

const SIDE_MAP = {
  [POSITION_ACTION.ADD]: {
    [SHORT]: SELL,
    [LONG]: BUY,
  },
  [POSITION_ACTION.REMOVE]: {
    [SHORT]: BUY,
    [LONG]: SELL,
  }
}

const OpenPosition = ({ position, client, cid, changeClientPositionState, updatePositionRequest }) => {
  const [marginDialog, setMarginDialog] = useState({ open: false, action: 'remove' })
  const [expanded, setExpanded] = useState(false)
  const [markPrice, setMarkPrice] = useState(0)

  const handleMarginClose = () => {
    setMarginDialog((prev) => ({ ...prev, open: false }))
  }

  const handleMarginOpen = (action) => {
    setMarginDialog({ action, open: true })
  }

  const handleExpanded = () => {
    setExpanded((prev) => !prev)
  }

  const isFuturesFutures = useMemo(
    () =>
      client.leftAccount.exchangeType === TYPE_FUTURES &&
      client.rightAccount.exchangeType === TYPE_FUTURES,
    [],
  )

  const leftData = useMemo(
    () => {
      return position.exchangeSide === 'LEFT'
        ? position
        : client.leftAccount.openPositions.find((pos) => pos.symbol === position.symbol)
    },
    [position, client.leftAccount.openPositions],
  )

  const rightData = useMemo(
    () =>
      position.exchangeSide === 'RIGHT'
        ? position
        : client.rightAccount.openPositions.find((pos) => pos.symbol === position.symbol) ||
          undefined,
    [position, client.rightAccount.openPositions],
  )

  useEffect(() => {
    let newMarkPrice = leftData?.markPrice || rightData?.markPrice
    if (newMarkPrice) {
      setMarkPrice(newMarkPrice)
      return
    }

    const fetchPrice = async () => {
      const data = await marketService.getMarketPrice(`${position.baseAsset}${position.quoteAsset}`, position.exchange)
      setMarkPrice(data?.askPrice)
    }
    fetchPrice()
  }, [position.symbol, leftData?.markPrice, rightData?.markPrice])

  const rightLeverage = rightData?.isolated ? `x${rightData?.leverage}` : '-'

  const positionValue = (
    Math.abs(leftData?.currentPosition || rightData?.currentPosition) * markPrice
  )?.toLocaleString('en-US')

  let currentPositionColor = 'text.primary'
  let spotPosition = null
  if (client.rightAccount.exchangeType === TYPE_SPOT) {
    spotPosition = Number(client.rightAccount?.balance[position.baseAsset]?.total) || 0
    if (position.positionType === LONG) {
      spotPosition = (spotPosition - Number(position.investedAmount)).toFixed(3)
    }
  } else {
    spotPosition = Number(rightData?.currentPosition)
  }

  if (spotPosition) {
    const futuresAbsPos = Math.abs(Number(leftData?.currentPosition) || 0)
    const spotAbsPos = Math.abs(spotPosition)
    const difference = Math.abs((futuresAbsPos - spotAbsPos) / ((futuresAbsPos + spotAbsPos) / 2))
    if (difference >= 0.005) {
      currentPositionColor = 'error.main'
    }
  }

  const getHealthColor = (liquidationPercent) => {
    if (liquidationPercent) {
      const num = Number(liquidationPercent)
      if (num > 60.0) {
        return 'success.main'
      } else if (num < 20.0) {
        return 'error.main'
      }
      return 'text.primary'
    }
  }

  const type = Number(position.currentPosition) > 0 ? LONG : SHORT

  const leftLiquidation =
    leftData?.liquidationPercent === 99900
      ? 'Max'
      : leftData?.liquidationPercent?.toLocaleString('en-US')
  const rightLiquidation =
    rightData?.liquidationPercent === 99900
      ? 'Max'
      : rightData?.liquidationPercent?.toLocaleString('en-US')

  const updatePositionRequestHandler = useCallback((e) => {
    const action = e.currentTarget.value
    const type = Number(position.currentPosition) > 0 ? LONG : SHORT
    const side = SIDE_MAP[action][type]

    updatePositionRequest(type, side, position)
  }, [position, updatePositionRequest])

  return (
    <Paper
      elevation={6}
      className={s.root}
      style={{
        padding: 10,
        margin: '6px 0',
        backgroundColor: position.brokenPosition ? 'rgba(255, 236, 0, 0.225)' : '',
        transition: 'all .1s',
        maxHeight: 'auto',
        flexWrap: 'wrap',
      }}
    >
      <div className={s.column}>
        <Typography variant="body1" gutterBottom>
          Symbol: {position.symbol}&nbsp; ({leftData?.leverage ? `x${leftData?.leverage}` : '-'}
          {isFuturesFutures && ` / ${rightLeverage}`})&nbsp;
          <div className="centered-line">
            <Box
              color={getHealthColor(leftData?.liquidationPercent)}
              style={{ display: 'inline-block' }}
            >
              {leftLiquidation || '-'}
            </Box>
            &nbsp;/&nbsp;
            <Box
              color={getHealthColor(rightData?.liquidationPercent)}
              style={{ display: 'inline-block' }}
            >
              {rightLiquidation || '-'}
            </Box>
            &nbsp;%
          </div>
        </Typography>
        <Collapse in={expanded}>
          <Typography variant="body1" gutterBottom>
            Defence Trigger:&nbsp;
            {(
              leftData?.defenceInfo?.defenceTrigger || rightData?.defenceInfo?.defenceTrigger
            )?.toLocaleString('en-US') || '-'}{' '}
            %
          </Typography>
          <Typography variant="body1" gutterBottom>
            Defence Amount:&nbsp;
            {(
              leftData?.defenceInfo?.defenceAmount || rightData?.defenceInfo?.defenceAmount
            )?.toLocaleString('en-US') || '-'}{' '}
            %
          </Typography>
        </Collapse>
      </div>
      <div className={s.column}>
        <Typography variant="body1" gutterBottom>
          Position value: {positionValue}
        </Typography>
        <Collapse in={expanded}>
          <Typography variant="body1" gutterBottom>
            Margin: {(parseFloat(leftData?.margin?.toFixed(2)) || '-')?.toLocaleString('en-US')}
            {' / '}
            {(parseFloat(rightData?.margin?.toFixed(2)) || '-')?.toLocaleString('en-US') || '-'}
            &nbsp;
            {!position.isolated && '(Cross)'}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Unrealized Profit:
            {leftData?.unrealizedProfit
              ? ` ${parseFloat(leftData?.unrealizedProfit?.toFixed(2)).toLocaleString('en-US')}`
              : ' -'}
            {' / '}
            {rightData?.unrealizedProfit
              ? ` ${parseFloat(rightData?.unrealizedProfit?.toFixed(2)).toLocaleString('en-US')}`
              : ' -'}{' '}
            USDT
          </Typography>
          <Typography variant="body1" gutterBottom>
            Earned Fee:&nbsp;
            {`${leftData?.earnedFunding?.amount?.toLocaleString('en-US') || '-'}
            / ${rightData?.earnedFunding?.amount?.toLocaleString('en-US') || '-'} USDT`}
          </Typography>
        </Collapse>
      </div>
      <div className={s.column}>
        <Typography component="div" variant="body1" gutterBottom>
          Current Position: &nbsp;
          <Box color={currentPositionColor} style={{ display: 'inline-block' }}>
            {leftData?.currentPosition?.toLocaleString('en-US') || '-'}
            {' / '}
            {isFuturesFutures
              ? rightData?.currentPosition?.toLocaleString('en-US') || '-'
              : client.rightAccount.balance[position.baseAsset]?.total?.toLocaleString('en-US') || '-'
            }
          </Box>
        </Typography>
        <Collapse in={expanded}>
          <Typography variant="body1" gutterBottom>
            Liquidation Price:&nbsp;
            {leftData?.liquidationPrice?.toLocaleString('en-US') || '-'}
            {' / '}
            {rightData?.liquidationPrice?.toLocaleString('en-US') || '-'}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Mark Price: {markPrice?.toLocaleString('en-US')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Entry Price:&nbsp;
            {leftData?.entryPrice?.toLocaleString('en-US') || '-'}
            {' / '}
            {rightData?.entryPrice?.toLocaleString('en-US') || '-'}
          </Typography>
        </Collapse>
      </div>
      <div className={s.column}>
        <div className={s.actionButtons}>
          <Tooltip title={'Increase position'}>
            <Button
              className={s.action}
              variant="contained"
              color="primary"
              value={POSITION_ACTION.ADD}
              onClick={updatePositionRequestHandler}
            >
              Add
            </Button>
          </Tooltip>
          <Tooltip title={'Remove/Decrease position'}>
            <Button
              className={s.action}
              variant="contained"
              color="secondary"
              value={POSITION_ACTION.REMOVE}
              onClick={updatePositionRequestHandler}
            >
              Remove
            </Button>
          </Tooltip>
          <Button
            className={s.action}
            variant="contained"
            component={Link}
            to={`/client/${cid}/symbol/${position.symbol}`}
          >
            Configure
          </Button>
          <ClosePositionButton
            className={s.action}
            client={client}
            position={position}
            markPrice={markPrice}
          />
          {position.requestExecuting && (
            <Tooltip title={'Request is running'}>
              <AutorenewIcon style={{ marginRight: 20 }} />
            </Tooltip>
          )}
          <Tooltip
            title={
              position.suspended
                ? `Activate Symbol ${position.symbol}`
                : `Disable Symbol ${position.symbol}`
            }
          >
            <IconButton
              className={s.iconButton}
              component="span"
              onClick={() => changeClientPositionState(cid, position)}
            >
              <PowerSettingsNewIcon style={{ color: position.suspended ? red[700] : green[500] }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={position.defence3Enabled ? `Defence 3 Enabled` : `Defence 3 Disabled`}>
            <IconButton className={s.iconButton} component="span" disabled>
              <SecurityIcon style={{ color: position.defence3Enabled ? green[500] : red[700] }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Add margin'}>
            <IconButton
              className={s.iconButton}
              component="span"
              disabled={position.exchangeSide === 'RIGHT'}
              onClick={() => handleMarginOpen('add')}
            >
              <Add />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Remove margin'}>
            <IconButton
              className={s.iconButton}
              component="span"
              disabled={position.exchangeSide === 'RIGHT'}
              onClick={() => handleMarginOpen('remove')}
            >
              <Remove />
            </IconButton>
          </Tooltip>
          <IconButton
            className={s.iconButton}
            style={{
              transform: expanded ? 'rotate(180deg)' : '',
              transition: '.1s',
            }}
            onClick={handleExpanded}
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
      </div>

      {position.exchangeSide === 'LEFT' && (
        <MarginDialog
          open={marginDialog.open}
          guid={client.guid}
          futuresBalance={client.leftAccount.balance}
          symbol={position.symbol}
          exchange={client.leftAccount.exchange}
          action={marginDialog.action}
          onClose={handleMarginClose}
          onSuccess={() => {}}
        />
      )}
    </Paper>
  )
}

export default OpenPosition
