import React from 'react'
import requestService from '../../../service/RequestService'

import RequestListView from '../RequestListView/RequestListView'

const ActiveRequests = () => {
  const [activeCount, setActiveCount] = React.useState(0)

  const getRequests = async (page) => {
    const data = await requestService.getActiveRequest(page - 1)
    const requests = data.content
    setActiveCount(requests.length)
    return data
  }

  return (
    <RequestListView requestCallback={getRequests} title={`Active requests - ${activeCount}`} />
  )
}

export default ActiveRequests
