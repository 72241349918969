import {
  Collapse,
  InputAdornment,
  List,
  ListItem,
  Paper,
  Popover,
  TextField,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useMemo, useState } from 'react'
import { useAsyncDebounce } from 'react-table'

const SelectQueryFilter = ({ column: { filterValue, preFilteredRows, setFilter, id } }) => {
  const [value, setValue] = useState(filterValue)
  const [anchor, setAnchor] = useState(null)
  const options = useMemo(
    () => [...new Set(preFilteredRows?.map((row) => row.values[id]))],
    [preFilteredRows, id],
  )

  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined)
  }, 200)

  const onSelect = (option) => {
    if (filterValue === option) {
      setFilter(null)
      setValue('')
      return
    }

    setFilter(option)
    setValue(option)
  }

  const handlePopoverClose = () => setAnchor(null)
  const handlePopoverOpen = (e) => {
    setAnchor(e.currentTarget.parentNode)
  }

  const showSelect = Boolean(anchor)

  return (
    <>
      <TextField
        size="small"
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
        placeholder={`${options.length} items...`}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              style={{ paddingRight: '-14px' }}
              onClick={handlePopoverOpen}
            >
              <ArrowDropDownIcon
                style={{
                  cursor: 'pointer',
                  transition: 'all 0.2s',
                  transform: showSelect ? 'none' : 'rotate(90deg)',
                }}
              />
            </InputAdornment>
          ),
        }}
        inputProps={{ style: { fontSize: 12, maxWidth: 125, marginBottom: -3 } }}
        style={{ marginBottom: 8 }}
      />
      <Popover
        id="popover"
        open={showSelect}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Collapse in={showSelect} timeout="auto" unmountOnExit style={{ border: 'none' }}>
          <Paper elevation={1}>
            <List disablePadding style={{ maxHeight: 150, maxWidth: 150, overflow: 'auto' }}>
              {options?.map((option, i) => (
                <ListItem
                  key={i}
                  selected={filterValue === option}
                  button
                  onClick={() => {
                    onSelect(option)
                  }}
                >
                  {option}
                </ListItem>
              ))}
            </List>
          </Paper>
        </Collapse>
      </Popover>
    </>
  )
}

export default SelectQueryFilter
