import instance from './axiosConfig'

class FuturesService {
  async getRemovableMargin(symbol, guid, exchange) {
    const response = await instance.get(
      `/api/futures/margin?symbol=${symbol}&guid=${guid}&exchange=${exchange}`,
    )
    return response.data
  }

  async getBinanceFuturesBalance(guid) {
    const response = await instance.get(`/api/futures/binance/balance?clientIdentifier=${guid}`)
    return response.data
  }

  async addMarginToPosition(symbol, guid, amount) {
    const response = await instance.post(`/api/futures/margin`, { symbol, guid, amount })
    return response.data
  }

  async removeMarginFromPosition(symbol, guid, amount) {
    const response = await instance.delete(`/api/futures/margin`, {
      data: { symbol, guid, amount },
    })
    return response.data
  }
}

const futuresService = new FuturesService()
export default futuresService
