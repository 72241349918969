import React, { useCallback, useEffect, useRef, useState } from 'react'
import s from './Header.module.css'
import Button from '@material-ui/core/Button'
import { Link, useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { Helmet } from 'react-helmet'
import NotificationsIcon from '@material-ui/icons/Notifications'
import Badge from '@material-ui/core/Badge'
import webSocketService, { REQUEST_TOPIC } from '../../service/WebSocketService'
import { useDispatch, useSelector } from 'react-redux'
import { getInfo } from '../../redux/thunks/system-thunks'
import { systemActions } from '../../redux/slices/system-slice'
import { awsLogout } from '../../service/auth-api'
import { Popover } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { menuItems } from './config'

const Header = () => {
  const info = useSelector((state) => state.system)

  const dispatch = useDispatch()
  const history = useHistory()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleActiveCountChange = useCallback((count) => {
    if (count !== undefined && count !== null) {
      dispatch(systemActions.setActiveRequestCount(count))
    }
  }, [])

  useEffect(() => {
    let id

    async function subscribe() {
      id = webSocketService.subscribe(REQUEST_TOPIC, (message) =>
        handleActiveCountChange(message.activeCount),
      )
      dispatch(getInfo())
    }
    subscribe()

    return function cleanup() {
      webSocketService.unsubscribe(REQUEST_TOPIC, id)
    }
  }, [])

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const openMenu = Boolean(anchorEl)

  return (
    <div className={'centered-line justify-between ' + s.header}>
      <Helmet>
        <title>{`${info.environment} Funding Application`}</title>
      </Helmet>
      <Typography variant="body1" className={s.title} onClick={() => history.push('/')}>
        Environment {info.environment}
      </Typography>
      <div className={`centered-line` + s.actions}>
        <Tooltip title="Active Requests">
          <IconButton
            color="primary"
            aria-label="show active requests"
            component={Link}
            to={`/request/active`}
          >
            <Badge badgeContent={info.activeRequestCount} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Tooltip>
        <Tooltip title={'More actions'}>
          <IconButton
            style={{ marginLeft: 10 }}
            color="primary"
            aria-label="delete client"
            onClick={handleMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        <Popover
          id={openMenu ? 'actions' : undefined}
          open={openMenu}
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className={s.popover}>
            {menuItems.map(({ to, name }) => (
              <Button
                key={to}
                style={{ justifyContent: 'flex-start' }}
                color="primary"
                component={Link}
                to={to}
              >
                {name}
              </Button>
            ))}
            <Button style={{ justifyContent: 'flex-start' }} color="secondary" onClick={awsLogout}>
              Log Out
            </Button>
          </div>
        </Popover>
      </div>
    </div>
  )
}

export default Header
