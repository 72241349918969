import Button from '@material-ui/core/Button'
import React, { useEffect, useState } from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core'
import userService from '../../../../service/UserService'
import OrderWindow from './OrderWindow/OrderWindow'
import ValidationDialog from '../../../UserManagement/ValidationDialog/ValidationDialog'
import PopUp from '../../../common/PopUp'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const OrderExecutionButton = ({ client }) => {
  const classes = useStyles()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [authenticated, setAuthenticated] = useState(false)
  const [showValidate, setShowValidate] = useState(false)
  const [showOrder, setShowOrder] = useState(false)
  const [popup, setPopup] = useState({
    message: '',
    severity: 'success',
    open: false,
  })

  useEffect(() => {
    if (authenticated) {
      setShowOrder(true)
    }
  }, [authenticated])

  const authenticateCode = async (otpCode) => {
    const response = await userService.mfaAuthenticate(otpCode)

    if (response.status === 200) {
      setPopup({ open: true, severity: 'success', message: 'Success' })
      setShowValidate(false)
      setAuthenticated(true)
      return
    }
    if (response.status === 500) {
      setShowValidate(false)
      setAuthenticated(false)
      history.push('/user-management')
      return
    }
    setPopup({
      open: true,
      severity: 'error',
      message: 'Validation error: ' + response.data.message,
    })
  }

  const openOrderWindow = async () => {
    if (authenticated) {
      setShowOrder(true)
      return
    }

    setLoading(true)
    try {
      const user = await userService.getUser()
      if (user?.authenticated) {
        setAuthenticated(true)
      } else {
        setShowValidate(true)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleOrderSuccess = (response) => {
    const { data } = response
    setShowOrder(false)

    const message = (
      <div>
        {data?.side} order success
        <br />
        Executed amount: {data?.executedAmount}
        <br />
        Average price: {data?.price}
        <br />
        Order ID: {data?.orderId}
        <br />
      </div>
    )
    setPopup({ open: true, severity: 'success', message })
  }

  const handleCloseOrder = () => {
    setShowOrder(false)
  }

  const handleCloseValidate = () => {
    setShowValidate(false)
  }

  const handleUnauthorized = (error) => {
    setAuthenticated(false)
    setShowValidate(true)
    setShowOrder(false)
    setPopup({
      message: `Could not place order. ${error}`,
      severity: 'error',
      open: true,
    })
  }

  const closePopup = () => setPopup((prev) => ({ ...prev, open: false }))

  return (
    <React.Fragment>
      <PopUp {...popup} closePopup={closePopup} />
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Button style={{ justifyContent: 'flex-start' }} color="primary" onClick={openOrderWindow}>
        Order
      </Button>
      <OrderWindow
        client={client}
        open={showOrder}
        onClose={handleCloseOrder}
        onSuccess={handleOrderSuccess}
        handleUnauthorized={handleUnauthorized}
      />
      <ValidationDialog
        open={showValidate}
        handleSubmit={authenticateCode}
        handleClose={handleCloseValidate}
      />
    </React.Fragment>
  )
}

export default OrderExecutionButton
