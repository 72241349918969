import React, { useEffect, useState } from 'react'
import s from './ConfigurationView.module.css'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import configurationService from '../../service/ConfigurationService'
import {
  DEFENCE_AMOUNT,
  DEFENCE_TRIGGER,
  HEALTH_NOTIFICATION_ENABLED,
  POSITION_CLOSE_AMOUNT_LIMIT,
  POSITION_HEALTH_HIGHEST,
  POSITION_HEALTH_LOWEST,
  PREMIUM_SLIPPAGE,
  SPECULATION_CLIENT_ID,
  SPECULATION_LEVERAGE,
  SPECULATION_LOT_AMOUNT,
  SPECULATION_REQUEST_AMOUNT,
  SPECULATION_TIMEOUT_MINUTES,
} from '../../model/ConfigurationKey'
import BackButton from '../common/BackButton'
import PopUp from '../common/PopUp'
import { useSelector, useDispatch } from 'react-redux'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import IconButton from '@material-ui/core/IconButton'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import Tooltip from '@material-ui/core/Tooltip'
import suspendingService from '../../service/SuspendingService'
import { systemActions } from '../../redux/slices/system-slice'
import LoadingScreen from '../common/LoadingScreen'
import { CircularProgress } from '@material-ui/core'
import { parseBoolean } from '../../utilities/number-helpers'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import ExchangeConfiguration from './ExchangeConfiguration/ExchangeConfiguration'

export const ConfigurationView = () => {
  const info = useSelector((state) => state.system)
  const { suspended, speculationSuspended } = info

  const [state, setState] = useState({
    defenceTrigger: '',
    defenceAmount: '',
    usernames: '',
    healthLowest: '',
    healthHighest: '',
    premiumSlippage: '',
    closeAmountLimit: '',
    healthNotificationEnabled: false,
    popup: {
      message: '',
      severity: 'success',
      open: false,
    },
    isLoading: false,
    isSaving: false,
  })

  const [speculation, setSpeculation] = useState({
    cid: '',
    leverage: '',
    requestAmount: '',
    lotAmount: '',
    timeout: '',
  })

  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      setState((prev) => ({ ...prev, isLoading: true }))

      const config = await configurationService.getFullConfig()
      setState((prev) => ({
        ...prev,
        defenceTrigger: config.globalConfig[DEFENCE_TRIGGER],
        defenceAmount: config.globalConfig[DEFENCE_AMOUNT],
        usernames: config.adminNotificationUsernames,
        healthLowest: config.globalConfig[POSITION_HEALTH_LOWEST],
        healthHighest: config.globalConfig[POSITION_HEALTH_HIGHEST],
        isLoading: false,
        premiumSlippage: config.globalConfig[PREMIUM_SLIPPAGE],
        closeAmountLimit: config.globalConfig[POSITION_CLOSE_AMOUNT_LIMIT],
        healthNotificationEnabled: parseBoolean(config.globalConfig[HEALTH_NOTIFICATION_ENABLED]),
      }))
      setSpeculation({
        cid: config.globalConfig[SPECULATION_CLIENT_ID],
        leverage: config.globalConfig[SPECULATION_LEVERAGE],
        requestAmount: config.globalConfig[SPECULATION_REQUEST_AMOUNT],
        lotAmount: config.globalConfig[SPECULATION_LOT_AMOUNT],
        timeout: config.globalConfig[SPECULATION_TIMEOUT_MINUTES],
      })
    })()
  }, [])

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.id
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSpeculationChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.id
    setSpeculation((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const updateConfig = async () => {
    try {
      if (!state.defenceAmount || !state.defenceTrigger) {
        setState((prev) => ({
          ...prev,
          popup: {
            message: 'Defence amoune and Defence trigger are required',
            severity: 'error',
            open: true,
          },
        }))
        return
      }
      setState((prev) => ({ ...prev, isSaving: true }))
      const config = {
        adminNotificationUsernames: state.usernames,
        globalConfig: {
          DEFENCE_TRIGGER: state.defenceTrigger,
          DEFENCE_AMOUNT: state.defenceAmount,
          POSITION_HEALTH_LOWEST: state.healthLowest,
          POSITION_HEALTH_HIGHEST: state.healthHighest,
          PREMIUM_SLIPPAGE: state.premiumSlippage,
          POSITION_CLOSE_AMOUNT_LIMIT: state.closeAmountLimit,
          SPECULATION_CLIENT_ID: speculation.cid,
          SPECULATION_LEVERAGE: speculation.leverage,
          SPECULATION_REQUEST_AMOUNT: speculation.requestAmount,
          SPECULATION_LOT_AMOUNT: speculation.lotAmount,
          SPECULATION_TIMEOUT_MINUTES: speculation.timeout,
          HEALTH_NOTIFICATION_ENABLED: state.healthNotificationEnabled.toString(),
        },
      }
      await configurationService.updateConfig(config)
      dispatch(systemActions.setInfo({ ...info, closeAmountLimit: Number(state.closeAmountLimit) }))
      setState({
        ...state,
        popup: {
          message: 'Configuration successfully update',
          severity: 'success',
          open: true,
        },
        isSaving: false,
      })
    } catch (e) {
      setState({
        ...state,
        popup: {
          message: 'Failed to update configuration: ' + e,
          severity: 'error',
          open: true,
        },
        isSaving: false,
      })
    }
  }

  const closePopup = () => {
    setState((prevState) => ({
      ...prevState,
      popup: {
        ...prevState.popup,
        open: false,
      },
    }))
  }

  const changeSystemState = async () => {
    if (suspended) {
      await suspendingService.activateSystem()
    } else {
      await suspendingService.suspendSystem()
    }
    dispatch(systemActions.setInfo({ ...info, suspended: !suspended }))
  }

  const changeSpeculationStatus = async () => {
    if (speculationSuspended) {
      await suspendingService.activateSpeculation()
    } else {
      await suspendingService.suspendSpeculation()
    }
    dispatch(systemActions.setInfo({ ...info, speculationSuspended: !speculationSuspended }))
  }

  if (state.isLoading) return <LoadingScreen />

  return (
    <Paper elevation={8} className={s.root}>
      <PopUp {...state.popup} closePopup={() => closePopup()} />
      <div className="centered-line justify-between">
        <Typography variant="h4">Global Configuration</Typography>
        <BackButton />
      </div>
      <div className="flex-line justify-center" style={{ gap: '2em' }}>
        <ExchangeConfiguration />
        <div className={'column align-center'} style={{ marginTop: 12 }}>
          <div className="centered-line">
            <Typography variant="h6" style={{ marginRight: 8 }}>
              System status:
            </Typography>
            <Tooltip title={suspended ? 'Activate System' : 'Disable System'} arrow>
              <IconButton aria-label="add an alarm" size={'small'} onClick={changeSystemState}>
                <PowerSettingsNewIcon
                  style={{ color: suspended ? red[700] : green[500] }}
                  fontSize="large"
                />
              </IconButton>
            </Tooltip>
          </div>
          <TextField
            className={s.input}
            id="defenceTrigger"
            label="Defence Trigger %"
            margin="normal"
            type="number"
            value={state.defenceTrigger}
            onChange={handleInputChange}
            required
          />
          <TextField
            className={s.input}
            id="defenceAmount"
            label="Defence Amount %"
            margin="normal"
            type="number"
            value={state.defenceAmount}
            onChange={handleInputChange}
            required
          />
          <TextField
            className={s.input}
            id="usernames"
            label="Notification Usernames"
            margin="normal"
            value={state.usernames}
            onChange={handleInputChange}
          />
          <TextField
            className={s.input}
            id="healthLowest"
            label="Position Health Lowest %"
            margin="normal"
            type="number"
            value={state.healthLowest}
            onChange={handleInputChange}
          />
          <TextField
            className={s.input}
            id="healthHighest"
            label="Position Health Highest %"
            margin="normal"
            type="number"
            value={state.healthHighest}
            onChange={handleInputChange}
          />
          <TextField
            className={s.input}
            id="premiumSlippage"
            label="Premium Slippage %"
            margin="normal"
            type="number"
            value={state.premiumSlippage}
            onChange={handleInputChange}
          />
          <TextField
            className={s.input}
            id="closeAmountLimit"
            label="Close Amount Limit (USDT)"
            margin="normal"
            type="number"
            value={state.closeAmountLimit}
            onChange={handleInputChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                id="healthNotificationEnabled"
                checked={state.healthNotificationEnabled}
              />
            }
            label="Health Notifications"
            onChange={handleInputChange}
          />
        </div>
        <div className={'column align-center'} style={{ marginTop: 12 }}>
          <div className="centered-line">
            <Typography variant="h6" style={{ marginRight: 8 }}>
              Speculation status:
            </Typography>
            <Tooltip
              title={speculationSuspended ? 'Activate Speculation' : 'Disable Speculation'}
              arrow
            >
              <IconButton
                aria-label="add an alarm"
                size={'small'}
                onClick={changeSpeculationStatus}
              >
                <PowerSettingsNewIcon
                  style={{ color: speculationSuspended ? red[700] : green[500] }}
                  fontSize="large"
                />
              </IconButton>
            </Tooltip>
          </div>
          <TextField
            className={s.input}
            id="cid"
            label="Speculation CID"
            margin="normal"
            value={speculation.cid}
            onChange={handleSpeculationChange}
          />
          <TextField
            className={s.input}
            id="leverage"
            label="Speculation Leverage"
            margin="normal"
            type="number"
            value={speculation.leverage}
            onChange={handleSpeculationChange}
          />
          <TextField
            className={s.input}
            id="requestAmount"
            label="Speculation Request Amount"
            margin="normal"
            type="number"
            value={speculation.requestAmount}
            onChange={handleSpeculationChange}
          />
          <TextField
            className={s.input}
            id="lotAmount"
            label="Speculation Lot Amount"
            margin="normal"
            type="number"
            value={speculation.lotAmount}
            onChange={handleSpeculationChange}
          />
          <TextField
            className={s.input}
            id="timeout"
            label="Speculation Timeout (Minutes)"
            margin="normal"
            type="number"
            value={speculation.timeout}
            onChange={handleSpeculationChange}
          />
        </div>
      </div>
      <Button
        style={{ margin: '16px auto' }}
        variant="contained"
        color="primary"
        onClick={updateConfig}
        startIcon={
          <>{state.isSaving && <CircularProgress size={14} style={{ color: 'white' }} />}</>
        }
      >
        Update
      </Button>
    </Paper>
  )
}

export default ConfigurationView
