import Dialog from '@material-ui/core/Dialog'
import s from './OrderWindow.module.css'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import TextField from '@material-ui/core/TextField'
import { USDT } from '../../../../../model/Asset'
import Button from '@material-ui/core/Button'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import PopUp from '../../../../common/PopUp'
import { Checkbox, FormControlLabel, MenuItem } from '@material-ui/core'
import ordersService from '../../../../../service/OrdersService'
import marketService from '../../../../../service/MarketService'
import debounce from 'lodash.debounce'
import { useHistory } from 'react-router-dom'
import userService from '../../../../../service/UserService'

const titleStyle = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    fontWeight: 'bold',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const EXCHANGE_TYPES = [
  {
    label: 'Left',
    value: 'leftAccount',
  },
  {
    label: 'Right',
    value: 'rightAccount',
  },
]

const DialogTitle = withStyles(titleStyle)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const initialState = {
  exchangeType: EXCHANGE_TYPES[0].value,
  symbol: '',
  amount: '',
  side: '',
  reduceOnly: false,
}

const OrderWindow = ({ onClose, onSuccess, open, client, handleUnauthorized }) => {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [marketPrice, setMarketPrice] = useState(null)
  const [values, setValues] = useState(initialState)
  const [popup, setPopup] = useState({
    message: '',
    severity: 'success',
    open: false,
  })

  useEffect(() => {
    setPopup((prev) => ({ ...prev, open: false }))
  }, [open])

  const updatePrice = async () => {
    if (!values.symbol || !open) {
      return
    }
    const price = await marketService.getMarketPrice(
      values.symbol,
      client[values.exchangeType]?.exchange,
    )
    setMarketPrice(price?.askPrice || 0)
    return price?.askPrice
  }

  const readPositionDebounced = useCallback(debounce(updatePrice, 400), [values.symbol])

  useEffect(() => {
    readPositionDebounced()

    return readPositionDebounced.cancel
  }, [values.symbol, readPositionDebounced])

  useEffect(() => {
    updatePrice()
  }, [values.exchangeType])

  const handleChangeUppercase = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value.toUpperCase()
    setValues((prev) => ({ ...prev, [e.target.id || e.target.name]: value }))
  }

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    setValues((prev) => ({ ...prev, [e.target.id || e.target.name]: value }))
  }

  const placeOrder = async (side) => {
    setLoading(true)
    try {
      const { symbol, amount, exchangeType, reduceOnly } = values
      const exchange = client[exchangeType]?.exchange

      const response = await userService.placeOrder(
        client.guid,
        symbol.toUpperCase(),
        amount,
        side,
        exchange,
        reduceOnly,
      )
      if (response.status === 401) {
        handleUnauthorized(response.data.message)
        return
      }
      if (response.status !== 200) {
        throw new Error(response.data.message)
      }

      setValues(initialState)
      onSuccess(response)
    } catch (err) {
      setPopup({
        message: `${side} order failed. ${err}`,
        severity: 'error',
        open: true,
      })
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    onClose()
  }

  const closePopup = () => setPopup((prev) => ({ ...prev, open: false }))

  return (
    <Dialog
      PaperProps={{ style: { width: 450, padding: '0 20px' } }}
      onClose={handleClose}
      open={open}
    >
      <PopUp {...popup} closePopup={closePopup} />
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <CircularProgress color="primary" />
      </Backdrop>
      <DialogTitle id="dialog-title" onClose={handleClose}>
        Place order
      </DialogTitle>
      <div className={s.transfer + ' column'}>
        <TextField
          id="symbol"
          label="Symbol"
          variant="outlined"
          fullWidth
          onChange={handleChangeUppercase}
          value={values.symbol}
        />
        <TextField
          id="amount"
          label="Amount"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          type="number"
          value={values.amount}
          InputProps={{
            endAdornment: (
              <div className={s.orderValue}>~{(values.amount * marketPrice).toFixed(2)} USDT</div>
            ),
          }}
        />
        <TextField
          id="exchangeType"
          name="exchangeType"
          select
          label="Exchange type"
          value={values.exchangeType}
          onChange={handleChange}
          helperText="Select exchange type"
        >
          {EXCHANGE_TYPES.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={values.reduceOnly}
              onChange={handleChange}
              name="reduceOnly"
            />
          }
          label="Reduce only"
        />
        <div className="flex-line justify-between">
          <Button color="primary" variant="contained" onClick={() => placeOrder('BUY')}>
            BUY
          </Button>
          <Button color="secondary" variant="contained" onClick={() => placeOrder('SELL')}>
            SELL
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default OrderWindow
