import { numberWithCommas, parseDate } from '../../utilities/number-helpers'

export const OPERATIONS_COLUMNS = [
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ value }) => numberWithCommas(value),
  },
  {
    Header: 'Coin',
    accessor: 'coin',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Time',
    accessor: 'timestamp',
    Cell: ({ value }) => parseDate(value),
  },
  {
    Header: 'Transaction id',
    accessor: 'transactionId',
  },
  {
    Header: 'Exchange',
    accessor: 'exchange',
  },
]
