import Paper from '@material-ui/core/Paper'
import React, { useCallback, useRef } from 'react'
import { FixedSizeList as List } from 'react-window'
import { EXECUTING } from '../../../model/RequestState'
import loggingService from '../../../service/LoggingService'
import RequestLog from './RequestLog/RequestLog'
import s from './RequestLogs.module.css'
const { useEffect, useState } = React

const itemHeigth = 85

const RequestLogs = ({ requestId, status }) => {
  const [logs, setLogs] = useState([])
  const [subscribed, setSubscribed] = useState(false)

  const listRef = useRef(null)
  const scrollPos = useRef(0)

  const hasLogs = logs && logs.length > 0

  const logsCallback = useCallback((newLogs) => {
    setLogs((prev) => combineLogs(newLogs, prev))
  }, [])

  const handleScroll = ({ scrollOffset }) => {
    scrollPos.current = scrollOffset
  }

  useEffect(() => {
    ;(async () => {
      if (!requestId) {
        return
      }
      if (status === EXECUTING && !subscribed) {
        await loggingService.subscribeOnRequestLogs(requestId, (newLogs) => {
          logsCallback(newLogs)
          if (listRef.current && scrollPos.current !== 0)
            listRef.current.scrollTo(scrollPos.current + newLogs.length * itemHeigth)
        })
        setSubscribed(true)
      } else if (subscribed) {
        await loggingService.unsubscribeAll()
        setSubscribed(false)
      } else if (!subscribed && !hasLogs) {
        const allLogs = await loggingService.getRequestLogs(requestId)
        setLogs(allLogs)
      }
    })()

    return async () => {
      await loggingService.unsubscribeAll()
    }
  }, [status])

  const combineLogs = (newLogs, oldLogs) => {
    const logSnapshot = [...newLogs]
    oldLogs.forEach((value) => {
      if (logSnapshot.length <= 200 && !logSnapshot.find((l) => l.id === value.id))
        logSnapshot.push(value)
    })

    return logSnapshot
  }

  return (
    <>
      {hasLogs && (
        <Paper elevation={2} style={{ padding: 10, overflow: 'auto' }}>
          <div className={s.table}>
            <div className={s.th}>
              <div>Date</div>
              <div>Level</div>
              <div>Message</div>
            </div>
            <div>
              <List
                onScroll={handleScroll}
                ref={listRef}
                height={500}
                itemData={logs}
                itemCount={logs.length}
                itemSize={itemHeigth}
              >
                {({ data, index, style }) => (
                  <RequestLog key={index} data={data} index={index} style={style} />
                )}
              </List>
              {/* {logs.map(log => (
                <RequestLog key={log.id} log={log} />
              ))} */}
            </div>
          </div>
        </Paper>
      )}
    </>
  )
}

export default RequestLogs
