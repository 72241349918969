import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import clientService from '../../service/ClientService'
import BackButton from '../common/BackButton'
import PopUp from '../common/PopUp'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const ConfigurationView = () => {
  const { cid, symbol } = useParams()
  const [config, setConfig] = useState({
    symbol: symbol,
    defenceTrigger: '',
    defenceAmount: '',
    investedAmount: '',
    brokenPosition: false,
  })
  const [popup, setPopup] = useState({
    message: '',
    severity: 'success',
    open: false,
  })

  useEffect(() => {
    ;(async () => {
      const data = await clientService.getSymbolConfig(cid, config.symbol)
      setConfig(data)
    })()
  }, [cid, config.symbol])

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.id
    setConfig((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const updateConfig = async () => {
    try {
      await clientService.updateSymbolConfig(cid, config)
      setPopup({
        message: 'Configuration successfully update',
        severity: 'success',
        open: true,
      })
    } catch (e) {
      setPopup({
        message: 'Failed to update configuration: ' + e,
        severity: 'error',
        open: true,
      })
    }
  }

  const closePopup = () => {
    setPopup((prev) => ({
      ...prev,
      open: false,
    }))
  }

  return (
    <Paper elevation={8} style={{ padding: 12, display: 'flex', flexFlow: 'column' }}>
      <PopUp {...popup} closePopup={() => closePopup()} />
      <div className="centered-line justify-between">
        <Typography variant="h4">
          Client # {cid} symbol {config.symbol} configuration
        </Typography>
        <BackButton />
      </div>
      <TextField
        id="defenceTrigger"
        label="Defence Trigger %"
        margin="normal"
        type="number"
        value={config.defenceTrigger}
        onChange={handleInputChange}
      />
      <TextField
        id="defenceAmount"
        label="Defence Amount %"
        margin="normal"
        type="number"
        value={config.defenceAmount}
        onChange={handleInputChange}
      />
      <TextField
        id="investedAmount"
        label="Invested Amount"
        margin="normal"
        type="number"
        value={config.investedAmount}
        onChange={handleInputChange}
      />
      <FormControlLabel
        control={<Checkbox color="primary" id="brokenPosition" checked={config.brokenPosition} />}
        label="Broken Position"
        onChange={handleInputChange}
      />

      <Button variant="contained" color="primary" onClick={updateConfig} style={{ marginTop: 20 }}>
        Update
      </Button>
    </Paper>
  )
}

export default ConfigurationView
