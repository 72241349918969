import axios from 'axios'
import { redirectToCognitoPage } from './auth-api'

export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

instance.interceptors.request.use(function (config) {
  const headers = authHeader()
  if (headers) {
    config.headers = { ...config.headers, ...headers }
  }
  return config
})

instance.interceptors.response.use(undefined, (err) => {
  const error = err.response

  if (error?.status === 401 || error?.status === 403) {
    redirectToCognitoPage()
  }

  return error
})

export function authHeader() {
  const token = localStorage.getItem('token')
  if (token) {
    return { Authorization: 'Bearer ' + token }
  } else {
    return {}
  }
}

export default instance
