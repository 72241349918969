import { useEffect, useState } from 'react'
import { useQuery } from './use-query'
import { useInterval } from './use-interval'
import {
  awsCallTokenEndpoint,
  awsCallTokenRefresh,
  getAwsToken,
  isAuthExpired,
  redirectToCognitoPage,
} from '../service/auth-api'
import { useHistory } from 'react-router'

export const useInit = () => {
  const [isAuth, setIsAuth] = useState(localStorage.getItem('authenticated') === 'true' || false)

  const history = useHistory()
  const query = useQuery()
  const code = query?.get('code')

  const expiresIn = localStorage.getItem('expires_in') || 3600000

  useInterval(() => {
    async function refreshMe() {
      const refreshToken = localStorage.getItem('refresh')
      await getAwsToken(() => awsCallTokenRefresh('refresh_token', refreshToken))
    }
    if (isAuth) refreshMe()
  }, expiresIn - 300000) //sends refresh request 5 minutes earlier before token expires

  useEffect(() => {
    function authMe() {
      //requesting token after callback code is received
      const codeVerifier = localStorage.getItem('codeVerifier')
      getAwsToken(() => awsCallTokenEndpoint('authorization_code', code, codeVerifier)).then(() => {
        setIsAuth(true)
        history.push('/')
      })
    }
    if (code && !isAuth) {
      authMe()
    } else if (code && isAuth) {
      window.location.replace('/')
    } else if (isAuthExpired()) {
      redirectToCognitoPage()
    }
  }, [code, history, isAuth])

  //initial auth
  if (!isAuth && !code && !isAuthExpired()) {
    redirectToCognitoPage()
  }

  return isAuth
}
