import { useCallback } from 'react'
import requestService from '../../../service/RequestService'
import { useParams } from 'react-router-dom'
import RequestListView from '../RequestListView/RequestListView'
import { useClients } from '../../../hooks/use-clients'

const ClientRequests = () => {
  const { cid } = useParams()
  useClients()

  const getRequests = useCallback((page) => requestService.getAllByCid(cid, page - 1), [cid])

  return <RequestListView requestCallback={getRequests} title={`Client #${cid} requests`} />
}

export default ClientRequests
