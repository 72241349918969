import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import {
  BINANCE_FUTURES,
  BINANCE_SPOT,
  DISPLAY_NAMES,
  TYPE_FUTURES,
  TYPE_SPOT,
} from '../../../../model/Exchange'
import marketService from '../../../../service/MarketService'

const REQUIRED_ASSETS = ['USDT', 'USD', 'UST', 'USDC', 'BNB']

const BalanceView = ({ leftAccount, rightAccount, estimatedTotalBalance }) => {
  const [BNBPrices, setBNBPrices] = useState(null)

  useEffect(() => {
    const fetchBNBPrices = async () => {
      const promises = [
        await marketService.getMarketPrice('BNBUSDT', BINANCE_SPOT),
        await marketService.getMarketPrice('BNBUSDT', BINANCE_FUTURES),
      ]

      const [BNBSpotPrice, BNBFuturesPrice] = await Promise.all(promises)
      setBNBPrices({ BNBSpotPrice, BNBFuturesPrice })
    }
    fetchBNBPrices()
  }, [])

  const availableColor = (asset, available, exchange) => {
    if (asset !== 'BNB' || !BNBPrices) {
      return 'text.primary'
    }

    const BNBSpotPriceBalance = BNBPrices.BNBSpotPrice.askPrice * Number(available)
    const BNBFuturesPriceBalance = BNBPrices.BNBFuturesPrice.askPrice * Number(available)
    if (exchange === BINANCE_SPOT && BNBSpotPriceBalance < estimatedTotalBalance * 0.0002) {
      return 'error.main'
    }
    if (exchange === BINANCE_FUTURES && BNBFuturesPriceBalance < estimatedTotalBalance * 0.00008) {
      return 'error.main'
    }
    return 'text.primary'
  }

  return (
    <React.Fragment>
      <Typography component="div" variant="body1" gutterBottom>
        {`${DISPLAY_NAMES.get(leftAccount.exchange)} - ${DISPLAY_NAMES.get(rightAccount.exchange)}`}
      </Typography>
      {REQUIRED_ASSETS.filter(
        (asset) =>
          (leftAccount.balance[asset] && leftAccount.balance[asset].total !== 0) ||
          (rightAccount.balance[asset] && rightAccount.balance[asset].total !== 0),
      ).map((asset) => {
        return (
          <Typography component="div" variant="body1" gutterBottom key={asset}>
            {`Asset: ${asset} `}
            <Box
              style={{ display: 'inline-block' }}
              color={availableColor(asset, leftAccount.balance[asset]?.free, leftAccount.exchange)}
            >
              {leftAccount.balance[asset]?.total?.toLocaleString('en-US') ?? `0`}
            </Box>
            {` / `}
            <Box
              style={{ display: 'inline-block' }}
              color={availableColor(
                asset,
                rightAccount.balance[asset]?.free,
                rightAccount.exchange,
              )}
            >
              {rightAccount.balance[asset]?.total?.toLocaleString('en-US') ?? `0`}
            </Box>
          </Typography>
        )
      })}
      <div style={{ paddingBottom: 15 }} />
      {leftAccount.openPositions.map((pos, i) => {
        if (rightAccount.exchangeType === TYPE_SPOT) {
          const balance = rightAccount.balance[pos.baseAsset]
          if (balance?.total !== Math.abs(pos.currentPosition) || balance?.free > 0) {
            const locked =
              balance?.free > 0 ? `locked: ${balance?.locked?.toLocaleString('en-US') ?? `0`}` : ''
            return (
              <Typography key={pos.symbol + i}>
                <Box style={{ display: 'inline-block' }} color="error.main">
                  {`Position: ${pos.symbol}  ${pos.currentPosition.toLocaleString('en-US')} / ${
                    balance?.total?.toLocaleString('en-US') ?? `0`
                  } ${locked}`}
                </Box>
              </Typography>
            )
          }
        } else {
          const rightPos = rightAccount.openPositions.find((p) => p.symbol === pos.symbol)
          if (
            !rightPos ||
            (rightPos && Math.abs(rightPos.currentPosition) !== Math.abs(pos.currentPosition))
          ) {
            return (
              <Typography key={pos.symbol + i}>
                <Box style={{ display: 'inline-block' }} color="error.main">
                  {`Position: ${pos.symbol}  ${pos.currentPosition.toLocaleString('en-US')} / ${
                    rightPos?.currentPosition?.toLocaleString('en-US') ?? `0`
                  }`}
                </Box>
              </Typography>
            )
          }
        }
        return null
      })}
      {rightAccount.openPositions.map((pos, i) => {
        if (!leftAccount.openPositions.find((p) => p.symbol === pos.symbol)) {
          return (
            <Typography key={pos.symbol + i}>
              <Box style={{ display: 'inline-block' }} color="error.main">
                {`Position: ${pos.symbol} 0 / ${pos.currentPosition.toLocaleString('en-US')}`}
              </Box>
            </Typography>
          )
        }
        return null
      })}
      {rightAccount.exchangeType === TYPE_SPOT &&
        Object.keys(rightAccount.balance).map((asset, i) => {
          if (
            REQUIRED_ASSETS.includes(asset) ||
            leftAccount.openPositions.some((pos) => pos.baseAsset === asset)
          ) {
            return null
          }

          const balance = rightAccount.balance[asset]
          const locked =
            balance?.free > 0 ? `locked: ${balance?.locked?.toLocaleString('en-US') ?? `0`}` : ''
          if (balance?.total === 0 && balance?.free === 0) {
            return null
          }

          return (
            <Typography key={asset + i}>
              <Box style={{ display: 'inline-block' }} color="error.main">
                {`Position: ${asset} 0 / ${
                  balance?.total?.toLocaleString('en-US') ?? `0`
                } ${locked}`}
              </Box>
            </Typography>
          )
        })}
    </React.Fragment>
  )
}
export default BalanceView
