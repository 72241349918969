import { Button } from '@material-ui/core'
import HistoryIcon from '@material-ui/icons/History'
import { useHistory } from 'react-router'

const HistoryButton = ({ guid, cid }) => {
  const history = useHistory()

  return (
    <>
      <Button
        style={{ justifyContent: 'flex-start' }}
        color="primary"
        aria-label="download history"
        onClick={() => history.push(`/history-view/${cid}`)}
      >
        <HistoryIcon />
        &nbsp;Download History
      </Button>
    </>
  )
}

export default HistoryButton
