import moment from 'moment'

export const parseDate = (epoch) => {
  if (!epoch) return ''
  return moment(epoch).format('YYYY-MM-DD HH:mm:ss')
}

export const numberWithCommas = (value) => {
  const parts = String(value).split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return parts.join('.')
}

export const formatFloat = (value, decimals = 3) => {
  const num = Number(value)
  if (value != null && !isNaN(num)) {
    return parseFloat(num.toFixed(decimals))
  }
  return ''
}

export const parseBoolean = (value) => {
  return value === 'true'
}
