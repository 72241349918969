import {
  CircularProgress,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead as THead,
  TableRow,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ListIcon from '@material-ui/icons/List'
import ReorderIcon from '@material-ui/icons/Reorder'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useExpanded, useFilters, useGroupBy, useSortBy, useTable } from 'react-table'
import s from './SortableTable.module.css'

const SortableTable = ({
  columns,
  data,
  defaultSortBy = [],
  enableFilters = false,
  enableGrouping = false,
  loading = false,
  hiddenColumns,
}) => {
  const sortTypes = useMemo(
    () => ({
      alphanumericFalsyLast(rowA, rowB, columnId, desc) {
        const a = rowA.values[columnId]
        const b = rowB.values[columnId]
        if (!a && !b) {
          return 0
        }
        if (!a) {
          return desc ? -1 : 1
        }
        if (!b) {
          return desc ? 1 : -1
        }
        return a - b
      },
    }),
    [],
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setHiddenColumns } =
    useTable(
      {
        columns,
        data,
        sortTypes,
        disableMultiSort: true,
        autoResetFilters: false,
        autoResetSortBy: false,
        initialState: {
          sortBy: defaultSortBy,
        },
      },
      useFilters,
      useGroupBy,
      useSortBy,
      useExpanded,
    )

  useEffect(() => {
    if (hiddenColumns) {
      setHiddenColumns(hiddenColumns)
    }
  }, [hiddenColumns, setHiddenColumns])

  const FiltersRow = useCallback(
    ({ headers }) => (
      <TableRow className={s.filters}>
        {headers.map((column, i) => (
          <TableCell key={i} {...column.getHeaderProps()}>
            {column.canFilter ? column.render('Filter') : null}
          </TableCell>
        ))}
      </TableRow>
    ),
    [],
  )

  return (
    <>
      <TableContainer className={s.table}>
        <MuiTable stickyHeader {...getTableProps()}>
          <THead>
            {headerGroups.map((headerGroup, i) => (
              <React.Fragment key={i}>
                <TableRow className={s.heading} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, i) => (
                    <TableCell key={i}>
                      <div
                        style={{
                          display: 'flex',
                          flexFlow: 'row !important',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {column.canGroupBy && enableGrouping && (
                          <div
                            {...column.getGroupByToggleProps()}
                            style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
                          >
                            {column.isGrouped ? <ReorderIcon /> : <ListIcon />}
                          </div>
                        )}
                        <div
                          style={{ userSelect: 'none' }}
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                        >
                          {column.render('Header')}
                        </div>
                        <ArrowDropUpIcon
                          style={{
                            color: column.isSorted && !column.isSortedDesc ? 'lightgrey' : 'black',
                          }}
                          fontSize="small"
                        />
                        <ArrowDropDownIcon
                          style={{
                            color: column.isSorted && column.isSortedDesc ? 'lightgrey' : 'black',
                          }}
                          fontSize="small"
                        />
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
                {enableFilters && <FiltersRow headers={headerGroup.headers} key="Filters" />}
              </React.Fragment>
            ))}
          </THead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              return (
                <TableRow
                  {...row.getRowProps()}
                  style={{
                    backgroundColor: row.original.brokenPosition ? 'rgba(255, 236, 0, 0.225)' : '',
                  }}
                >
                  {row.cells.map((cell, i) => {
                    return (
                      <TableCell key={i} {...cell.getCellProps()} data-title={cell.column.Header}>
                        {cell.isGrouped ? (
                          <div
                            className="flex-line justify-start"
                            {...row.getToggleRowExpandedProps()}
                          >
                            <ExpandMoreIcon
                              style={{
                                transform: row.isExpanded ? '' : 'rotate(-90deg)',
                                transition: '.1s',
                              }}
                              fontSize="small"
                            />
                            {cell.render('Cell')} ({row.subRows.length})
                          </div>
                        ) : cell.isAggregated ? (
                          cell.render('Aggregated')
                        ) : cell.isPlaceholder ? null : (
                          cell.render('Cell', {})
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </MuiTable>
        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>
            <CircularProgress size={60} />
          </div>
        )}
        {rows.length <= 0 && !loading && <h2 style={{ margin: '8px' }}>No results</h2>}
      </TableContainer>
    </>
  )
}

export default SortableTable
