import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import { EXECUTING, STOPPED } from '../../../../model/RequestState'
import { parseDate } from '../../../../utilities/number-helpers'
import { checkIsOpenRequest } from '../../../../utilities/isOpenRequest'
import { useDispatch, useSelector } from 'react-redux'
import { clientsActions } from '../../../../redux/slices/clients-slice'

const useStyles = makeStyles({
  root: {
    marginTop: 20,
    marginBottom: 20,
  },
  pos: {
    marginBottom: 12,
  },
})

const RequestCard = ({ request, setRepeatedRequest, requestCancellation }) => {
  const classes = useStyles()
  const client = useSelector((state) =>
    state.clients.clients.find((value) => value.guid === request.guid),
  )

  const dispatch = useDispatch()

  const asset = checkIsOpenRequest(request.type, request.orderSide)
    ? request.quoteAsset
    : request.baseAsset

  const remainingPercent = (fill) => {
    return Math.round((Number(fill.filledAmount) / Number(fill.requestAmount)) * 100)
  }

  const openRequestWindow = () => {
    dispatch(clientsActions.setClientInfo(client))
    setRepeatedRequest({ request, client, asset })
  }

  const symbol = (request.asset.base !== '')
      ? `${request.asset.base} : ${request.asset.left} : ${request.asset.right}`
      : request.symbol

  return (
    <Card className={classes.root}>
      <CardContent>
        {request.userName || request.userEmail ? (
          <Typography variant="body1">Request: {request.userName || request.userEmail}</Typography>
        ) : null}
        <Typography variant="body1">Symbol: {symbol}</Typography>
        <Typography variant="body1">
          Amount: {request.amount} {asset}
        </Typography>
        <Typography variant="body1">
          Amount Executed: {request.fill.filledAmount} {asset} ({remainingPercent(request.fill)}%)
        </Typography>
        <Typography variant="body1">
          Futures Premium: {request.realFuturesPremium}% (futures: {request.futuresUSDTSpent}, spot:{' '}
          {request.spotUSDTSpent})
        </Typography>
        <Typography variant="body1">Status: {request.status}</Typography>
        {request.status === STOPPED ? (
          <Typography variant="body1">Stop Reason: {request.cancelSource}</Typography>
        ) : null}
        <Typography variant="body1">
          Type: {request.type} /{' '}
          {checkIsOpenRequest(request.type, request.orderSide) ? 'Open' : 'Close'}
        </Typography>
        <Typography variant="body1">Trigger: {request.triggerSource}</Typography>
        <Typography variant="body1">Started on: {parseDate(request.startedOn)}</Typography>
        {request.finishedOn ? (
          <Typography variant="body1">Finished on: {parseDate(request.finishedOn)}</Typography>
        ) : null}
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={{ pathname: `/request/${request.id}` }}
        >
          Show Details
        </Button>
        {request.status === EXECUTING ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => requestCancellation(request.id)}
          >
            Cancel Request
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={openRequestWindow}>
            Repeat
          </Button>
        )}
      </CardActions>
    </Card>
  )
}

export default React.memo(RequestCard)
