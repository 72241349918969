import React, { useCallback, useEffect, useState } from 'react'
import s from './RequestListView.module.css'
import requestService from '../../../service/RequestService'
import RequestCard from './RequestCard/RequestCard'
import LoadingScreen from '../../common/LoadingScreen'
import Typography from '@material-ui/core/Typography'
import BackButton from '../../common/BackButton'
import webSocketService, { REQUEST_TOPIC } from '../../../service/WebSocketService'
import { Pagination } from '@material-ui/lab'
import RequestDialog from '../../HomeClient/ClientCard/RequestDialog/RequestDialog'

const RequestListView = ({ requestCallback, title, groupByName = true }) => {
  const [requests, setRequests] = useState([])
  const [loading, setLoading] = useState(false)
  const [subscribeId, setSubscribeId] = useState(null)
  const [paging, setPaging] = useState({ totalPages: 1, page: 1 })
  const [repeatedRequest, setRepeatedRequest] = useState(null)

  const openRepeatRequest = useCallback((request) => {
    setRepeatedRequest(request)
  }, [])

  const closeRepeatedRequest = useCallback(() => {
    setRepeatedRequest(null)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      const data = await requestCallback(paging.page)
      //dispatch(requestsActions.setRequests(requests))
      setPaging((prev) => ({ ...prev, totalPages: data.totalPages }))
      setRequests(data.content)

      setLoading(false)
    }
    fetchData()
  }, [paging.page])

  useEffect(() => {
    const id = webSocketService.subscribe(REQUEST_TOPIC, (message) => handleRequestChange(message))
    setSubscribeId(id)

    return () => {
      if (subscribeId) {
        webSocketService.unsubscribe(REQUEST_TOPIC, subscribeId)
      }
    }
  }, [])

  const groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
      ;(rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
  }

  const handleRequestChange = (message) => {
    if (!!message.request) {
      updateRequest(message.request)
      return
    }
    if (!!message.fill) {
      updateRequestFill(message.fill)
    }
  }

  const cancelRequest = async (requestId) => {
    const request = await requestService.stopExecution(requestId)
    updateRequest(request)
  }

  const updateRequest = (request) => {
    setRequests((prev) => {
      const updatedRequests = prev.map((el) => (el.id === request.id ? request : el))
      if (!updatedRequests.find((el) => el.id === request.id)) {
        updatedRequests.push(request)
      }
      return updatedRequests
    })
  }

  const updateRequestFill = (fill) => {
    setRequests((prev) =>
      prev.map((el) => {
        if (el.id === fill.requestId) el.fill = fill
        return el
      }),
    )
  }

  const handlePaging = (_, value) => setPaging((prev) => ({ ...prev, page: value }))

  const groupedRequests = groupBy(requests, 'cid')

  return (
    <div>
      <div className="centered-line justify-between">
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <BackButton />
      </div>
      <div>
        <Pagination count={paging.totalPages} page={paging.page} onChange={handlePaging} />
        {loading ? (
          <LoadingScreen />
        ) : groupByName ? (
          Object.keys(groupedRequests).map((key) => (
            <div key={key} className={s.requestsByUser}>
              <Typography variant="h4" component={'h2'} gutterBottom>
                {key}:
              </Typography>
              {groupedRequests[key].map((request) => (
                <RequestCard
                  key={request.cid + Math.random()}
                  request={request}
                  requestCancellation={cancelRequest}
                  setRepeatedRequest={openRepeatRequest}
                />
              ))}
            </div>
          ))
        ) : (
          requests.map((request) => (
            <div key={request.cid + Math.random()} className={s.requestsByUser}>
              <Typography variant="h5" component={'h2'}>
                {request.cid}:
              </Typography>
              <RequestCard
                request={request}
                requestCancellation={cancelRequest}
                setRepeatedRequest={openRepeatRequest}
              />
            </div>
          ))
        )}
        <Pagination count={paging.totalPages} page={paging.page} onChange={handlePaging} />
        <RequestDialog
          open={!!repeatedRequest}
          handleClose={closeRepeatedRequest}
          guid={repeatedRequest?.request?.guid}
          side={repeatedRequest?.request?.orderSide}
          type={repeatedRequest?.request?.type}
          position={{
            symbol: repeatedRequest?.request?.symbol,
            currentPosition:
              repeatedRequest?.client?.leftAccount.balance[repeatedRequest?.asset]?.free ||
              repeatedRequest?.client?.leftAccount?.openPositions?.find((pos) => pos.baseAsset === repeatedRequest?.request?.asset?.base)?.currentPosition,
            leverage: repeatedRequest?.request?.leverage,
            isolated: repeatedRequest?.request?.isolated,
            baseAsset: repeatedRequest?.request?.baseAsset,
            quoteAsset: repeatedRequest?.request?.quoteAsset,
            asset: repeatedRequest?.request?.asset,
          }}
          request={{
            symbol: repeatedRequest?.request?.asset?.base,
            amount: repeatedRequest?.request?.amount,
            futuresPremium: repeatedRequest?.request?.futuresPremium,
            lotAmount: repeatedRequest?.request?.lotAmount,
            leverage: repeatedRequest?.request?.leverage,
          }}
        />
      </div>
    </div>
  )
}

export default RequestListView
