import instance from './axiosConfig'

class RequestService {
  async getActiveRequest() {
    const response = await instance.get(`/api/request/active`)
    return response.data
  }

  async getActiveRequestCount(page = 0) {
    const response = await instance.get(`/api/request/active/count?page=${page}`)
    return response?.data
  }

  async findById(id) {
    const response = await instance.get(`/api/request/${id}`)
    return response.data
  }

  async getAllByCid(cid, page = 0) {
    const response = await instance.get(`/api/request?cid=${cid}&page=${page}`)
    return response.data
  }

  async getAllRequests(page = 0) {
    const response = await instance.get(`/api/request?page=${page}`)
    return response.data
  }

  async startExecution(payload) {
    return instance.post('/api/request', payload).then(
      (res) => res,
      (err) => err,
    )
  }

  async stopExecution(id) {
    const response = await instance.delete(`/api/request/${id}`)
    return response.data
  }

  async getFill(id) {
    const response = await instance.get(`/api/request/${id}/fill`)
    return response.data
  }
}

const requestService = new RequestService()
export default requestService
