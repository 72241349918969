import { parseDate } from '../../../utilities/number-helpers'

export const COLUMNS = [
  {
    Header: 'Client',
    accessor: 'cid',
  },
  {
    Header: 'Exchange',
    accessor: 'exchange',
  },
  {
    Header: 'Exchange type',
    accessor: 'exchangeType',
  },
  {
    Header: 'Last event',
    accessor: 'lastEventTs',
    Cell: ({ value }) => parseDate(value),
  },
]
