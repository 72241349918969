import Dialog from '@material-ui/core/Dialog'
import s from './TransferDialog.module.css'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { BNB, USDT } from '../../../../model/Asset'
import Button from '@material-ui/core/Button'
import RepeatIcon from '@material-ui/icons/Repeat'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import binanceSpotService from '../../../../service/BinanceSpotService'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core'
import futuresService from '../../../../service/FuturesService'

const titleStyle = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const DialogTitle = withStyles(titleStyle)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const TransferDialog = ({ onClose, onSuccess, open, cid, guid }) => {
  const classes = useStyles()

  const [spotBalance, setSpotBalance] = useState({})
  const [futuresBalance, setFuturesBalance] = useState({})
  const [selectedAsset, setSelectedAsset] = useState(USDT)
  const [amount, setAmount] = useState({
    transfer: '',
    available: '',
  })
  const [exchanges, setExchanges] = useState({
    fromExchange: 'Spot',
    toExchange: 'Futures',
  })
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [responseInfo, setResponseInfo] = useState({
    text: '',
    severity: '',
  })

  const calculateAvailable = useCallback(() => {
    const balance = exchanges.fromExchange === 'Spot' ? spotBalance : futuresBalance
    const assetBalance = balance[selectedAsset]
    return assetBalance ? assetBalance.free : '0'
  }, [exchanges.fromExchange, futuresBalance, selectedAsset, spotBalance])

  useEffect(() => {
    ;(async () => {
      setAmount({
        transfer: '',
        available: '',
      })
      setSelectedAsset(USDT)
      setError('')
      setResponseInfo({
        text: '',
        severity: '',
      })

      if (open) {
        setLoading(true)

        const [binanceSpot, binanceFutures] = await Promise.all([
          binanceSpotService.getBalance(guid),
          futuresService.getBinanceFuturesBalance(guid),
        ])
        setSpotBalance(binanceSpot)
        setFuturesBalance(binanceFutures)

        setLoading(false)
      }
    })()
  }, [open])

  useEffect(() => {
    setAmount({
      transfer: '',
      available: calculateAvailable(),
    })
    setError('')
  }, [exchanges, selectedAsset])

  useEffect(() => {
    setAmount((a) => ({
      transfer: a.transfer,
      available: calculateAvailable(),
    }))
    setError('')
  }, [spotBalance, futuresBalance])

  const changeAsset = (event) => {
    setSelectedAsset(event.target.value)
  }

  const changeAmount = (value) => {
    setAmount({
      ...amount,
      transfer: value,
    })
  }

  const confirmTransfer = async () => {
    if (!amount.transfer) {
      setError(`Transfer amount ${amount.transfer} is missing`)
      return
    }
    if (error) {
      setError('')
    }
    setLoading(true)
    try {
      let response
      if (exchanges.fromExchange === 'Spot') {
        response = await binanceSpotService.transferFromSpot(cid, selectedAsset, amount.transfer)
        if (response.status !== 200) {
          throw Error(response.data.message)
        }
        setSpotBalance((prev) => ({
          ...prev,
          [selectedAsset]: {
            ...prev[selectedAsset],
            free: prev[selectedAsset]?.free - Number(amount.transfer),
          },
        }))
        setFuturesBalance((prev) => ({
          ...prev,
          [selectedAsset]: {
            ...prev[selectedAsset],
            free: prev[selectedAsset]?.free + Number(amount.transfer),
          },
        }))
      } else {
        response = await binanceSpotService.transferFromFutures(cid, selectedAsset, amount.transfer)
        if (response.status !== 200) {
          throw Error(response.data.message)
        }
        setSpotBalance((prev) => ({
          ...prev,
          [selectedAsset]: {
            ...prev[selectedAsset],
            free: prev[selectedAsset]?.free + Number(amount.transfer),
          },
        }))
        setFuturesBalance((prev) => ({
          ...prev,
          [selectedAsset]: {
            ...prev[selectedAsset],
            free: prev[selectedAsset]?.free - Number(amount.transfer),
          },
        }))
      }
      setResponseInfo({
        text: `Transfer successfully done.`,
        severity: 'success',
      })

      onSuccess?.(guid)
    } catch (e) {
      setResponseInfo({
        text: `Transfer failed. ${e}`,
        severity: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const swapExchanges = () => {
    setExchanges({
      toExchange: exchanges.fromExchange,
      fromExchange: exchanges.toExchange,
    })
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 550, padding: '0 10px' } }}
      onClose={handleClose}
      open={open}
    >
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle id="dialog-title" onClose={handleClose}>
        Transfer clients '{cid}' funds
      </DialogTitle>
      <div className={s.transfer + ' column'}>
        <div className={'centered-line w-100'}>
          <TextField
            style={{ flex: 1 }}
            id="fromExchange"
            label="From"
            variant="filled"
            disabled
            value={exchanges.fromExchange}
          />
          <IconButton aria-label="swap" onClick={(e) => swapExchanges()}>
            <RepeatIcon fontSize="large" />
          </IconButton>
          <TextField
            style={{ flex: 1 }}
            id="toExchnage"
            label="To"
            variant="filled"
            disabled
            value={exchanges.toExchange}
          />
        </div>
        <FormControl fullWidth>
          <InputLabel id="selectedAssetLabel">Coin</InputLabel>
          <Select
            labelId="selectedAssetLabel"
            id="selectedAsset"
            required
            value={selectedAsset}
            onChange={(e) => changeAsset(e)}
          >
            <MenuItem value={USDT}>{USDT}</MenuItem>
            <MenuItem value={BNB}>{BNB}</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="amount"
          label="Amount"
          variant="outlined"
          fullWidth
          onChange={(e) => changeAmount(e.target.value)}
          type="number"
          value={amount.transfer}
          error={!!error}
          helperText={error}
        />
        <Typography variant="body1">
          Available amount{' '}
          <strong className={'link'} onClick={() => changeAmount(amount.available)}>
            {amount.available}
          </strong>{' '}
          {selectedAsset}{' '}
        </Typography>
        {responseInfo.text ? (
          <Grid item xs={12}>
            <Alert severity={responseInfo.severity}>{responseInfo.text}</Alert>
          </Grid>
        ) : null}
        <Button onClick={(e) => confirmTransfer()}>Confirm</Button>
      </div>
    </Dialog>
  )
}

TransferDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  cid: PropTypes.string.isRequired,
  guid: PropTypes.string.isRequired,
}

export default TransferDialog
