import { Paper, Typography } from '@material-ui/core'
import { useMemo } from 'react'
import SortableTable from '../../Table/SortableTable'
import { COLUMNS } from './columns'
import './OrderBookTable.module.css'

const OrderBookTable = ({ data = [], loading }) => {
  const columns = useMemo(() => COLUMNS, [])

  return (
    <Paper elevation={2}>
      <Typography variant="h5" gutterBottom style={{ margin: 20 }}>
        Order book subscriptions
      </Typography>
      <SortableTable data={data} columns={columns} loading={loading} />
    </Paper>
  )
}

export default OrderBookTable
