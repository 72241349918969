import React, { useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import clientService from '../../../../service/ClientService'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import ArchiveIcon from '@material-ui/icons/Archive'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button } from '@material-ui/core'

const style = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
})

const ArchiveClientButton = withStyles(style)(({ classes, client, positions }) => {
  const [loading, setLoading] = useState(false)

  const clientArchiveChange = async () => {
    setLoading(true)
    try {
      if (client.archived) {
        await clientService.unArchiveClient(client.cid)
      } else {
        await clientService.archiveClient(client.cid)
      }
    } finally {
      setLoading(false)
    }
  }

  const title = client.archived ? 'Unarchive Client' : 'Archive Client'

  if (positions.length) {
    return null
  }
  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Button
        style={{ justifyContent: 'flex-start' }}
        color="primary"
        aria-label="delete client"
        onClick={clientArchiveChange}
      >
        {client.archived ? <UnarchiveIcon /> : <ArchiveIcon />}&nbsp;{title}
      </Button>
    </React.Fragment>
  )
})

export default ArchiveClientButton
