import Snackbar from '@material-ui/core/Snackbar'
import * as React from 'react'
import Alert from '@material-ui/lab/Alert'

const PopUp = (props) => {
  const { message, severity, open, closePopup } = props

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    closePopup()
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default PopUp
