import SelectQueryFilter from '../Table/Filters/SelectQueryFilter'
import { parseDate } from '../../utilities/number-helpers'

export const COLUMNS = [
  {
    Header: 'Symbol',
    accessor: 'symbol',
    Filter: (info) => <SelectQueryFilter id={'symbol'} {...info} />,
  },
  {
    Header: 'Exchange',
    accessor: 'exchangePair',
    Filter: (info) => <SelectQueryFilter id={'cid'} {...info} />,
    canGroupBy: false,
  },
  {
    Header: 'Side',
    accessor: 'side',
    Filter: (info) => <SelectQueryFilter id={'cid'} {...info} />,
    canGroupBy: false,
  },
  {
    Header: 'Original QTY',
    accessor: 'amount',
    disableFilters: true,
    canGroupBy: false,
  },
  {
    Header: 'Price',
    accessor: 'price',
    disableFilters: true,
    canGroupBy: false,
  },
  {
    Header: 'Order value',
    accessor: 'orderValue',
    Cell: ({ row }) => (row.original.price * row.original.amount).toFixed(2),
    Aggregated: () => null,
    disableFilters: true,
    canGroupBy: false,
  },
  {
    Header: 'Executed QTY',
    accessor: 'executedAmount',
    disableFilters: true,
    canGroupBy: false,
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    Cell: ({ row }) => parseDate(row.original.createdAt),
    Aggregated: () => null,
    disableFilters: true,
    canGroupBy: false,
  },
]
