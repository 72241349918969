export const menuItems = [
  {
    name: 'All Requests',
    to: '/all-requests',
  },
  {
    name: 'Create Client',
    to: '/client',
  },
  {
    name: 'Configuration',
    to: '/config',
  },
  {
    name: 'User',
    to: '/user-management',
  },
  {
    name: 'Client Health',
    to: '/client-health',
  },
  {
    name: 'Position Health',
    to: '/health-view',
  },
  {
    name: 'System Health',
    to: '/system-health',
  },
  {
    name: 'Client Data',
    to: '/client-finances',
  },
  {
    name: 'Notifications',
    to: '/notifications',
  },
]
