import { createSlice } from '@reduxjs/toolkit'

export const systemSlice = createSlice({
  name: 'system',
  initialState: {
    environment: '',
    suspended: false,
    speculationSuspended: false,
    closeAmountLimit: 0,
    activeRequestCount: 0,
  },
  reducers: {
    setInfo: (state, action) => {
      state.environment = action.payload.environment
      state.suspended = action.payload.suspended
      state.speculationSuspended = action.payload.speculationSuspended
      state.closeAmountLimit = action.payload.closeAmountLimit
    },
    setActiveRequestCount: (state, action) => {
      state.activeRequestCount = action.payload
    },
  },
})

export const systemActions = systemSlice.actions

export default systemSlice.reducer
