import { Paper, Typography } from '@material-ui/core'
import s from './ClientHealth.module.css'
import { useEffect, useMemo, useState } from 'react'
import { COLUMNS } from './columns'
import SortableTable from '../Table/SortableTable'
import { mockArr } from './mockArr'
import clientService from '../../service/ClientService'
import LoadingScreen from '../common/LoadingScreen'

const ClientHealth = () => {
  const columns = useMemo(() => COLUMNS, [])
  const [data, setData] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    ;(async () => {
      setIsFetching(true)

      const data = await clientService.getClientsHealth()
      if (data) setData(data)

      setIsFetching(false)
    })()
  }, [])

  if (isFetching) return <LoadingScreen />

  return (
    <div className={s.root}>
      <Typography variant="h4" gutterBottom>
        Client Health
      </Typography>
      <Paper elevation={2}>
        <SortableTable data={data} columns={columns} />
      </Paper>
    </div>
  )
}

export default ClientHealth
