import s from './ResetDialog.module.css'
import { Button, Dialog, DialogActions, DialogTitle, TextField } from '@material-ui/core'
import { useState } from 'react'
import { useEffect } from 'react'

const ResetDialog = ({ open, handleSubmit, handleClose }) => {
  const [otpCode, setOtpCode] = useState('')
  const [email, setEmail] = useState('')

  const handleOTPChange = (e) => {
    setOtpCode(e.target.value)
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  useEffect(() => {
    if (!open) {
      setOtpCode('')
      setEmail('')
    }
  }, [open])

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className={s.dialog}>
        <DialogTitle className={s.dialogTitle}>
          <div className={s.dialogTitle}>Confirm MFA reset</div>
        </DialogTitle>
        <DialogActions style={{ width: '100%' }}>
          <div className={s.dialogActions}>
            <TextField
              label={'OTP code'}
              value={otpCode}
              onChange={handleOTPChange}
              variant="outlined"
              size="small"
            />
            <TextField
              label={'User email'}
              value={email}
              onChange={handleEmailChange}
              variant="outlined"
              size="small"
            />
            <Button
              onClick={() => handleSubmit(email, otpCode)}
              variant="contained"
              color="primary"
              autoFocus
            >
              Confirm
            </Button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default ResetDialog
