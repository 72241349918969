import Button from '@material-ui/core/Button'
import React, { useState } from 'react'
import BuySellWindow from './BuySellWindow'

const BNBTradeButton = ({ client }) => {
  const [open, setOpen] = useState(false)

  return (
    <React.Fragment>
      <BuySellWindow guid={client.guid} open={open} onClose={() => setOpen(false)} />
      <Button
        style={{ justifyContent: 'flex-start' }}
        color="primary"
        onClick={() => setOpen(true)}
      >
        Trade BNB
      </Button>
    </React.Fragment>
  )
}

export default BNBTradeButton
