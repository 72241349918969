import instance from './axiosConfig'

class ClientPositionService {
  async getAllClientPositions() {
    const response = await instance.get(`/api/client/positions`)
    return response.data
  }

  async getClientPositions(guid) {
    const response = await instance.get(`/api/client/${guid}/position`)
    return response.data
  }

  async getClientPosition(guid, symbol, exchange) {
    const response = await instance.get(
      `/api/client/${guid}/position/${symbol.toUpperCase()}?exchange=${exchange}`,
    )
    return response.data
  }

  async closeClientPosition(guid, symbol) {
    const response = await instance.post(
      `/api/client/${guid}/position/${symbol.toUpperCase()}/forceClose`,
    )
    return response
  }
}

const clientPositionService = new ClientPositionService()
export default clientPositionService
