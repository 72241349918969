import { useCallback } from 'react'
import requestService from '../../../service/RequestService'
import RequestListView from '../RequestListView/RequestListView'
import { useClients } from '../../../hooks/use-clients'

const AllClientsRequests = () => {
  useClients()

  const getRequests = useCallback((page) => requestService.getAllRequests(page - 1), [])

  return (
    <RequestListView groupByName={false} requestCallback={getRequests} title={`All Requests`} />
  )
}

export default AllClientsRequests
