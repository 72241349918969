import { useEffect, useState } from 'react'
import webSocketService, { RATE_LIMIT_TOPIC } from '../../service/WebSocketService'

const RateLimitView = () => {
  const [subscribeId, setSubscribeId] = useState(null)
  const [rateLimits, setRateLimits] = useState({})
  const [orderCounts, setOrderCounts] = useState({})
  useEffect(() => {
    async function fetchData() {
      const id = webSocketService.subscribe(RATE_LIMIT_TOPIC, (message) => handleUpdate(message))
      setSubscribeId(id)
    }

    fetchData()

    return () => {
      if (subscribeId) {
        webSocketService.unsubscribe(RATE_LIMIT_TOPIC, subscribeId)
      }
    }
  }, [])

  const handleUpdate = (message) => {
    if (message.rateInfo) {
      setRateLimits((prevState) => ({
        ...prevState,
        [message.exchange]: message.rateInfo,
      }))
    }
    if (message.orderCounts) {
      setOrderCounts((prevState) => ({
        ...prevState,
        [message.exchange]: message.orderCounts,
      }))
    }
  }

  return (
    <div>
      {Object.keys(rateLimits).map(function (keyName, keyIndex) {
        return (
          <li key={keyName}>
            {keyName} - Local Weigh [{rateLimits[keyName].localWeight}], API Weight [
            {rateLimits[keyName].apiWeight}]
          </li>
        )
      })}
      {Object.keys(orderCounts).map(function (keyName, keyIndex) {
        return (
          <li key={keyName}>
            {keyName} -{' '}
            {orderCounts[keyName].map((e) => `Orders [${e.time}]: ${e.count}`).join(', ')}
          </li>
        )
      })}
    </div>
  )
}

export default RateLimitView
