import { Typography } from '@material-ui/core'
import { useEffect, useMemo, useState } from 'react'
import infoService from '../../service/InfoService'
import { parseDate } from '../../utilities/number-helpers'
import OrderBookTable from './OrderBookTable/OrderBookTable'
import SchedulersTable from './SchedulersTable/SchedulersTable'
import WebSocketsTable from './WebSocketsTable/WebSocketsTable'
import { useInterval } from '../../hooks/use-interval'

const SystemHealth = () => {
  const [data, setData] = useState({})
  const [isFetching, setIsFetching] = useState(false)

  const fetchData = async () => {
    const data = await infoService.getSystemHealth()
    if (data) setData(data)

    setIsFetching(false)
  }

  useInterval(fetchData, 3000)

  useEffect(() => {
    setIsFetching(true)
    fetchData()
  }, [])

  return (
    <>
      <Typography variant="h5" gutterBottom style={{ margin: '20px 0' }}>
        Updated: {parseDate(data?.systemTime)}
      </Typography>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 24,
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <OrderBookTable data={data?.orderBookSubscriptions} loading={isFetching} />
        <SchedulersTable data={data?.schedulers} loading={isFetching} />
        <WebSocketsTable data={data?.wsConnections} loading={isFetching} />
      </div>
    </>
  )
}

export default SystemHealth
