import { Chip, Modal, Paper, TextField, Typography } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import React, { useState } from 'react'
import clientService from '../../../../service/ClientService'
import PopUp from '../../../common/PopUp'
import binanceSpotService from '../../../../service/BinanceSpotService'

const BuySellWindow = ({ guid, open, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState('')
  const [popup, setPopup] = useState({
    message: '',
    severity: 'success',
    open: false,
  })

  const tradeBNB = async (side) => {
    setLoading(true)
    try {
      const response = await binanceSpotService.tradeBNB(guid, value, side)
      if (response.status !== 200) {
        throw new Error(response.data.message)
      } else {
        setPopup({ message: `Successfull order`, severity: 'success', open: true })
      }
    } catch (e) {
      setPopup({ message: `Order failed: ${e.message}`, severity: 'error', open: true })
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setValue('')
    onClose()
  }

  const closePopup = () => setPopup((prev) => ({ ...prev, open: false }))

  return (
    <>
      <PopUp {...popup} closePopup={closePopup} />
      <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
        <CircularProgress color="inherit" size={90} />
      </Backdrop>
      <Modal open={open} onClose={handleClose} className={'centered'}>
        <Paper className="column" style={{ padding: 24, minWidth: 320, maxWidth: 450, gap: 18 }}>
          <Typography variant="h5" gutterBottom>
            Trade BNB
          </Typography>
          <TextField
            value={value}
            label="Amount"
            type="number"
            onChange={(e) => setValue(e.target.value)}
            variant="outlined"
            size="small"
          />
          <div className="flex-line justify-between">
            <Button
              disabled={loading || !value}
              color="primary"
              variant="contained"
              onClick={() => tradeBNB('BUY')}
            >
              Buy
            </Button>
            <Button
              disabled={loading || !value}
              color="secondary"
              variant="contained"
              onClick={() => tradeBNB('SELL')}
            >
              Sell
            </Button>
          </div>
        </Paper>
      </Modal>
    </>
  )
}

export default BuySellWindow
