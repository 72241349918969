import React from 'react'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import { STOPPED } from '../../../model/RequestState'

const RequestResultBox = ({ request, fillResult, canStop, stopCallback, asset, stopping }) => {
  return (
    <Paper
      elevation={2}
      className="align-center"
      style={{ display: 'flex', flexFlow: 'column', padding: 10 }}
    >
      <Typography variant="body1" gutterBottom style={{ marginRight: 'auto' }}>
        Result:
      </Typography>
      <div className="w-100 flex-line justify-around" style={{ marginBottom: 12 }}>
        <div className="column">
          <TextField
            id="filledAmount"
            label="Filled"
            margin="normal"
            disabled
            value={fillResult.filledAmount}
            InputProps={{
              startAdornment: <InputAdornment position="start">{asset}</InputAdornment>,
            }}
          />
          <TextField
            id="status"
            label="Request Status"
            margin="normal"
            disabled
            value={request.status}
          />
        </div>
        <div className="column">
          <TextField
            id="remainingAmount"
            label="Remaining"
            margin="normal"
            disabled
            value={fillResult.remainingAmount}
            InputProps={{
              startAdornment: <InputAdornment position="start">{asset}</InputAdornment>,
            }}
          />
          {request.status === STOPPED && (
            <TextField
              id="stopReason"
              label="Stop Reason"
              margin="normal"
              disabled
              value={request.cancelSource}
            />
          )}
        </div>
      </div>
      <Button color="secondary" disabled={stopping || !canStop} onClick={stopCallback}>
        {stopping ? <CircularProgress size={24.5} /> : 'Stop'}
      </Button>
    </Paper>
  )
}

export default React.memo(RequestResultBox)
