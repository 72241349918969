import { configureStore } from '@reduxjs/toolkit'
import clientsReducer from './slices/clients-slice'
import systemReducer from './slices/system-slice'
import thunkMiddleware from 'redux-thunk'

export default configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunkMiddleware),
  reducer: {
    clients: clientsReducer,
    system: systemReducer,
  },
})
