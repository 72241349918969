import { numberWithCommas } from '../../utilities/number-helpers'

export const COLUMNS = [
  {
    Header: 'Client',
    accessor: 'cid',
    disableFilters: true,
  },
  {
    Header: 'Account type',
    accessor: 'accountType',
    disableFilters: true,
  },
  {
    Header: 'Date',
    accessor: 'date',
    sortType: 'alphanumericFalsyLast',
    disableFilters: true,
  },
  {
    Header: 'Estimated balance',
    accessor: 'estimatedBalance',
    Cell: ({ value }) => numberWithCommas(value),
    sortType: 'alphanumericFalsyLast',
    disableFilters: true,
  },
  {
    Header: 'Invested amount',
    accessor: 'investedAmount',
    Cell: ({ value }) => numberWithCommas(value),
    sortType: 'alphanumericFalsyLast',
    disableFilters: true,
  },
  {
    Header: 'Funding reward',
    accessor: 'fundingReward',
    Cell: ({ value }) => numberWithCommas(value),
    sortType: 'alphanumericFalsyLast',
    disableFilters: true,
  },
  {
    Header: 'Project fee taken',
    accessor: 'projectFeeTaken',
    Cell: ({ value }) => numberWithCommas(value),
    sortType: 'alphanumericFalsyLast',
    disableFilters: true,
  },
  {
    Header: 'Project fee total',
    accessor: 'projectFeeTotal',
    Cell: ({ value }) => numberWithCommas(value),
    sortType: 'alphanumericFalsyLast',
    disableFilters: true,
  },
  {
    Header: 'Other gain/loss',
    accessor: 'otherGainLoss',
    Cell: ({ value }) => numberWithCommas(value),
    sortType: 'alphanumericFalsyLast',
    disableFilters: true,
  },
]
