import { numberWithCommas } from '../../utilities/number-helpers'

export const COLUMNS = [
  {
    Header: 'Client',
    accessor: 'cid',
    disableFilters: true,
  },
  {
    Header: 'Estimated balance',
    accessor: 'estimatedBalance',
    Cell: ({ value }) => numberWithCommas(value),
    disableFilters: true,
  },
  {
    Header: 'Free balance (USDT)',
    accessor: 'freeUSDT',
    Cell: ({ value }) => numberWithCommas(value),
    sortType: 'alphanumericFalsyLast',
    disableFilters: true,
  },
  {
    Header: 'Free balance, %',
    accessor: 'free',
    Cell: ({ value }) => numberWithCommas(value),
    sortType: 'alphanumericFalsyLast',
    disableFilters: true,
  },
  {
    Header: 'Last funding, %',
    accessor: 'lastFunding',
    Cell: ({ value }) => numberWithCommas(value),
    sortType: 'alphanumericFalsyLast',
    disableFilters: true,
  },
  {
    Header: 'Last 3d funding, %',
    accessor: 'last3dFunding',
    Cell: ({ value }) => numberWithCommas(value),
    sortType: 'alphanumericFalsyLast',
    disableFilters: true,
  },
]
