import instance from './axiosConfig'

class SymbolService {
  async reloadSymbols(exchange) {
    return await instance.post(`/api/symbol/${exchange}`)
  }
}

const symbolService = new SymbolService()
export default symbolService
