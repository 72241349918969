import React, { useEffect, useState } from 'react'
import s from './ExchangeConfiguration.module.css'

import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import configurationService from '../../../service/ConfigurationService'
import PopUp from '../../common/PopUp'
import { AddCircleRounded, Delete } from '@material-ui/icons'
import { Chip, Modal, Paper } from '@material-ui/core'
import symbolService from '../../../service/SymbolService'

const SymbolModal = ({ open, defaultExternal = '', defaultInternal = '', onSave, onClose }) => {
  const [internal, setInternal] = useState(defaultInternal)
  const [external, setExternal] = useState(defaultExternal)

  useEffect(() => {
    setInternal(defaultInternal)
    setExternal(defaultExternal)
  }, [defaultExternal, defaultInternal, open])

  const handleClose = () => {
    setInternal('')
    setExternal('')
    onClose()
  }

  const handleSave = () => {
    onSave(internal, external)
    onClose()
  }

  return (
    <Modal className={s.modal} open={open} onClose={onClose}>
      <Paper className={s.paper}>
        <Typography className="w-100">Symbol configuration</Typography>
        <TextField
          label="Internal symbol"
          value={internal}
          onChange={(e) => setInternal(e.target.value)}
          fullWidth
        />
        <TextField
          label="External symbol"
          value={external}
          onChange={(e) => setExternal(e.target.value)}
          fullWidth
        />
        <div className="centered justify-between w-100">
          <Button variant={'contained'} color="default" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant={'contained'} color="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      </Paper>
    </Modal>
  )
}

const AssetButton = ({ internalAsset, externalAsset, onDelete, onSave }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Chip
        variant="outlined"
        color={'primary'}
        size="small"
        label={internalAsset}
        clickable
        onClick={() => setOpen(true)}
        onDelete={onDelete}
        deleteIcon={<Delete />}
      />
      <SymbolModal
        open={open}
        onClose={() => setOpen(false)}
        onSave={onSave}
        defaultInternal={internalAsset}
        defaultExternal={externalAsset}
      />
    </>
  )
}

const ExchangeConfiguration = () => {
  const [selected, setSelected] = useState(0)
  const [data, setData] = useState([])
  const [openAsset, setOpenAsset] = useState(false)
  const [popup, setPopup] = useState({
    message: '',
    severity: 'success',
    open: false,
  })

  useEffect(() => {
    ;(async () => {
      const data = await configurationService.getExchangeConfig()

      if (data) setData(data)
    })()
  }, [])

  const handleSelect = (e) => {
    setSelected(e.target.value)
  }

  const handleChange = (e) => {
    setData((prev) =>
      prev.map((item, i) =>
        i === selected ? { ...prev[i], [e.target.id]: e.target.value } : item,
      ),
    )
  }

  const handleSave = async () => {
    const res = await configurationService.updateExchangeConfig(data[selected])

    if (res.status === 200) {
      setPopup({ open: true, severity: 'success', message: 'Exchange config saved successfully!' })
    } else {
      setPopup({
        open: true,
        severity: 'error',
        message: `Error saving config: ${res.data.message}`,
      })
    }
  }

  const handleReload = async () => {
    const exchange = data[selected].name
    const res = await symbolService.reloadSymbols(exchange)
    if (res.status === 200) {
      setPopup({
        open: true,
        severity: 'success',
        message: `${exchange} symbols successfully reload`,
      })
    } else {
      setPopup({
        open: true,
        severity: 'error',
        message: `Error reloading ${exchange} symbols: ${res.data.message}`,
      })
    }
  }

  const handleDeleteSymbolConfig = (key) => {
    setData((prev) => {
      const newAssetMapping = { ...prev[selected].assetMapping }
      delete newAssetMapping[key]
      return prev.map((config, i) =>
        selected === i ? { ...prev[i], assetMapping: newAssetMapping } : config,
      )
    })
  }

  const handleSaveSymbolConfig = (internalAsset, externalAsset) => {
    setData((prev) => {
      const newAssetMapping = { ...prev[selected].assetMapping, [internalAsset]: externalAsset }
      return prev.map((config, i) =>
        selected === i ? { ...prev[i], assetMapping: newAssetMapping } : config,
      )
    })
  }

  return (
    <div className={'column align-center'} style={{ marginTop: 12, gap: 12 }}>
      <PopUp {...popup} closePopup={() => setPopup((prev) => ({ ...prev, open: false }))} />
      <Typography variant="h6">Exchange configuration</Typography>
      <Select value={selected} label="Exchange" onChange={handleSelect} fullWidth>
        {data.map((item, i) => (
          <MenuItem value={i}>{item.name}</MenuItem>
        ))}
      </Select>
      <TextField
        id="excludeEstimatedBalanceAssets"
        label="Exclude Estimated assets"
        margin="normal"
        value={data[selected]?.excludeEstimatedBalanceAssets || ''}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        id="excludeHealthBalanceAssets"
        label="Exclude Health assets"
        margin="normal"
        value={data[selected]?.excludeHealthBalanceAssets || ''}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        id="takerFee"
        label="Taker fee"
        margin="normal"
        value={data[selected]?.takerFee || ''}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        id="makerFee"
        label="Maker fee"
        margin="normal"
        value={data[selected]?.makerFee || ''}
        onChange={handleChange}
        fullWidth
      />
      <Typography className="w-100">Asset mapping:</Typography>
      <div className={s.assets}>
        {data[selected]?.assetMapping &&
          Object.keys(data[selected]?.assetMapping).map((key) => (
            <AssetButton
              internalAsset={key}
              externalAsset={data[selected]?.assetMapping[key]}
              onDelete={() => handleDeleteSymbolConfig(key)}
              onSave={handleSaveSymbolConfig}
            />
          ))}
        <Chip
          variant="outlined"
          color={'primary'}
          size="small"
          label={
            <div className="centered">
              <AddCircleRounded style={{ marginRight: 4 }} fontSize="small" />
              ADD ASSET
            </div>
          }
          clickable
          onClick={() => setOpenAsset(true)}
        />
      </div>
      <SymbolModal
        open={openAsset}
        onClose={() => setOpenAsset(false)}
        onSave={handleSaveSymbolConfig}
      />
      <Button variant={'contained'} color={'primary'} onClick={handleSave}>
        Save
      </Button>
      <Button variant={'contained'} color={'primary'} onClick={handleReload}>
        Reload
      </Button>
    </div>
  )
}

export default ExchangeConfiguration
