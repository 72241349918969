import { Paper, Typography } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import ordersService from '../../service/OrdersService'
import LoadingScreen from '../common/LoadingScreen'
import SortableTable from '../Table/SortableTable'
import { COLUMNS } from './columns'
import s from './OrdersView.module.css'

const OrdersView = () => {
  const [isFetching, setIsFetching] = useState(false)
  const { guid } = useParams()

  const [data, setData] = useState([])
  const columns = useMemo(() => COLUMNS, [])

  const buildTable = (data) => {
    let table = []
    data.future.forEach((row) =>
      table.push({
        ...row,
        exchangePair: data.leftExchange,
      }),
    )
    data.spot.forEach((row) =>
      table.push({
        ...row,
        exchangePair: data.rightExchange,
      }),
    )
    return table
  }

  useEffect(() => {
    ;(async () => {
      setIsFetching(true)
      const data = await ordersService.getClientOrders(guid)

      if (data.future || data.spot) {
        const table = buildTable(data)
        setData(table)
      }
      setIsFetching(false)
    })()
  }, [guid])

  if (isFetching) return <LoadingScreen />

  return (
    <div className={s.root}>
      <Typography variant="h4" gutterBottom>
        Active Orders
      </Typography>
      <Paper elevation={2}>
        <SortableTable data={data} columns={columns} enableFilters enableGrouping />
      </Paper>
    </div>
  )
}

export default OrdersView
