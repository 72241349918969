import instance from './axiosConfig'

class OrdersService {
  async getClientOrders(guid) {
    const response = await instance.get(`/api/client/${guid}/order/all`)
    return response.data
  }

  async placeOrder(guid, symbol, amount, side, exchange, reduceOnly) {
    const response = await instance.post(`/api/client/${guid}/order`, {
      symbol,
      amount,
      side,
      exchange,
      reduceOnly,
    })
    return response
  }
}

const ordersService = new OrdersService()
export default ordersService
