import { IconButton, Paper, Typography } from '@material-ui/core'
import { DesktopWindows, MobileFriendly } from '@material-ui/icons'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useClients } from '../../hooks/use-clients'
import { formatFloat } from '../../utilities/number-helpers'
import LoadingScreen from '../common/LoadingScreen'
import SortableTable from '../Table/SortableTable'
import { COLUMNS } from './columns'
import s from './HealthView.module.css'

const HealthView = () => {
  const [compactView, setCompactView] = useState(false)
  const columns = useMemo(() => COLUMNS, [])
  const clients = useSelector((state) => state.clients.clients)
  const isFetching = useSelector((state) => state.clients.isFetching)

  const hiddenColumns = compactView
    ? ['positionValue', 'spotValue', 'locked', 'available', 'futures', 'unrealizedProfit']
    : []

  useClients()

  const structureData = useCallback(() => {
    const rows = []
    const activeClients = clients.filter((client) => !client.archived)

    activeClients.forEach((client) =>
      Object.entries(client.rightAccount.balance).forEach(([asset, spot]) => {
        if (['USDT', 'USD', 'USDC', 'BNB'].includes(asset)) return

        const position = client.leftAccount.openPositions.find((pos) => pos.baseAsset === asset)
        const differenceUSDT = !!position
          ? (spot.total + position?.currentPosition) * position.markPrice
          : spot.value
        rows.push({
          baseAsset: position?.baseAsset || asset,
          cid: client.cid,
          symbol: position?.symbol || `${asset}USDT`,
          markPrice: formatFloat(position?.markPrice, 2),
          locked: formatFloat(spot.locked, 0),
          available: formatFloat(spot.free, 0),
          futures: formatFloat(position?.currentPosition, 0),
          difference: Math.abs(formatFloat(spot.total + (position?.currentPosition || 0), 2)),
          health: formatFloat(position?.liquidationPercent, 2),
          exchangePair: `${client.leftAccount.exchange} - ${client.rightAccount.exchange}`,
          positionValue: formatFloat(position?.currentPosition * position?.markPrice, 0),
          spotValue: formatFloat(spot.value || 0, 0),
          unrealizedProfit: formatFloat(position?.unrealizedProfit, 0),
          differenceUSDT: Math.abs(formatFloat(differenceUSDT, 0)),
          brokenPosition: position?.brokenPosition || false,
        })
      }),
    )

    activeClients.forEach((client) =>
      client.leftAccount.openPositions.forEach((position) => {
        if (
          ['USDT', 'USD', 'USDC', 'BNB', ...rows.map((row) => row.baseAsset)].includes(
            position.baseAsset,
          )
        )
          return

        rows.push({
          baseAsset: position?.baseAsset,
          cid: client.cid,
          symbol: position?.symbol,
          markPrice: formatFloat(position?.markPrice, 2),
          futures: formatFloat(position?.currentPosition, 0),
          health: formatFloat(position?.liquidationPercent, 2),
          exchangePair: `${client.leftAccount.exchange} - ${client.rightAccount.exchange}`,
          positionValue: formatFloat(position?.currentPosition * position?.markPrice, 0),
          unrealizedProfit: formatFloat(position?.unrealizedProfit, 0),
          brokenPosition: position?.brokenPosition || false,
        })
      }),
    )

    return rows
  }, [clients])

  const data = useMemo(() => structureData(), [structureData])

  const toggleView = () => {
    setCompactView((prev) => !prev)
  }

  if (isFetching) return <LoadingScreen />

  return (
    <div className={s.root}>
      <Typography variant="h4" gutterBottom>
        Health View
      </Typography>
      <IconButton className={s.compactViewButton} onClick={toggleView}>
        <div style={{ display: 'flex', gap: 12 }}>
          {compactView ? <MobileFriendly /> : <DesktopWindows />}
          {compactView ? <small>Show desktop view</small> : <small>Show compact view</small>}
        </div>
      </IconButton>
      <Paper elevation={2}>
        <SortableTable data={data} columns={columns} hiddenColumns={hiddenColumns} enableFilters />
      </Paper>
    </div>
  )
}

export default HealthView
