import { createSlice } from '@reduxjs/toolkit'

export const clientsSlice = createSlice({
  name: 'clients',
  initialState: {
    clients: [],
    clientInfo: null,
    isFetching: false,
  },
  reducers: {
    setClients: (state, action) => {
      state.clients = action.payload
    },
    setClient: (state, action) => {
      if (state.clients && state.clients.length > 0)
        state.clients = state.clients.map((el) =>
          el.guid === action.payload.guid ? action.payload : el,
        )
    },
    setClientInfo: (state, action) => {
      state.clientInfo = action.payload
    },
    setActiveClient: (state, action) => {
      state.activeClient = action.payload
    },
    setIsFetching: (state, action) => {
      state.isFetching = action.payload
    },
  },
})

export const clientsActions = clientsSlice.actions

export default clientsSlice.reducer
