import React from 'react'

import { Redirect } from 'react-router-dom'
import axios from '../../../service/axiosConfig'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'

export default class LoginHr extends React.Component {
  state = {
    username: '',
    password: '',
  }

  onFormSubmit = async (e) => {
    e.preventDefault()

    let response_post = await axios.post(`/api/auth/signin`, {
      username: this.state.username,
      password: this.state.password,
    })
    this.props.setAuthenticate(
      response_post.status,
      response_post.headers.authorization,
      response_post.headers.role,
      response_post.headers.user_id,
    )
  }

  basicCode = () => {
    return btoa(`${this.state.username}:${this.state.password}`)
  }

  render() {
    if (this.props.authenticated === true && this.props.token !== null) {
      return <Redirect to={'/'} />
    }

    return (
      <div className="text-center" style={{ width: '18rem' }}>
        <TextField
          id="username"
          label="Username"
          margin="normal"
          value={this.state.username}
          onChange={(e) => this.setState({ username: e.target.value })}
        />

        <TextField
          id="password"
          type="password"
          label="Password"
          margin="normal"
          value={this.state.password}
          onChange={(e) => this.setState({ password: e.target.value })}
        />

        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={this.onFormSubmit}>Login</Button>
          </Box>
        </Grid>
      </div>
    )
  }
}
