import { numberWithCommas, parseDate } from '../../utilities/number-helpers'

export const TRADE_COLUMNS = [
  {
    Header: 'Symbol',
    accessor: 'symbol',
  },
  {
    Header: 'Order Id',
    accessor: 'orderId',
  },
  {
    Header: 'Price',
    accessor: 'price',
    Cell: ({ value }) => numberWithCommas(value),
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ value }) => numberWithCommas(value),
  },
  {
    Header: 'Executed quote',
    accessor: 'executedQuote',
    Cell: ({ value }) => numberWithCommas(value),
  },
  {
    Header: 'Commission',
    accessor: 'commission',
  },
  {
    Header: 'Commission asset',
    accessor: 'commissionAsset',
  },
  {
    Header: 'Time',
    accessor: 'time',
    Cell: ({ value }) => parseDate(value),
  },
]
