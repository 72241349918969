import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { EXECUTING } from '../../model/RequestState'
import clientPositionService from '../../service/ClientPositionService'
import requestService from '../../service/RequestService'
import BackButton from '../common/BackButton'
import PopUp from '../common/PopUp'
import RequestLogs from './RequestLogs/RequestLogs'
import RequestResultBox from './RequestResultBox/RequestResultBox'
import s from './RequestView.module.css'
import RequestCreate from 'components/RequestCreate/RequestCreate'
import { useDispatch } from 'react-redux'
import { clientsActions } from 'redux/slices/clients-slice'

import { recalcClientSpotBalance } from 'hooks/use-clients'

const getClient = async (id) => {
  const res = await clientPositionService.getClientPositions(id)

  return res
}

const getPosition = async (id, symbol, exchange) => {
  const res = await clientPositionService.getClientPosition(id, symbol, exchange)

  return res
}

const useRequestData = (id, setPopup, setStopping) => {
  const [position, setPosition] = useState()
  const [request, setRequest] = useState()
  const [client, setClient] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    const fetch = async () => {
      const requestRes = await requestService.findById(id)
      const clientRes = await getClient(requestRes.guid)
      const positionRes = await getPosition(requestRes.guid, requestRes.symbol, clientRes.leftAccount.exchange)

      const recalclClient = recalcClientSpotBalance(clientRes)

      dispatch(clientsActions.setClientInfo(recalclClient))

      setRequest(requestRes)
      setClient(recalclClient)
      setPosition(positionRes)
    }

    fetch()
  }, [])

  const stopExecution = useCallback(async () => {
    try {
      setStopping(true)
      await requestService.stopExecution(id)
      setPopup({
        message: 'Request stopped successfully',
        severity: 'success',
        open: true,
      })
    } catch (e) {
      const message = e.response?.data?.message
      console.log(e)
      setPopup({
        message: 'Cannot stop request: ' + message,
        severity: 'error',
        open: true,
      })
    } finally {
      setStopping(false)
    }
  }, [])

  return { position, request, client, stopExecution }
}

const RequestView = () => {
  const { id } = useParams()
  const [popup, setPopup] = useState({
    message: '',
    severity: 'success',
    open: false,
  })
  const [stopping, setStopping] = useState(false)

  const { position, request, client, stopExecution } = useRequestData(id, setPopup, setStopping)

  const closePopup = useCallback(() => {
    setPopup({
      message: '',
      severity: 'success',
      open: false,
    })
  }, [])

  return (
    <div className={s.root}>
      <PopUp {...popup} closePopup={closePopup} />
      <div className="centered-line justify-between">
        <Typography style={{ margin: '16px 0' }} variant="h5">
          Request {id} info
        </Typography>
        {id && <BackButton />}
      </div>
      {client && position && request && (
        <>
          <RequestCreate
            type={request.type}
            side={request.orderSide}
            guid={client.guid}
            request={request}
            position={position}
            viewForm
          />
          <RequestResultBox
            fillResult={request.fill}
            canStop={request.status === EXECUTING}
            asset={''}
            request={request}
            stopCallback={stopExecution}
            stopping={stopping}
          />
          <RequestLogs requestId={id} status={request.status} />
        </>
      )}
    </div>
  )
}

export default RequestView
