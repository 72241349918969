import Button from '@material-ui/core/Button'
import React from 'react'
import { useHistory } from 'react-router-dom'

const BackButton = (props) => {
  let history = useHistory()
  return (
    <Button {...props} onClick={() => history.push('/')}>
      Back
    </Button>
  )
}

export default BackButton
