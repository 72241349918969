import Button from '@material-ui/core/Button'
import React, { useState } from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import clientPositionService from '../../../../../service/ClientPositionService'
import { makeStyles, Tooltip } from '@material-ui/core'
import { useSelector } from 'react-redux'
import PopUp from '../../../../common/PopUp'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const ClosePositionButton = ({ client, position, markPrice, ...props }) => {
  const info = useSelector((state) => state.system)
  const { closeAmountLimit } = info

  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [popup, setPopup] = useState({
    message: '',
    severity: 'success',
    open: false,
  })

  const closePosition = async () => {
    setLoading(true)
    try {
      const response = await clientPositionService.closeClientPosition(client.guid, position.symbol)
      if (response.status === 200) {
        setPopup({
          message: 'Successfully closed position',
          severity: 'success',
          open: true,
        })
        return
      }
      throw Error(response?.data?.message)
    } catch (err) {
      setPopup({
        message: 'Could not force close position: ' + err,
        severity: 'error',
        open: true,
      })
    } finally {
      setLoading(false)
    }
  }

  const closePopup = () => {
    setPopup((prev) => ({ ...prev, open: false }))
  }

  const openPosition = Math.abs(Number(position.currentPosition))
  const canClose = openPosition * markPrice <= closeAmountLimit

  if (!canClose) {
    return null
  }
  return (
    <React.Fragment>
      <PopUp {...popup} closePopup={closePopup} />
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Tooltip title={'Remove futures if its value is <100 USDT'}>
        <Button {...props} color="secondary" variant="contained" onClick={closePosition}>
          Close
        </Button>
      </Tooltip>
    </React.Fragment>
  )
}

export default ClosePositionButton
