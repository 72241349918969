import binanceSpotService from '../../../../service/BinanceSpotService'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import s from './RefreshBNBModalButton.module.css'
import IconButton from '@material-ui/core/IconButton'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import React, { useState } from 'react'
import { Button, Typography } from '@material-ui/core'
import PopUp from '../../../common/PopUp'

const RefreshModalButton = ({ cid }) => {
  const [open, setOpen] = useState(false)
  const [changingStatus, setChangingStatus] = useState(false)
  const [popup, setPopup] = useState({
    message: '',
    severity: 'success',
    open: false,
  })

  const bnbRefresh = async () => {
    setChangingStatus(true)
    try {
      const response = await binanceSpotService.refreshBNB(cid)
      if (response.status !== 200) {
        throw new Error(response.data.message)
      } else {
        setPopup({ message: `BNB Successfully Refreshed`, severity: 'success', open: true })
      }
    } catch (e) {
      setPopup({ message: `BNB Refresh Failed: ${e.message}`, severity: 'error', open: true })
    } finally {
      setChangingStatus(false)
    }
  }

  const status = changingStatus ? (
    <span style={{ color: 'green' }}>ON</span>
  ) : (
    <span style={{ color: 'red' }}>OFF</span>
  )

  const closePopup = () => setPopup((prev) => ({ ...prev, open: false }))

  return (
    <>
      <Button
        style={{ justifyContent: 'flex-start' }}
        color="primary"
        aria-label="refresh bnb"
        onClick={() => setOpen(true)}
      >
        Refresh BNB
      </Button>
      <Modal className={s.modal} open={open} onClose={() => setOpen(false)}>
        <Paper className={s.paper}>
          <PopUp {...popup} closePopup={closePopup} />
          <div className={s.heading}>
            <Typography variant="h6">BNB Refresh</Typography>
            <IconButton size="small" onClick={() => setOpen(false)}>
              <CloseRoundedIcon />
            </IconButton>
          </div>
          <Typography variant="h4">Current refresh status: {status}</Typography>
          <Button
            onClick={bnbRefresh}
            variant={'contained'}
            color="primary"
            disabled={changingStatus}
          >
            BNB Refresh
          </Button>
        </Paper>
      </Modal>
    </>
  )
}

export default RefreshModalButton
