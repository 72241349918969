import React, { useState, useMemo } from 'react'
import s from './MoreActionsButton.module.css'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { Button, Popover } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import RepeatIcon from '@material-ui/icons/Repeat'
import { Link } from 'react-router-dom'
import ArchiveClientButton from '../ArchiveClientButton/ArchiveClientButton'
import DeleteClientButton from '../DeleteClientButton/DeleteClientButton'
import HistoryButton from '../HistoryButton/HistoryButton'
import RefreshModalButton from '../RefreshModal/RefreshModalButton'
import RefreshBNBModalButton from '../RefreshBNBModal/RefreshBNBModalButton'
import { BINANCE_FUTURES, BINANCE_SPOT } from '../../../../model/Exchange'
import TransferDialog from '../TransferDialog/TransferDialog'
import DustToBNBButton from '../DustToBNBButton/DustToBNBButton'
import BNBTradeButton from '../BNBTradeButton/BNBTradeButton'
import OrderExecutionButton from '../OrderExecutionButton/OrderExecutionButton'
import NestedMenu from './NestedMenu'

const MoreActionsButton = ({ client }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openTransfer, setOpenTransfer] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickOpenTransfer = () => {
    setOpenTransfer(true)
  }

  const handleTransferClose = () => {
    setOpenTransfer(false)
  }

  const transferAvailable = useMemo(
    () =>
      client.leftAccount.exchange === BINANCE_FUTURES ||
      client.rightAccount.exchange === BINANCE_SPOT,
    [client.leftAccount.exchange, client.rightAccount.exchange],
  )

  const open = Boolean(anchorEl)
  const id = open ? 'actions' : undefined

  return (
    <React.Fragment>
      <Tooltip title={'More actions'}>
        <IconButton color="primary" aria-label="delete client" onClick={handleClick}>
          <MoreHorizIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={s.popover}>
          {!client.archived && (
            <>
              {transferAvailable && (
                <Button
                  style={{ justifyContent: 'flex-start' }}
                  color="primary"
                  aria-label="transfer funds"
                  onClick={handleClickOpenTransfer}
                >
                  <RepeatIcon />
                  &nbsp;Transfer Funds
                </Button>
              )}
              <RefreshModalButton cid={client.cid} refreshing={client.refreshing} />
              <Button
                style={{ justifyContent: 'flex-start' }}
                color="primary"
                aria-label="modify client"
                component={Link}
                to={`/client/${client.cid}`}
              >
                <EditIcon />
                &nbsp;Modify Client
              </Button>
              <DeleteClientButton client={client} positions={client.leftAccount.openPositions} />
              <HistoryButton
                guid={client.guid}
                cid={client.cid}
                activeRequests={client.activeRequests}
              />
            </>
          )}
          <ArchiveClientButton client={client} positions={client.leftAccount.openPositions} />
          <NestedMenu>
            <RefreshBNBModalButton cid={client.cid} />
            <BNBTradeButton client={client} />
            <DustToBNBButton
              disabled={client.rightAccount?.exchange !== BINANCE_SPOT}
              client={client}
            />
          </NestedMenu>
          <OrderExecutionButton client={client} />
          <Button
            style={{ justifyContent: 'flex-start' }}
            disabled={client.archived}
            color="primary"
            component={Link}
            to={`/orders/${client.guid}`}
          >
            Active Orders
          </Button>
          <Button
            style={{ justifyContent: 'flex-start' }}
            disabled={client.archived}
            color="primary"
            component={Link}
            to={`/client/${client.cid}/request`}
          >
            Show Requests
          </Button>
        </div>
      </Popover>
      <TransferDialog
        open={openTransfer}
        onClose={handleTransferClose}
        cid={client.cid}
        guid={client.guid}
      />
    </React.Fragment>
  )
}

export default MoreActionsButton
