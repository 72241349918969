import { localesLanguages } from 'config/locales'
import { createContext } from 'react'

const defaultValues = {
  locale: localesLanguages.en,
  setLocale: () => {},
}

export const Context = createContext(defaultValues)

export default Context
