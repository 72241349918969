import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

const RequestMarketBox = ({ className, onSubmit, market, loader }) => {
  return (
    <Paper elevation={2} className={className}>
      <div className="flex-line centered" style={{ marginBottom: 12 }}>
        <TextField
          id="priceSpot"
          label="Price Spot"
          margin="normal"
          disabled
          value={market.spotPrice}
          style={{ marginLeft: 5, marginRight: 5 }}
        />
        <TextField
          id="priceFutures"
          label="Price Futures"
          margin="normal"
          disabled
          value={market.futuresPrice}
          style={{ marginLeft: 5, marginRight: 5 }}
        />
        <TextField
          id="currentDifference"
          label="Difference %"
          margin="normal"
          disabled
          value={market.currentDifference}
          style={{ marginLeft: 5, marginRight: 5 }}
        />
      </div>
      <Button
        onClick={onSubmit}
        variant="outlined"
        color="primary"
      >
        Execute
      </Button>
      {loader}
    </Paper>
  )
}

export default RequestMarketBox
