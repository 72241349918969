import messages_en from './en'

export const localesLanguages = {
  en: 'en',
}

const parseLocalesList = (object, parentKey) => {
  const keys = Object.keys(object)

  return keys.reduce((acc, key) => {
    const value = object[key]
    const objectKey = parentKey ? `${parentKey}.${key}` : key

    if (typeof value === 'string') {
      return {
        ...acc,
        [objectKey]: value,
      }
    }

    return {
      ...acc,
      ...parseLocalesList(value, objectKey),
    }
  }, {})
}

const locales = {
  en: parseLocalesList(messages_en),
}

export const defaultLocale = localesLanguages.en

export default locales
