import instance from './axiosConfig'
import { BINANCE_SPOT } from '../model/Exchange'

class MarketService {
  async getMarketDetails(request) {
    const response = await instance.post(`/api/market/details`, request)
    return response.data
  }

  async getMarketPrice(symbol, exchange = BINANCE_SPOT) {
    const response = await instance.get(`/api/market/price?symbol=${symbol}&exchange=${exchange}`)
    return response?.data
  }
}

const marketService = new MarketService()
export default marketService
