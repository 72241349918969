import instance from './axiosConfig'

class BinanceSpotService {
  async transferFromSpot(cid, asset, amount) {
    return this.transfer(cid, asset, amount, 'FROM_SPOT')
  }

  async transferFromFutures(cid, asset, amount) {
    return this.transfer(cid, asset, amount, 'FROM_FUTURES')
  }

  async transfer(cid, asset, amount, direction) {
    return await instance.post(`/api/spot/binance/transfer`, {
      cid: cid,
      asset: asset,
      amount: amount,
      direction: direction,
    })
  }

  async dustToBNB(guid, assets) {
    return await instance.post(`/api/spot/binance/dustToBNB`, {
      guid: guid,
      assets: assets,
    })
  }

  async refreshBNB(guid) {
    return await instance.post(`/api/spot/binance/refreshBNB?clientIdentifier=${guid}`)
  }

  async tradeBNB(clientIdentifier, amount, side) {
    return await instance.post(`/api/spot/binance/tradeBNB`, { clientIdentifier, amount, side })
  }

  async getBalance(guid) {
    const response = await instance.get(`/api/spot/binance/balance?clientIdentifier=${guid}`)
    return response.data
  }
}

const binanceSpotService = new BinanceSpotService()
export default binanceSpotService
