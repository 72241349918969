import instance from './axiosConfig'

class ClientService {
  async getClient(cid) {
    const response = await instance.get(`/api/client/${cid}`)
    return response.data
  }

  async saveClient(client) {
    const response = await instance.post(`/api/client`, client)
    return response.data
  }

  async updateClient(cid, client) {
    const response = await instance.patch(`/api/client/${cid}`, client)
    return response.data
  }

  async deleteClient(cid) {
    await instance.delete(`/api/client/${cid}`)
  }

  async archiveClient(cid) {
    await instance.post(`/api/client/${cid}/archive`)
  }

  async unArchiveClient(cid) {
    await instance.delete(`/api/client/${cid}/archive`)
  }

  async updateSymbolConfig(cid, symbolConfig) {
    const response = await instance.post(`/api/client/${cid}/symbol`, symbolConfig)
    return response.data
  }

  async getSymbolConfig(cid, symbol) {
    const response = await instance.get(`/api/client/${cid}/symbol/${symbol}`)
    return response.data
  }

  async startRefresh(cid) {
    await instance.post(`/api/client/${cid}/refresh`)
  }

  async stopRefresh(cid) {
    await instance.delete(`/api/client/${cid}/refresh`)
  }

  async getClientsHealth() {
    const response = await instance.get(`/api/client/health`)
    return response.data
  }

  async getClientFinances(Accept, guid, startDate, endDate, page, size) {
    const response = await instance.get(`/api/report/finances`, {
      headers: { Accept },
      params: { client: guid, startDate, endDate, page, size },
    })
    return response.data
  }
}

const clientService = new ClientService()
export default clientService
