export const B2C2_FUTURES = 'B2C2_FUTURES'
export const BINANCE_FUTURES = 'BINANCE_FUTURES'
export const BINANCE_SPOT = 'BINANCE_SPOT'
export const FTX_FUTURES = 'FTX_FUTURES'
export const ENIGMA_SPOT = 'ENIGMA_SPOT'
export const BITFINEX_SPOT = 'BITFINEX_SPOT'

export const DISPLAY_NAMES = new Map([
  [B2C2_FUTURES, 'B2C2 CFD'],
  [BINANCE_FUTURES, 'Binance Futures'],
  [BINANCE_SPOT, 'Binance Spot'],
  [FTX_FUTURES, 'FTX Futures'],
  [ENIGMA_SPOT, 'Enigma Spot'],
  [BITFINEX_SPOT, 'Bitfinex Spot'],
])

export const TYPE_SPOT = 'SPOT'
export const TYPE_FUTURES = 'FUTURES'
