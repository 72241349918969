import React from 'react'
import LoginHr from './LoginHr/LoginHr'
import Grid from '@material-ui/core/Grid'

export default class LoginPage extends React.Component {
  setAuthenticate = () => {}

  render() {
    return (
      <Grid container>
        <Grid item xs={12} style={{ marginTop: '10%' }} />
        <Grid item xs={12}>
          <LoginHr
            authenticated={this.props.authenticated}
            setAuthenticate={this.setAuthenticate}
          />
        </Grid>
        <Grid item style={{ marginBottom: '10px', marginTop: '40px' }}></Grid>
      </Grid>
    )
  }
}
