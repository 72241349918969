import instance from './axiosConfig'

class HistoryService {
  async getUserTrades(Accept, client, symbol, exchangeType, startDate, endDate, limit = 1000) {
    const response = await instance.get(
      `/api/report/trades?client=${client}&symbol=${symbol}&exchangeType=${exchangeType}&startDate=${startDate}&endDate=${endDate}&limit=${limit}`,
      { headers: { Accept } },
    )
    return response
  }

  async getUserIncomes(Accept, client, startDate, endDate) {
    const response = await instance.get(
      `/api/report/incomes?client=${client}&startDate=${startDate}&endDate=${endDate}`,
      {
        headers: { Accept },
      },
    )
    return response
  }

  async getUserOperations(Accept, client) {
    const response = await instance.get(`/api/report/history?client=${client}`, {
      headers: { Accept },
    })
    return response
  }
}

const historyService = new HistoryService()
export default historyService
