import React, { useEffect, useState } from 'react'
import s from './ClientModifyView.module.css'
import moment from 'moment'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { useHistory, useParams } from 'react-router-dom'
import BackButton from '../common/BackButton'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import clientService from '../../service/ClientService'
import LoadingScreen from '../common/LoadingScreen'
import {
  B2C2_FUTURES,
  BINANCE_FUTURES,
  BINANCE_SPOT,
  BITFINEX_SPOT,
  DISPLAY_NAMES,
  ENIGMA_SPOT,
  FTX_FUTURES,
} from '../../model/Exchange'
import { PRIV, EST, CT } from '../../model/AccountType'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { DatePicker } from '@material-ui/pickers'
import ClearIcon from '@material-ui/icons/Clear'

const ACCOUNT_TYPES = [PRIV, EST, CT]

export const ClientModifyView = () => {
  const [client, setClient] = useState({
    cid: '',
    futuresKeys: {},
    spotKeys: {},
    usernameWhitelist: '',
    leftExchange: '',
    rightExchange: '',
    defence0Enabled: false,
    invested: '0',
    extraProfitEarned: '',
    activeFrom: null,
  })

  const history = useHistory()
  const { id } = useParams()

  const [loading, setLoading] = useState(id !== undefined)

  useEffect(() => {
    ;(async () => {
      if (id) {
        try {
          const data = await clientService.getClient(id)
          const client = {
            ...data,
            activeFrom: data?.activeFrom
              ? moment(data.activeFrom, 'DD.MM.YYYY').format('YYYY-MM-DD')
              : null,
          }
          setClient(client)
        } finally {
          setLoading(false)
        }
      }
    })()
  }, [])

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.id
    setClient((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleDateChange = (date) => {
    setClient((prev) => ({
      ...prev,
      activeFrom: date.format('YYYY-MM-DD'),
    }))
  }

  const handleClearDate = (e) => {
    e.stopPropagation()
    setClient((prev) => ({
      ...prev,
      activeFrom: null,
    }))
  }

  const handleKeyInputChange = (event, fieldName) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.id
    let keys = client[fieldName]
    if (keys === null) {
      keys = {}
    }
    keys[name] = value
    setClient((prev) => ({
      ...prev,
      [fieldName]: keys,
    }))
  }

  const handleSelectChange = (event, fieldName) => {
    const value = event.target.value
    setClient((prev) => ({
      ...prev,
      [fieldName]: value,
    }))
  }

  const saveClient = async (history) => {
    const value = {
      ...client,
      activeFrom: client.activeFrom ? moment(client.activeFrom).format('DD.MM.YYYY') : null,
    }

    if (id) {
      await clientService.updateClient(value.guid, value)
    } else {
      await clientService.saveClient(value)
    }
    history.push(`/`)
  }

  return (
    <Paper elevation={8} className={s.paper}>
      <div className="flex-line justify-between" style={{ marginBottom: 20 }}>
        <Typography variant="h4">{id ? `Update Client '${id}'` : 'Create Client'}</Typography>
        <BackButton />
      </div>

      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <div className="centered-line">
            {id && (
              <TextField
                style={{ marginRight: 24 }}
                id="guid"
                label="GUID"
                margin="normal"
                disabled
                required
                value={client.guid}
              />
            )}
            <TextField
              id="cid"
              label="CID"
              margin="normal"
              style={{ marginRight: 24 }}
              disabled={!!id}
              required={!id}
              value={client.cid}
              onChange={handleInputChange}
            />
            <FormControlLabel
              control={
                <Checkbox color="primary" id="defence0Enabled" checked={client.defence0Enabled} />
              }
              label="Defence 0 Enabled"
              onChange={handleInputChange}
            />
          </div>
          <FormControl className={s.input}>
            <InputLabel id="demo-simple-select-label">Left Exchange</InputLabel>
            <Select
              labelId="left-exchange"
              id="leftExchange"
              required
              value={client.leftExchange}
              onChange={(e) => handleSelectChange(e, 'leftExchange')}
            >
              {[BINANCE_FUTURES, FTX_FUTURES].map((exc) => (
                <MenuItem key={exc} value={exc}>
                  {DISPLAY_NAMES.get(exc)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="apiKey"
            label="Futures API Key"
            margin="normal"
            fullWidth
            onChange={(e) => handleKeyInputChange(e, 'futuresKeys')}
          />
          <TextField
            id="secretKey"
            label="Futures Secret Key"
            margin="normal"
            fullWidth
            onChange={(e) => handleKeyInputChange(e, 'futuresKeys')}
          />
          {[FTX_FUTURES].includes(client.leftExchange) ? (
            <TextField
              id="subAccount"
              label="Futures Sub Account"
              margin="normal"
              fullWidth
              onChange={(e) => handleKeyInputChange(e, 'futuresKeys')}
            />
          ) : null}
          <TextField
            id="invested"
            label="Invested Amount $"
            margin="normal"
            type="number"
            fullWidth
            value={client.invested}
            onChange={handleInputChange}
          />
          <TextField
            id="extraProfitEarned"
            label="Extra Profit Earned $"
            margin="normal"
            type="number"
            fullWidth
            value={client.extraProfitEarned}
            onChange={handleInputChange}
          />
          <TextField
            id="projectFeePercent"
            label="Project Fee %"
            margin="normal"
            type="number"
            fullWidth
            value={client.projectFeePercent}
            onChange={handleInputChange}
          />
          <TextField
            id="projectFeeTakenFromAccount"
            label="Project fee taken from account"
            margin="normal"
            type="number"
            fullWidth
            value={client.projectFeeTakenFromAccount}
            onChange={handleInputChange}
          />
          <TextField
            id="projectFeeTakenFromOtherSource"
            label="Project fee taken from other source"
            margin="normal"
            type="number"
            fullWidth
            value={client.projectFeeTakenFromOtherSource}
            onChange={handleInputChange}
          />
          <TextField
            id="usernameWhitelist"
            label="Notification Usernames"
            margin="normal"
            fullWidth
            value={client.usernameWhitelist}
            onChange={handleInputChange}
          />
          <FormControl className={s.input}>
            <DatePicker
              id="activeFrom"
              label="Active from"
              format="DD.MM.yyyy"
              value={client.activeFrom}
              onChange={handleDateChange}
              InputProps={{
                endAdornment: (
                  <IconButton size="small" onClick={(e) => handleClearDate(e)}>
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
          </FormControl>
          <FormControl className={s.input}>
            <InputLabel id="demo-simple-select-label">Spot Exchange</InputLabel>
            <Select
              labelId="right-exchange"
              id="rightExchange"
              required
              value={client.rightExchange}
              onChange={(e) => handleSelectChange(e, 'rightExchange')}
            >
              {[
                B2C2_FUTURES,
                BINANCE_SPOT,
                ENIGMA_SPOT,
                BITFINEX_SPOT,
                FTX_FUTURES,
                BINANCE_FUTURES,
              ].map((exc) => (
                <MenuItem key={exc} value={exc}>
                  {DISPLAY_NAMES.get(exc)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={s.input}>
            <InputLabel id="demo-simple-select-label">Account Type</InputLabel>
            <Select
              labelId="account-type"
              id="accountType"
              required
              value={client.accountType}
              onChange={(e) => handleSelectChange(e, 'accountType')}
            >
              {ACCOUNT_TYPES.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {[BINANCE_SPOT, BITFINEX_SPOT, FTX_FUTURES, BINANCE_FUTURES].includes(
            client.rightExchange,
          ) ? (
            <React.Fragment>
              <TextField
                id="apiKey"
                label="Spot API Key"
                margin="normal"
                fullWidth
                onChange={(e) => handleKeyInputChange(e, 'spotKeys')}
              />
              <TextField
                id="secretKey"
                label="Spot Secret Key"
                margin="normal"
                fullWidth
                onChange={(e) => handleKeyInputChange(e, 'spotKeys')}
              />
              {[FTX_FUTURES].includes(client.rightExchange) ? (
                <TextField
                  id="subAccount"
                  label="Spot Sub Account"
                  margin="normal"
                  fullWidth
                  onChange={(e) => handleKeyInputChange(e, 'spotKeys')}
                />
              ) : null}
            </React.Fragment>
          ) : null}
          {client.rightExchange === B2C2_FUTURES ? (
            <React.Fragment>
              <TextField
                id="token"
                label="Auth token"
                margin="normal"
                fullWidth
                onChange={(e) => handleKeyInputChange(e, 'spotKeys')}
              />
            </React.Fragment>
          ) : null}
          <Button variant="contained" color="primary" onClick={() => saveClient(history)}>
            Save
          </Button>
        </>
      )}
    </Paper>
  )
}

export default ClientModifyView
