import Container from '@material-ui/core/Container'
import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.css'
import ClientModifyView from './components/ClientModifyView/ClientModifyView'
import LoadingScreen from './components/common/LoadingScreen'
import ConfigurationView from './components/ConfigurationView/ConfigurationView'
import Header from './components/Header/Header'
import HealthView from './components/HealthView/HealthView'
import HomeClient from './components/HomeClient/HomeClient'
import LoginPage from './components/LoginPage/LoginPage'
import RateLimitView from './components/RateLimit/RateLimitView'
import ActiveRequests from './components/requests_list/ActiveRequests/ActiveRequests'
import ClientRequests from './components/requests_list/ClientRequests/ClientRequests'
import RequestView from './components/RequestView/RequestViewNew'
import SymbolConfiguration from './components/SymbolConfiguration/SymbolConfiguration'
import PrivateRoute from './hoc/PrivateRoute'
import { useInit } from './hooks/use-init'
import store from './redux/store'
import OrdersView from './components/OrdersView/OrdersView'
import SystemHealth from './components/SystemHealth/SystemHealth'
import ClientHealth from './components/ClientHealth/ClientHealth'
import AllClientsRequests from './components/requests_list/AllClientsRequests/AllClientsRequests'
import HistoryView from './components/HistoryView/HistoryView'
import Notifications from './components/Notifications/Notifications'
import ClientFinances from './components/ClientFinances/ClientFinances'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import UserManagement from './components/UserManagement/UserManagement'
import LocaleProvider from 'providers/Locale'
// import { getClients } from "./redux/thunks/clients-thunks"

const App = () => {
  const isAuth = useInit()

  if (!isAuth)
    return (
      <Container>
        <LoadingScreen />
      </Container>
    )

  return (
    <Container maxWidth="xl">
      <Header />
      <Switch>
        <PrivateRoute exact path="/">
          <RateLimitView />
          <HomeClient />
        </PrivateRoute>
        <Route path="/login">
          <LoginPage authenticated={isAuth} />
        </Route>
        <PrivateRoute path="/client/:cid/symbol/:symbol">
          <SymbolConfiguration />
        </PrivateRoute>
        <PrivateRoute path="/client/:cid/request">
          <ClientRequests />
        </PrivateRoute>
        <PrivateRoute path="/client/:id">
          <ClientModifyView />
        </PrivateRoute>
        <PrivateRoute path="/client">
          <ClientModifyView />
        </PrivateRoute>
        <PrivateRoute path="/request/active">
          <ActiveRequests />
        </PrivateRoute>
        <PrivateRoute path="/request/:id">
          <RequestView />
        </PrivateRoute>
        <PrivateRoute path="/config">
          <ConfigurationView />
        </PrivateRoute>
        <PrivateRoute path="/orders/:guid">
          <OrdersView />
        </PrivateRoute>
        <PrivateRoute path="/health-view">
          <HealthView />
        </PrivateRoute>
        <PrivateRoute path="/system-health">
          <SystemHealth />
        </PrivateRoute>
        <PrivateRoute path="/client-health">
          <ClientHealth />
        </PrivateRoute>
        <PrivateRoute path="/all-requests">
          <AllClientsRequests />
        </PrivateRoute>
        <PrivateRoute path="/history-view/:cid">
          <HistoryView />
        </PrivateRoute>
        <PrivateRoute path="/notifications">
          <Notifications />
        </PrivateRoute>
        <PrivateRoute path="/client-finances">
          <ClientFinances />
        </PrivateRoute>
        <PrivateRoute path="/user-management">
          <UserManagement />
        </PrivateRoute>
      </Switch>
    </Container>
  )
}

class AppWrap extends Component {
  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo)
  }

  render() {
    return (
      <Provider store={store}>
        <LocaleProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router>
              <App />
            </Router>
          </MuiPickersUtilsProvider>
        </LocaleProvider>
      </Provider>
    )
  }
}

export default AppWrap
