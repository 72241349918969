import requestService from '../../service/RequestService'
import infoService from '../../service/InfoService'
import { systemActions } from '../slices/system-slice'

export const getInfo = () => async (dispatch) => {
  const [info, activeRequestCount] = await Promise.all([
    infoService.getInfo(),
    requestService.getActiveRequestCount(),
  ])

  dispatch(
    systemActions.setInfo({
      environment: info.environment,
      suspended: info.suspended,
      speculationSuspended: info.speculationSuspended,
      closeAmountLimit: info.closeAmountLimit,
    }),
  )
  dispatch(systemActions.setActiveRequestCount(activeRequestCount))
}
