import { useState, useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import locales, { defaultLocale, localesLanguages } from 'config/locales'

import Context from './Context'

const isDevelopment = process?.env?.NODE_ENV === 'development'

const Provider = ({ children, persistKey = 'locale' }) => {
  const persistLocale = localStorage.getItem(persistKey)

  const persistValue =
    (persistLocale && persistLocale in localesLanguages && persistLocale) || defaultLocale

  const [locale, setLocale] = useState(persistValue)

  const handleIntlProviderError = (err) => {
    if (isDevelopment) {
      console.log('IntlProvider Error: >', err)
    }
  }

  useEffect(() => {
    try {
      localStorage.setItem(persistKey, locale)
    } catch (error) {
      console.warn(error)
    }
  }, [locale, persistKey])

  return (
    <Context.Provider value={{ locale, setLocale }}>
      <IntlProvider
        locale={locale}
        defaultLocale={defaultLocale}
        messages={locales[locale]}
        onError={handleIntlProviderError}
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export default Provider
