import * as SockJS from 'sockjs-client'
import * as Stomp from 'stomp-websocket'
import { authHeader } from './axiosConfig'

export const CLIENT_TOPIC = '/topic/client'
export const REQUEST_TOPIC = '/topic/request'
export const RATE_LIMIT_TOPIC = '/topic/rateLimit'

class WebSocketService {
  constructor() {
    this.registrations = [
      {
        route: CLIENT_TOPIC,
        callback: this._handleClientTopic,
      },
      {
        route: REQUEST_TOPIC,
        callback: this._handleRequestTopic,
      },
      {
        route: RATE_LIMIT_TOPIC,
        callback: this._handleRateLimitTopic,
      },
    ]
    this.stompClient = null
    this.listeners = {}
    this.reconnects = 0
  }

  successCallback(frame) {
    if (!this.stompClient.connected) {
      this._reconnect()
      return
    }

    console.log('WS Connection established', new Date().toTimeString())
    this.registrations.forEach((registration) => {
      this.stompClient.subscribe(registration.route, registration.callback)
    })
  }

  _connect() {
    if (this.stompClient != null) {
      return
    }

    let headers = authHeader()
    if (headers.Authorization === undefined) return

    this.socket = new SockJS(process.env.REACT_APP_BASE_URL + 'ws')
    //this.stompClient = Stomp.client(process.env.REACT_APP_WS_URL)
    this.stompClient = Stomp.over(this.socket)
    this.stompClient.heartbeat.outgoing = 20000
    this.stompClient.heartbeat.incoming = 20000
    this.stompClient.reconnect_delay = 5000
    this.stompClient.debug = null

    this.stompClient.connect(
      headers,
      (frame) => {
        this.successCallback()
      },
      () => {
        this._reconnect()
      },
    )
  }

  _reconnect() {
    console.log('Could not connect to WS', new Date().toTimeString())

    let headers = authHeader()
    if (headers.Authorization === undefined) return

    let connected = false
    let reconInv = setInterval(() => {
      ++this.reconnects
      if (this.reconnects > 15) {
        console.log('Could not connect to WS. Check your internet connection and reload the page')
        clearInterval(reconInv)
        return
      }

      this.socket = new SockJS(process.env.REACT_APP_BASE_URL + 'ws')
      this.stompClient = Stomp.over(this.socket)
      this.stompClient.heartbeat.outgoing = 20000
      this.stompClient.heartbeat.incoming = 20000
      this.stompClient.reconnect_delay = 5000
      this.stompClient.debug = null
      this.stompClient.connect(
        headers,
        (frame) => {
          clearInterval(reconInv)
          connected = true
          this.successCallback()
        },
        () => {
          if (connected) {
            this._reconnect()
          }
        },
      )
    }, 1500)
  }

  subscribe(topic, callback) {
    this._connect()
    if (!this.listeners[topic]) {
      this.listeners[topic] = {}
    }
    const id = Math.random().toString(36).substr(2, 5)
    console.log(`Subscribing ${id} on a topic ${topic}`)
    this.listeners[topic][id] = callback
    return id
  }

  unsubscribe = (topic, id) => {
    console.log(`Unsubscribing ${id} from a topic ${topic}`)
    delete this.listeners[topic][id]
  }

  _handleClientTopic = (message) => {
    // console.log(`Clients topic update ${message}`)
    this._pushToSubscribers(message, CLIENT_TOPIC)
  }

  _handleRequestTopic = (message) => {
    // console.log(`Request topic update ${message}`)
    this._pushToSubscribers(message, REQUEST_TOPIC)
  }

  _handleRateLimitTopic = (message) => {
    // console.log(`Request topic update ${message}`)
    this._pushToSubscribers(message, RATE_LIMIT_TOPIC)
  }

  _pushToSubscribers(message, topic) {
    const listener = this.listeners[topic]
    if (!!listener) {
      const obj = JSON.parse(message.body)
      Object.entries(listener).forEach(([key, value]) => value(obj))
    }
  }
}

const webSocketService = new WebSocketService()

export default webSocketService
