import React, { useState } from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import clientService from '../../../../service/ClientService'
import DeleteIcon from '@material-ui/icons/Delete'
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core'

const style = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
})

const DeleteClientButton = withStyles(style)(({ classes, client, positions }) => {
  const [loading, setLoading] = useState(false)
  const [showConfigDialog, setShowConfigDialog] = useState(false)

  const deleteClient = async () => {
    setLoading(true)
    try {
      await clientService.deleteClient(client.cid)
    } finally {
      setLoading(false)
      setShowConfigDialog(false)
    }
  }

  const showDialog = () => {
    setShowConfigDialog(true)
  }

  const handleClose = () => {
    setShowConfigDialog(false)
  }

  if (positions.length) {
    return null
  }
  return (
    <React.Fragment>
      <Button
        style={{ justifyContent: 'flex-start' }}
        color="primary"
        aria-label="delete client"
        onClick={showDialog}
      >
        <DeleteIcon />
        &nbsp;Delete Client
      </Button>
      <Dialog open={showConfigDialog}>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle id="simple-dialog-title" onClose={handleClose}>
          Delete Client # {client.cid}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteClient} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
})

export default DeleteClientButton
