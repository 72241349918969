import { useState } from 'react'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Button, Collapse, List } from '@material-ui/core'

const NestedMenu = ({ children }) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen((prev) => !prev)
  }

  return (
    <>
      <Button
        style={{ justifyContent: 'flex-start' }}
        color="primary"
        aria-label="bnb"
        onClick={handleClick}
      >
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        &nbsp;BNB
      </Button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          style={{ margin: '0 8px', display: 'flex', flexDirection: 'column' }}
          component="div"
          disablePadding
        >
          {children}
        </List>
      </Collapse>
    </>
  )
}

export default NestedMenu
