import { numberWithCommas, parseDate } from '../../utilities/number-helpers'

export const FUNDS_COLUMNS = [
  {
    Header: 'Symbol',
    accessor: 'symbol',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ value }) => numberWithCommas(value),
  },
  {
    Header: 'Date',
    accessor: 'date',
    Cell: ({ value }) => parseDate(value),
  },
  {
    Header: 'Exchange',
    accessor: 'exchange',
  },
]
