import instance from './axiosConfig'

const subscribers = {}

class LoggingService {
  async subscribeOnRequestLogs(fundingId, callback) {
    const data = await this.getRequestLogs(fundingId)
    const timestamp = this.getFirstDate(data)
    callback(data)
    subscribers[fundingId] = {
      interval: setInterval(async () => {
        await this.getRequestWithCallback(fundingId)
      }, 1500),
      since: timestamp,
      fundingId: fundingId,
      callback: callback,
    }
  }

  async getRequestWithCallback(fundingId) {
    const sub = subscribers[fundingId]
    const data = await this.getRequestLogs(fundingId, sub.since)
    sub.callback(data)
    sub.since = this.getFirstDate(data, sub.since)
  }

  getFirstDate(logs, currentValue) {
    if (!logs || logs.length === 0) {
      return currentValue
    }
    return logs[0].id
  }

  async getRequestLogs(requestId, since) {
    let response
    if (since) {
      response = await instance.get(`/api/logging/request/${requestId}?since=${since}`)
    } else {
      response = await instance.get(`/api/logging/request/${requestId}`)
    }
    return response.data
  }

  async unsubscribeAll() {
    for (let k in subscribers) {
      const sub = subscribers[k]
      clearInterval(sub.interval)
      await this.getRequestWithCallback(sub.fundingId)
      delete subscribers[k]
    }
  }
}

const loggingService = new LoggingService()
export default loggingService
