import React, { useEffect, useRef } from 'react'
import s from '../RequestLogs.module.css'
import { parseDate } from '../../../../utilities/number-helpers'

const RequestLog = ({ data, index, style }) => {
  const log = data[index]
  const rowRef = useRef()

  return (
    <div className={s.row} style={style} ref={rowRef}>
      <div>{parseDate(log.createdOn)}</div>
      <div>{log.level}</div>
      <div>{log.message}</div>
    </div>
  )
}

export default RequestLog
