import instance from './axiosConfig'

class InfoService {
  async getInfo() {
    const response = await instance.get(`/api/info`)
    return response?.data
  }
  async getSystemHealth() {
    const response = await instance.get(`/api/system/health`)
    return response?.data
  }
}

const infoService = new InfoService()
export default infoService
