import { Box } from '@material-ui/core'
import { numberWithCommas } from '../../utilities/number-helpers'
import SelectQueryFilter from '../Table/Filters/SelectQueryFilter'

const healthColor = (health) => {
  if (health) {
    const value = Number(health)
    if (value > 60.0) {
      return 'success.main'
    } else if (value < 20.0) {
      return 'error.main'
    } else {
      return 'text.primary'
    }
  }
}

const renderFilter = (info) => <SelectQueryFilter key="FilterInput" id={'cid'} {...info} />

export const COLUMNS = [
  {
    Header: 'Client',
    accessor: 'cid',
    Filter: renderFilter,
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
    Filter: renderFilter,
  },
  {
    Header: 'Mark price (USDT)',
    accessor: 'markPrice',
    Cell: ({ value }) => numberWithCommas(value),
    disableFilters: true,
    sortType: 'alphanumericFalsyLast',
  },
  {
    Header: 'Position value (USDT)',
    accessor: 'positionValue',
    Cell: ({ value }) => numberWithCommas(value),
    disableFilters: true,
    sortType: 'alphanumericFalsyLast',
  },
  {
    Header: 'Spot value (USDT)',
    accessor: 'spotValue',
    Cell: ({ value }) => numberWithCommas(value),
    disableFilters: true,
    sortType: 'alphanumericFalsyLast',
  },
  {
    Header: 'Spot amount locked',
    accessor: 'locked',
    Cell: ({ value }) => numberWithCommas(value),
    disableFilters: true,
    sortType: 'alphanumericFalsyLast',
  },
  {
    Header: 'Spot amount available',
    accessor: 'available',
    Cell: ({ value }) => numberWithCommas(value),
    disableFilters: true,
    sortType: 'alphanumericFalsyLast',
  },
  {
    Header: 'Futures position amount',
    accessor: 'futures',
    Cell: ({ value }) => numberWithCommas(value),
    disableFilters: true,
    sortType: 'alphanumericFalsyLast',
  },
  {
    Header: 'Difference',
    accessor: 'difference',
    Cell: ({ value }) => numberWithCommas(value),
    disableFilters: true,
    sortType: 'alphanumericFalsyLast',
  },
  {
    Header: 'Difference USDT',
    accessor: 'differenceUSDT',
    Cell: ({ value }) => numberWithCommas(value),
    disableFilters: true,
    sortType: 'alphanumericFalsyLast',
  },
  {
    Header: 'Health',
    accessor: 'health',
    Cell: ({ value }) => (
      <Box color={healthColor(value)}>{value && `${value?.toLocaleString('en-US')}%`}</Box>
    ),
    disableFilters: true,
    sortType: 'alphanumericFalsyLast',
  },
  {
    Header: 'Unrealized profit',
    accessor: 'unrealizedProfit',
    Cell: ({ value }) => numberWithCommas(value),
    disableFilters: true,
    sortType: 'alphanumericFalsyLast',
  },
  {
    Header: 'Exchange pair',
    accessor: 'exchangePair',
    Filter: renderFilter,
    sortType: 'alphanumericFalsyLast',
  },
]
