import Button from '@material-ui/core/Button'
import React, { useState } from 'react'
import Converter from './Converter'

const DustToBNBButton = ({ client, disabled }) => {
  const [open, setOpen] = useState(false)

  return (
    <React.Fragment>
      <Converter guid={client.guid} open={open} onClose={() => setOpen(false)} />
      <Button
        color="primary"
        onClick={() => setOpen(true)}
        disabled={disabled}
        style={{ justifyContent: 'flex-start' }}
      >
        Dust To BNB
      </Button>
    </React.Fragment>
  )
}

export default DustToBNBButton
