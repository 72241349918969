import { Chip, Modal, Paper, TextField, Typography } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import React, { useState } from 'react'
import binanceSpotService from '../../../../service/BinanceSpotService'
import PopUp from '../../../common/PopUp'

const Converter = ({ guid, open, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [assets, setAssets] = useState([])
  const [value, setValue] = useState('')
  const [popup, setPopup] = useState({
    message: '',
    severity: 'success',
    open: false,
  })

  const dustToBNB = async () => {
    setLoading(true)
    try {
      const response = await binanceSpotService.dustToBNB(guid, assets)
      if (response.status !== 200) {
        throw new Error(response.data.message)
      } else {
        setPopup({ message: `Successfull convertation`, severity: 'success', open: true })
      }
    } catch (e) {
      setPopup({ message: `Convertation failed: ${e.message}`, severity: 'error', open: true })
    } finally {
      setLoading(false)
    }
  }

  const onAdd = () => {
    if (assets.includes(value?.toUpperCase()) || !value) return

    setAssets((prev) => [...prev, value.toUpperCase()])
  }

  const onDelete = (value) => {
    setAssets((prev) => prev.filter((asset) => asset !== value))
  }

  const handleClose = () => {
    setAssets([])
    setValue('')
    onClose()
  }

  const closePopup = () => setPopup((prev) => ({ ...prev, open: false }))

  return (
    <>
      <PopUp {...popup} closePopup={closePopup} />
      <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
        <CircularProgress color="inherit" size={90} />
      </Backdrop>
      <Modal open={open} onClose={handleClose} className={'centered'}>
        <Paper style={{ padding: 24, maxWidth: 450 }}>
          <Typography variant="h5" gutterBottom>
            Convert coins to BNB
          </Typography>
          <div className="centered-line" style={{ marginBottom: 12, gap: 10 }}>
            {assets?.map((asset) => (
              <Chip key={asset} label={asset} onDelete={() => onDelete(asset)} size="small" />
            ))}
          </div>
          <div className="centered-line" style={{ marginBottom: 20, gap: 12 }}>
            <TextField
              value={value}
              onChange={(e) => setValue(e.target.value)}
              variant="outlined"
              size="small"
            />
            <Button variant="outlined" onClick={onAdd}>
              Add
            </Button>
          </div>
          <Button
            disabled={assets.length < 1}
            color="primary"
            variant="contained"
            onClick={dustToBNB}
          >
            Convert
          </Button>
        </Paper>
      </Modal>
    </>
  )
}

export default Converter
