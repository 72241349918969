import s from './ClientCard.module.css'
import { Accordion, AccordionDetails, AccordionSummary, Badge, Divider } from '@material-ui/core'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { BUY, SELL } from '../../../model/OrderSide'
import { LONG, SHORT } from '../../../model/RequestType'
import { clientsActions } from '../../../redux/slices/clients-slice'
import OpenPosition from './OpenPosition/OpenPosition'
import RequestDialog from './RequestDialog/RequestDialog'
import BalanceView from './SpotBalance/BalanceView'
import SecurityIcon from '@material-ui/icons/Security'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import MoreActionsButton from './MoreActionsButton/MoreActionsButton'

const RiskExposure = ({ riskExposure = 0, maxRiskExposure = 0 }) => (
  <Typography style={{ marginTop: '10px', marginBottom: '10px' }}>
    <span className={s.financialTitle}>Risk Exposure:</span>{' '}
    {(+riskExposure).toLocaleString('en-US')}
    {' / '}
    {(+maxRiskExposure).toLocaleString('en-US')}
  </Typography>
)

const ClientCard = ({
  client,
  changeClientState,
  changeClientPositionState,
  allExpanded,
}) => {
  const [openRequest, setOpenRequest] = useState({
    show: false,
    type: SHORT,
    side: SELL,
    position: {},
  })
  const [expanded, setExpanded] = useState(false)
  const [investedClass, setInvestedClass] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    const receivedFunding =
      client.invested +
      client.totalFunding.totalEarnedFunding.amount -
      client.projectFeeTakenFromAccount

    if (
      receivedFunding > client.estimatedTotalBalance * 1.01 ||
      receivedFunding < client.estimatedTotalBalance * 0.99
    ) {
      setInvestedClass(s.redFlash)
      return
    }
    setInvestedClass('')
  }, [
    client.invested,
    client.estimatedTotalBalance,
    client.totalFunding.totalEarnedFunding.amount,
    client.projectFeeTakenFromAccount,
  ])

  useEffect(() => {
    setExpanded(allExpanded)
  }, [allExpanded])

  const openNewPositionRequest = useCallback((e) => {
    setExpanded(true)

    const type = e.currentTarget.value // SHORT | LONG
    const side = type === SHORT ? SELL : BUY

    dispatch(clientsActions.setClientInfo(client))
    setOpenRequest({ show: true, type: type, side: side })
  }, [client, dispatch])

  const updatePositionRequest = useCallback((type, side, position) => {
    dispatch(clientsActions.setClientInfo(client))
    setOpenRequest({ show: true, type: type, side: side, position })
  }, [client, dispatch])

  const activeRequestsCount = useMemo(
    () =>
      client.leftAccount.openPositions.reduce((prev, value) => {
        if (!value.requestExecuting) {
          return prev
        }
        return ++prev
      }, 0),
    [client.leftAccount.openPositions],
  )

  const projectFeeTaken = useMemo(
    () => client.projectFeeTakenFromAccount + client.projectFeeTakenFromOtherSource,
    [client.projectFeeTakenFromAccount, client.projectFeeTakenFromOtherSource],
  )

  const otherGainLoss = useMemo(
    () =>
      client.estimatedTotalBalance -
      client.invested -
      client.totalFunding.totalEarnedFunding?.amount +
      client.projectFeeTakenFromAccount,
    [
      client.estimatedTotalBalance,
      client.invested,
      client.totalFunding.totalEarnedFunding?.amount,
      client.projectFeeTakenFromAccount,
    ],
  )

  const [longPositions, shortPositions] = useMemo(() => {
    const long = {}
    const short = {}

    if (client.leftAccount.openPositions) {
      for (const pos of client.leftAccount.openPositions) {
        const position = {
          ...pos,
          exchangeSide: 'LEFT',
        }

        if (position.positionType === LONG) {
          long[position.symbol] = position
        }

        if (position.positionType === SHORT) {
          short[position.symbol] = position
        }
      }
    }

    if (client.rightAccount.openPositions) {
      for (const pos of client.rightAccount.openPositions) {
        const position = {
          ...pos,
          exchangeSide: 'RIGHT',
        }

        if (short[position.symbol] || long[position.symbol]) {
          continue
        }

        if (position.positionType === LONG) {
          long[position.symbol] = position
        }

        if (position.positionType === SHORT) {
          short[position.symbol] = position
        }
      }
    }

    return [Object.values(long), Object.values(short)]
  }, [client.leftAccount.openPositions, client.rightAccount.openPositions])

  return (
    <Accordion expanded={expanded} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        aria-label="Expand"
        onClick={() => setExpanded((prev) => !prev)}
      >
        <div className="centered-line justify-between w-100">
          <Typography variant="h5" component="h2" className={'centered-line'}>
            Client #{client.cid} ({client.accountType})
          </Typography>
          <div className={s.clientHeader}>
            <div className='flex-base'>
              <Tooltip
                title={
                  client.suspended ? `Activate Client ${client.cid}` : `Disable Client ${client.cid}`
                }
            >
              <IconButton
                disabled={client.archived}
                aria-label="add an alarm"
                onClick={(e) => {
                  changeClientState(client)
                  e.stopPropagation()
                }}
                style={{ marginLeft: 10 }}
              >
                <PowerSettingsNewIcon
                  style={{ color: client.suspended ? red[700] : green[500] }}
                  size="large"
                />
              </IconButton>
            </Tooltip>
            {client.defence0Enabled != null && (
              <Tooltip title={client.defence0Enabled ? `Defence 0 enabled` : `Defence 0 disabled`}>
                <div>
                  <IconButton disabled style={{ marginRight: 10 }}>
                    <SecurityIcon
                      style={{ color: !client.defence0Enabled ? red[700] : green[500] }}
                      size="large"
                    />
                  </IconButton>
                </div>
              </Tooltip>
            )}
            <Badge badgeContent={activeRequestsCount} color="secondary" style={{ marginRight: 16 }}>
              {activeRequestsCount > 0 && <AutorenewIcon />}
            </Badge>
            </div>
            <div
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
            >
              {!client.archived && (
                  <>
                    <Tooltip title="Open Short">
                      <IconButton
                        color="primary"
                        aria-label="create request"
                        value={SHORT}
                        onClick={openNewPositionRequest}
                      >
                        <ArrowDownwardIcon style={{ color: red[700] }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Open Long">
                      <IconButton
                        color="primary"
                        aria-label="start refresh"
                        value={LONG}
                        onClick={openNewPositionRequest}
                      >
                        <ArrowUpwardIcon style={{ color: green[500] }} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              <MoreActionsButton client={client} />
              <IconButton
                color="primary"
                aria-label="start refresh"
                value={LONG}
                onClick={() => setExpanded((prev) => !prev)}
                style={{ transform: expanded ? 'rotate(180deg)' : 'none' }}
              >
                <ExpandMoreIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </AccordionSummary>
      <Divider />
      <AccordionDetails className="column">
        <RequestDialog
          open={openRequest.show}
          type={openRequest.type}
          side={openRequest.side}
          position={openRequest.position}
          handleClose={(prev) => setOpenRequest({ ...prev, show: false })}
        />
        <div className={s.stats}>
          <div>
            <BalanceView
              leftAccount={client.leftAccount}
              rightAccount={client.rightAccount}
              estimatedTotalBalance={client.estimatedTotalBalance}
            />
            {client.b2c2Details != null && (
              <>
                <Typography style={{ marginTop: '10px', marginBottom: '10px' }}>
                  <span className={s.financialTitle}>B2C2 Margin Usage:</span>{' '}
                  {client.b2c2Details?.marginUsed?.toLocaleString('en-US')}%
                </Typography>
                <RiskExposure {...client.b2c2Details} />
              </>
            )}
          </div>
          <div className="column">
            <Typography className={`${investedClass} centered-line`} variant="body1" gutterBottom>
              <span className={s.financialTitle}>Estimated Balance:</span>{' '}
              {client.estimatedTotalBalance?.toLocaleString('en-US')} USDT
            </Typography>
            {client.invested != null && (
              <Typography className="centered-line" variant="body1" gutterBottom>
                <span className={s.financialTitle}>Invested:</span>{' '}
                {client.invested.toLocaleString('en-US')} USDT
              </Typography>
            )}
            <Typography className="centered-line" variant="body1" gutterBottom>
              <span className={s.financialTitle}>Funding reward:</span>{' '}
              {client.totalFunding.totalEarnedFunding.amount?.toLocaleString('en-US')}{' '}
              {client.totalFunding.totalEarnedFunding.asset?.toLocaleString('en-US')}
              {client.totalFunding.lastEarnedFunding
                ? ` (LF ${client.totalFunding.lastEarnedFunding.amount?.toLocaleString(
                    'en-US',
                  )}, APR ${client.totalFunding.lastEarnedFunding.apr?.toLocaleString('en-US')}%)`
                : null}
            </Typography>
            <Typography className="centered-line" variant="body1" gutterBottom>
              <span className={s.financialTitle}>Project Fee:</span>{' '}
              {projectFeeTaken?.toLocaleString('en-US')} /{' '}
              {(
                (client.projectFeePercent / 100) *
                client.totalFunding.totalEarnedFunding.amount
              )?.toLocaleString('en-US')}{' '}
              USDT
            </Typography>
            <Typography className="centered-line" variant="body1" gutterBottom>
              <p className={s.financialTitle}>Other gain/loss:</p>{' '}
              {otherGainLoss.toLocaleString('en-US')} USDT
            </Typography>
          </div>
        </div>
        <div className="column">
          {longPositions.length ? (
            <Typography className="centered-line" variant="h6" gutterBottom>
              <ArrowUpwardIcon style={{ color: green[500] }} /> Long positions:
            </Typography>
          ) : null}
          {longPositions.map((position) => (
            <OpenPosition
              key={position.symbol}
              updatePositionRequest={updatePositionRequest}
              position={position}
              client={client}
              cid={client.cid}
              changeClientPositionState={changeClientPositionState}
            />
          ))}
          {shortPositions.length ? (
            <Typography className="centered-line" variant="h6" gutterBottom>
              <ArrowDownwardIcon style={{ color: red[700] }} /> Short positions:
            </Typography>
          ) : null}
          {shortPositions.map((position) => (
            <OpenPosition
              key={position.symbol}
              updatePositionRequest={updatePositionRequest}
              position={position}
              client={client}
              cid={client.cid}
              changeClientPositionState={changeClientPositionState}
            />
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default React.memo(ClientCard)
