import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  IconButton,
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import s from './ClientFinances.module.css'
import { useEffect, useMemo, useState } from 'react'
import { COLUMNS } from './columns'
import SortableTable from '../Table/SortableTable'
import clientService from '../../service/ClientService'
import LoadingScreen from '../common/LoadingScreen'
import clientPositionService from '../../service/ClientPositionService'
import PopUp from '../common/PopUp'
import { DatePicker } from '@material-ui/pickers'
import ClearIcon from '@material-ui/icons/Clear'

const ALL_CLIENTS = 'allClients'

const ClientFinances = () => {
  const columns = useMemo(() => COLUMNS, [])
  const [data, setData] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [selectedClient, setSelectedClient] = useState(ALL_CLIENTS)
  const [clients, setClients] = useState([])
  const [period, setPeriod] = useState({ startDate: null, endDate: null })
  const [paging, setPaging] = useState({ totalPages: 1, page: 1 })
  const [popup, setPopup] = useState({
    message: '',
    severity: 'success',
    open: false,
  })

  useEffect(() => {
    ;(async () => {
      const positions = await clientPositionService.getAllClientPositions()
      if (positions) {
        let clientOptions = positions.map((c) => ({
          label: c?.cid,
          guid: c?.guid,
          disabled: c.archived || c.suspended,
        }))
        clientOptions = [{ label: 'All clients', guid: ALL_CLIENTS }, ...clientOptions]
        setClients(clientOptions)
      }
    })()
  }, [])

  useEffect(() => {
    handleShowTable()
  }, [paging.page])

  const getClientFinances = async (Accept, pageSize) => {
    const clientId = selectedClient === ALL_CLIENTS ? null : selectedClient
    const finances = await clientService.getClientFinances(
      Accept,
      clientId,
      period.startDate,
      period.endDate,
      paging.page - 1,
      pageSize,
    )
    return finances
  }

  const handleSelect = (e) => {
    setSelectedClient(e.target.value)
  }

  const handleShowTable = async () => {
    setIsFetching(true)

    const response = await getClientFinances('application/json')
    if (response?.content) {
      setData(response.content)
      setPaging((prev) => ({ ...prev, totalPages: response.totalPages }))
    }

    setIsFetching(false)
  }

  const handleDownload = async () => {
    setDownloading(true)

    const response = await getClientFinances('text/csv', 1000)

    const blob = new Blob([response], { type: `text/csv;charset=utf-8;` })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', `Finances_${selectedClient}.csv`)
    document.body.appendChild(link)
    link.click()

    setDownloading(false)
  }

  const handleDateChange = (date, id) => {
    setPeriod((prev) => ({
      ...prev,
      [id]: date.format('YYYY-MM-DD'),
    }))
  }

  const handleClearDate = (e, id) => {
    e.stopPropagation()
    setPeriod((prev) => ({
      ...prev,
      [id]: null,
    }))
  }

  const handlePageChange = (_, newPage) => {
    setPaging((prev) => ({ ...prev, page: newPage }))
  }

  const closePopup = () => setPopup((prev) => ({ ...prev, open: false }))

  return (
    <Paper className={s.root}>
      <PopUp {...popup} closePopup={closePopup} />
      <Typography variant="h5" gutterBottom>
        Client Finances
      </Typography>
      <div className="flex-line align-center" style={{ gap: 8 }}>
        <FormControl>
          <InputLabel id="client-select" shrink>
            Select client
          </InputLabel>
          <Select
            labelId="client-select"
            label="Select client"
            value={selectedClient}
            onChange={handleSelect}
            style={{ width: 150 }}
          >
            {clients?.map((client) => (
              <MenuItem
                key={client.guid}
                value={client.guid}
                style={{ color: client.disabled ? '#db2020' : '' }}
              >
                {client.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <DatePicker
          id="startDate"
          label="From"
          format="DD.MM.yyyy"
          value={period.startDate}
          onChange={(newValue) => handleDateChange(newValue, 'startDate')}
          style={{ width: 150 }}
          InputProps={{
            endAdornment: (
              <IconButton size="small" onClick={(e) => handleClearDate(e, 'startDate')}>
                <ClearIcon />
              </IconButton>
            ),
          }}
        />
        <DatePicker
          id="endDate"
          label="To"
          format="DD.MM.yyyy"
          value={period.endDate}
          onChange={(newValue) => handleDateChange(newValue, 'endDate')}
          style={{ width: 150 }}
          InputProps={{
            endAdornment: (
              <IconButton size="small" onClick={(e) => handleClearDate(e, 'endDate')}>
                <ClearIcon />
              </IconButton>
            ),
          }}
        />
      </div>
      <div className="flex-line align-center" style={{ gap: 8 }}>
        <Button variant="contained" onClick={handleShowTable} color="primary">
          Update
        </Button>
        <Button variant="outlined" onClick={handleDownload} disabled={downloading}>
          Download
        </Button>
      </div>
      {isFetching ? (
        <LoadingScreen />
      ) : (
        <Paper elevation={2}>
          <SortableTable
            data={data}
            columns={columns}
            defaultSortBy={[{ id: 'date', desc: true }]}
          />
          <div className={s.pagination}>
            <Pagination
              count={paging.totalPages}
              page={paging.page}
              onChange={handlePageChange}
              size="large"
            />
          </div>
        </Paper>
      )}
    </Paper>
  )
}

export default ClientFinances
