import React, { useState } from 'react'
import s from './Notifications.module.css'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import BackButton from '../common/BackButton'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ConfirmWindow from './ConfirmWindow/ConfirmWindow'
import notificationsService from '../../service/NotificationsService'
import PopUp from '../common/PopUp'

const ACCOUNTS = [
  { label: 'All clients', value: 'ALL' },
  { label: 'Only active clients', value: 'ACTIVE' },
]

const Notifications = () => {
  const [values, setValues] = useState({
    message: '',
    audience: ACCOUNTS[0],
  })
  const [open, setOpen] = useState(false)
  const [popup, setPopup] = useState({
    message: '',
    severity: 'success',
    open: false,
  })

  const history = useHistory()

  const handleInputChange = (event) => {
    const value = event.target.value
    const name = event.target.id
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSelectChange = (event, fieldName) => {
    const value = event.target.value
    setValues((prev) => ({
      ...prev,
      [fieldName]: value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setOpen(true)
  }

  const handleConfirm = async () => {
    const response = await notificationsService.sendNotification(
      values.message,
      values.audience.value,
    )

    if (response.status === 200) {
      history.push(`/`)
      return
    }
    setPopup({
      message: 'Could not send notification: ' + response.data.message,
      severity: 'error',
      open: true,
    })
  }

  const closePopup = () => setPopup((prev) => ({ ...prev, open: false }))

  return (
    <Paper elevation={8} className={s.paper}>
      <PopUp {...popup} closePopup={closePopup} />
      <div className="flex-line justify-between align-center w-100" style={{ marginBottom: 20 }}>
        <Typography variant="h4">Notifications for clients</Typography>
        <BackButton />
      </div>
      <form className="column align-center" onSubmit={handleSubmit}>
        <TextField
          className={s.input}
          multiline
          id="message"
          label="Message"
          margin="normal"
          required
          value={values.message}
          onChange={handleInputChange}
        />
        <FormControl className={s.input}>
          <InputLabel id="audience">Audience</InputLabel>
          <Select
            labelId="audience"
            id="audience"
            value={values.audience}
            onChange={(e) => handleSelectChange(e, 'audience')}
          >
            {ACCOUNTS.map((audienceType) => (
              <MenuItem key={audienceType.value} value={audienceType}>
                {audienceType.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" type="submit" style={{ marginTop: 24 }}>
          Send
        </Button>
      </form>
      <ConfirmWindow
        open={open}
        handleSubmit={handleConfirm}
        handleClose={() => setOpen(false)}
        audience={values.audience.label}
      />
    </Paper>
  )
}

export default Notifications
