import { parseDate } from '../../../utilities/number-helpers'

export const COLUMNS = [
  {
    Header: 'Channel',
    accessor: 'channel',
  },
  {
    Header: 'Last event',
    accessor: 'lastEventTs',
    Cell: ({ value }) => parseDate(value),
  },
]
