import { useMemo } from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Tooltip from '@material-ui/core/Tooltip'

import { LONG, SHORT } from '../../../model/RequestType'

const RequestInputBox = ({
  spotBalance,
  futuresBalance,
  values,
  errors,
  touched,
  isOpenRequest,
  cid,
  disableModify = false,
  onClickHandler,
  onChangeHandler,
  calculate,
  isCloseRequest,
  futuresMarketPrice,
  onLotsChange,
  type,
  client,
  className,
  viewForm,
  isEmptyForm,
  currencies,
}) => {
  const hasOpenPosition = useMemo(
    () => values.currentPosition && Number(values.currentPosition) !== 0,
    [values.currentPosition],
  )

  function showError(field) {
    if (!errors || !touched) {
      return ''
    }

    return errors[field] && touched[field] ? errors[field] : ''
  }

  const fillLotsButtons = [500, 1000, 2000, 5000]

  // const spotAsset = values.symbol.length === 3 ? values.symbol : '---'
  // const amountAsset = spotAsset
  // const spotAsset = (typeof values.asset !== 'undefined') ? values.asset.right : values.symbol
  const spotAsset = useMemo(
      () => {
          if (typeof values.asset !== 'undefined') {
            return type === LONG ? values.asset.base : values.asset.left
          }
          return type === LONG ? (values.symbol.length > 2 ? values.symbol : '---') : values.leftCurrency
      },
      [values.asset, values?.symbol, values?.leftCurrency, type],
  )

  const amountAsset = isOpenRequest ? spotAsset : values.asset

  const leverage = useMemo(
    () => (values.leverage ? values.leverage : values.leverage),
    [values.leverage, values.leverage],
  )
  const usdtValue = useMemo(
    () =>
      type === LONG && isOpenRequest
        ? '~' + (parseFloat((values?.amount * futuresMarketPrice).toFixed(2)) || 0) + ' $'
        : '',
    [values.amount, type, isOpenRequest, futuresMarketPrice],
  )

  const futuresTooltip = useMemo(
    () =>
      (type === LONG && !isOpenRequest) || (type === SHORT && isOpenRequest)
        ? 'The higher the better'
        : 'The lower the better',
    [type, isOpenRequest],
  )

  const showFillButtons = (!isOpenRequest || (type === LONG && isOpenRequest)) && !disableModify

  return (
    <Paper elevation={2} className={className}>
      <div className="justify-between flex-base">
        <div className={'column align-start'} style={{ width: '49%' }}>
          <TextField
            id="symbol"
            label="Symbol"
            margin="normal"
            disabled={viewForm || disableModify}
            required
            error={!!showError('symbol')}
            helperText={showError('symbol')}
            value={values.symbol}
            onChange={(e) => {
              if (e.target.value) {
                e.target.value = e.target.value.toUpperCase()
              }

              onChangeHandler(e)
            }}
          />
          <TextField
            select={isEmptyForm}
            id="leftCurrency"
            label={client?.leftAccount?.exchange}
            margin="normal"
            value={values.leftCurrency}
            error={!!showError('leftCurrency')}
            helperText={showError('leftCurrency')}
            disabled={viewForm || !isEmptyForm}
            InputLabelProps={{ shrink: true }}
            SelectProps={{
              native: true,
            }}
            onChange={(e) => {
              onChangeHandler({ target: { id: 'leftCurrency', value: e.target.value } })
            }}
            type="select"
            fullWidth
          >
            {currencies?.leftCurrencies?.map((currency) => <option key={currency} value={currency}>{currency}</option>)}
          </TextField>
          <TextField
            select={isEmptyForm}
            id="rightCurrency"
            label={client?.rightAccount?.exchange}
            margin="normal"
            disabled={viewForm || !isEmptyForm}
            InputLabelProps={{ shrink: true }}
            type="select"
            SelectProps={{
              native: true,
            }}
            value={values.rightCurrency}
            error={!!showError('rightCurrency')}
            helperText={showError('rightCurrency')}
            onChange={(e) => {
              onChangeHandler({ target: { id: 'rightCurrency', value: e.target.value } })
            }}
            fullWidth
          >
            {currencies?.rightCurrencies?.map((currency) => <option key={currency} value={currency}>{currency}</option>)}
          </TextField>
          <div className="centered align-baseline">
            <TextField
              id="amount"
              margin="normal"
              label="Amount"
              disabled={viewForm || disableModify}
              required
              error={!!showError('amount')}
              helperText={showError('amount')}
              type="number"
              value={values.amount}
              onChange={onChangeHandler}
              style={{ maxWidth: 225 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{amountAsset}</InputAdornment>
                ),
                endAdornment: <InputAdornment position="end">{usdtValue}</InputAdornment>,
              }}
            />
            {!viewForm && !isOpenRequest && !disableModify && (
              <Button
                size="small"
                disabled={values.currentPosition === ''}
                onClick={onClickHandler('amount', Math.abs(values.currentPosition))}
              >
                Max
              </Button>
            )}
          </div>
          <div style={{ display: 'flex' }}>
            <TextField
              id="lotAmount"
              margin="normal"
              label="Lots"
              required
              error={!!showError('lotAmount')}
              helperText={showError('lotAmount')}
              disabled={viewForm || disableModify}
              type="number"
              value={values.lotAmount}
              onChange={onChangeHandler}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{amountAsset}</InputAdornment>
                ),
              }}
            />
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              {!viewForm && showFillButtons &&
                fillLotsButtons.map((value) => (
                  <Button
                    key={value}
                    disabled={!futuresMarketPrice}
                    size="small"
                    onClick={() => onLotsChange((value / futuresMarketPrice).toFixed(2))}
                    style={{ height: '22px !important' }}
                  >
                    {value / 1000}K$
                  </Button>
                ))}
            </div>
          </div>
          <Tooltip title={futuresTooltip} placement="right-end" arrow>
            <TextField
              id="futuresPremium"
              margin="normal"
              label="Futures Premium"
              disabled={viewForm || disableModify}
              required
              error={!!showError('futuresPremium')}
              helperText={showError('futuresPremium')}
              type="number"
              value={values.futuresPremium}
              onChange={onChangeHandler}
            />
          </Tooltip>
          <FormControl>
            <InputLabel shrink={!!leverage}>Leverage</InputLabel>
            <TextField
              id="leverage"
              margin="normal"
              type="number"
              required={!hasOpenPosition}
              disabled={viewForm || hasOpenPosition || disableModify}
              value={leverage}
              onChange={onChangeHandler}
              inputProps={{ step: 'any' }}
            />
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                id="isolated"
                required={!hasOpenPosition}
                disabled={viewForm || hasOpenPosition || disableModify}
                checked={!!values.isolated}
              />
            }
            label="Isolated"
            labelPlacement="start"
            onChange={onChangeHandler}
          />
        </div>
        <div className={'column'} style={{ width: '49%' }}>
          <TextField id="cid" label="CID" margin="normal" disabled value={cid} />
          <TextField
            id="currentPosition"
            label="Current Position"
            margin="normal"
            type="number"
            disabled
            value={values.currentPosition}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {values?.baseAsset ? values.baseAsset : ''}
                </InputAdornment>
              ),
            }}
          />
          {isOpenRequest && (
            <>
              <TextField
                id="available"
                label={`Right Available`}
                disabled
                value={
                  spotBalance && spotAsset && spotBalance[spotAsset]
                    ? spotBalance[spotAsset].free
                    : ''
                }
                InputProps={{
                  startAdornment: <InputAdornment position="start">{spotAsset}</InputAdornment>,
                }}
              />
              <TextField
                id="futuresP"
                label="Left Available"
                disabled
                margin={'normal'}
                value={futuresBalance && futuresBalance['USDT'] ? futuresBalance['USDT'].free : ''}
                InputProps={{
                  startAdornment: <InputAdornment position="start">USDT</InputAdornment>,
                }}
              />
            </>
          )}
        </div>
      </div>
      {!viewForm && (
        <Button
          disabled={disableModify}
          onClick={() => calculate(values)}
          variant="outlined"
          color="primary"
        >
          Calculate
        </Button>
      )}
    </Paper>
  )
}

export default RequestInputBox
