import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

const LoadingScreen = () => {
  return (
    <React.Fragment>
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
    </React.Fragment>
  )
}

export default LoadingScreen
