import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { useSelector } from 'react-redux'
import { IconButton } from '@material-ui/core'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import RequestCreate from '../../../RequestCreate/RequestCreate'
import s from './RequestDialog.module.css'

const RequestDialog = ({ open, type, side, position, request, handleClose }) => {
  const client = useSelector((state) => state.clients.clientInfo)

  return (
    <Dialog className={s.dialog} open={open}>
      <div className={s.close}>
        <IconButton size="small" onClick={handleClose}>
          <CloseRoundedIcon />
        </IconButton>
      </div>
      <RequestCreate
        type={type}
        side={side}
        guid={client?.guid}
        request={request}
        position={position}
      />
    </Dialog>
  )
}

export default RequestDialog
