import { FormControl, MenuItem, TextField, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import GetAppIcon from '@material-ui/icons/GetApp'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { useMemo, useState } from 'react'
import historyService from '../../service/HistoryService'
import SortableTable from '../Table/SortableTable'
import { FUNDS_COLUMNS } from './funds-columns'
import s from './HistoryView.module.css'
import { TRADE_COLUMNS } from './trade-columns'
import { useParams } from 'react-router-dom'
import BackButton from '../common/BackButton'
import PopUp from '../common/PopUp'
import { OPERATIONS_COLUMNS } from './operations-column'
import { TYPE_FUTURES, TYPE_SPOT } from '../../model/Exchange'
import { DateTimePicker } from '@material-ui/pickers'
import moment from 'moment'

const CSV = 'text/csv'
const JSON = 'application/json'

const HistoryView = () => {
  const tradeColumns = useMemo(() => TRADE_COLUMNS, [])
  const fundsColumns = useMemo(() => FUNDS_COLUMNS, [])
  const operationsColumns = useMemo(() => OPERATIONS_COLUMNS, [])

  const today = moment().set({ h: 0, m: 0 })

  const { cid } = useParams()
  const [downloading, setDownloading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [symbol, setSymbol] = useState('')
  const [tradePeriod, setTradePeriod] = useState({ from: today, to: today })
  const [fundPeriod, setFundPeriod] = useState({ from: today, to: today })
  const [tradeData, setTradeData] = useState([])
  const [fundData, setFundData] = useState([])
  const [operationsData, setOperationsData] = useState([])
  const [tradeExchange, setTradeExchange] = useState(TYPE_SPOT)
  const [popup, setPopup] = useState({
    message: '',
    severity: 'success',
    open: false,
  })

  const fetchTrades = async (format) => {
    const startDate = new Date(tradePeriod.from).getTime()
    const endDate = new Date(tradePeriod.to).getTime()
    try {
      const response = await historyService.getUserTrades(
        format,
        cid,
        symbol,
        tradeExchange,
        startDate,
        endDate,
      )
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (err) {
      setPopup({
        message: 'Could not fetch trade history ' + err,
        severity: 'error',
        open: true,
      })
    }
  }

  const fetchFunds = async (format) => {
    const startDate = new Date(fundPeriod.from).getTime()
    const endDate = new Date(fundPeriod.to).getTime()
    try {
      const response = await historyService.getUserIncomes(format, cid, startDate, endDate)
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (err) {
      setPopup({
        message: 'Could not fetch funding history ' + err,
        severity: 'error',
        open: true,
      })
    }
  }

  const fetchOperations = async (format) => {
    try {
      const response = await historyService.getUserOperations(format, cid)
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (err) {
      setPopup({
        message: 'Could not fetch funding history ' + err,
        severity: 'error',
        open: true,
      })
    }
  }

  const getTrades = async () => {
    setLoading(true)
    const data = await fetchTrades(JSON)
    if (data) setTradeData(data)
    setLoading(false)
  }

  const getFunds = async () => {
    setLoading(true)
    const data = await fetchFunds(JSON)
    if (data) setFundData(data)
    setLoading(false)
  }

  const getOperations = async () => {
    setLoading(true)
    const data = await fetchOperations(JSON)
    if (data) setOperationsData(data)
    setLoading(false)
  }

  const download = (data, fileName) => {
    if (!data) return

    const blob = new Blob([data], { type: `text/csv;charset=utf-8;` })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
  }

  const downloadTrades = async () => {
    setDownloading(true)
    const data = await fetchTrades(CSV)
    download(data, `Trades/${cid}_${tradePeriod.from}-${tradePeriod.to}.csv`)
    setDownloading(false)
  }

  const downloadIncomes = async () => {
    setDownloading(true)
    const data = await fetchFunds(CSV)
    download(data, `Funds/${cid}_${fundPeriod.from}-${fundPeriod.to}.csv`)
    setDownloading(false)
  }

  const downloadOperations = async () => {
    setDownloading(true)
    const data = await fetchOperations(CSV)
    download(data, `Deposits_Withdrawals/${cid}.csv`)
    setDownloading(false)
  }

  const tradeDisabled = !tradePeriod.to || !tradePeriod.from || !symbol || loading
  const fundDisabled = !fundPeriod.to || !fundPeriod.from || loading

  const closePopup = () => setPopup((prev) => ({ ...prev, open: false }))

  return (
    <Paper className={s.paper}>
      <PopUp {...popup} closePopup={closePopup} />
      <div className={s.heading}>
        <Typography variant="h5">Download History</Typography>
        <BackButton />
      </div>
      <div className="flex-line" style={{ padding: '16px 0', gap: 28 }}>
        <div className={s.field}>
          <Typography variant="h6">Trades:</Typography>
          <TextField
            id="symbol"
            label="Symbol"
            required
            value={symbol}
            onChange={(e) => setSymbol(e.target.value)}
          />
          <FormControl>
            <DateTimePicker
              id="tradeFrom"
              label="From"
              format="DD.MM.yyyy HH:mm"
              ampm={false}
              value={tradePeriod.from}
              onChange={(date) => setTradePeriod((prev) => ({ ...prev, from: date }))}
              required
            />
          </FormControl>
          <FormControl>
            <DateTimePicker
              id="tradeTo"
              label="To"
              format="DD.MM.yyyy HH:mm"
              ampm={false}
              value={tradePeriod.to}
              onChange={(date) => setTradePeriod((prev) => ({ ...prev, to: date }))}
              required
            />
          </FormControl>
          <TextField
            id="exchange-select"
            label="Exchange type"
            value={tradeExchange}
            onChange={(e) => setTradeExchange(e.target.value)}
            select
            style={{ width: 150 }}
          >
            <MenuItem value={TYPE_SPOT}>Spot</MenuItem>
            <MenuItem value={TYPE_FUTURES}>Futures</MenuItem>
          </TextField>
          <div className={s.download}>
            <IconButton
              disabled={tradeDisabled}
              size="small"
              color="default"
              aria-label="calculate"
              onClick={getTrades}
            >
              <MoreHorizIcon style={{ marginRight: 4 }} /> Calculate
            </IconButton>
            <IconButton
              disabled={downloading}
              color="default"
              size="small"
              aria-label="download"
              onClick={downloadTrades}
            >
              <GetAppIcon /> Download
            </IconButton>
          </div>
          {tradeData.length != 0 && (
            <SortableTable
              data={tradeData}
              columns={tradeColumns}
              defaultSortBy={[{ id: 'time', desc: true }]}
            />
          )}
        </div>
        <div className={s.field}>
          <Typography variant="h6">Fundings:</Typography>
          <FormControl>
            <DateTimePicker
              id="fundFrom"
              label="From"
              format="DD.MM.yyyy HH:mm"
              ampm={false}
              value={fundPeriod.from}
              onChange={(date) => setFundPeriod((prev) => ({ ...prev, from: date }))}
              required
            />
          </FormControl>
          <FormControl>
            <DateTimePicker
              id="fundTo"
              label="To"
              format="DD.MM.yyyy HH:mm"
              ampm={false}
              value={fundPeriod.to}
              onChange={(date) => setFundPeriod((prev) => ({ ...prev, to: date }))}
              required
            />
          </FormControl>
          <div className={s.download}>
            <IconButton
              disabled={fundDisabled}
              size="small"
              color="default"
              aria-label="calculate"
              onClick={getFunds}
            >
              <MoreHorizIcon style={{ marginRight: 4 }} /> Calculate
            </IconButton>
            <IconButton
              disabled={downloading}
              size="small"
              color="default"
              aria-label="download"
              onClick={downloadIncomes}
            >
              <GetAppIcon /> Download
            </IconButton>
          </div>
          {fundData.length != 0 && (
            <SortableTable
              data={fundData}
              columns={fundsColumns}
              defaultSortBy={[{ id: 'date', desc: true }]}
            />
          )}
        </div>
        <div className={s.field}>
          <Typography variant="h6">Deposits & Withrdrawals:</Typography>
          <div className={s.download}>
            <IconButton
              disabled={loading}
              size="small"
              color="default"
              aria-label="calculate"
              onClick={getOperations}
            >
              <MoreHorizIcon style={{ marginRight: 4 }} /> Calculate
            </IconButton>
            <IconButton
              disabled={downloading}
              size="small"
              color="default"
              aria-label="download"
              onClick={downloadOperations}
            >
              <GetAppIcon /> Download
            </IconButton>
          </div>
          {operationsData.length != 0 && (
            <SortableTable
              data={operationsData}
              columns={operationsColumns}
              defaultSortBy={[{ id: 'timestamp', desc: true }]}
            />
          )}
        </div>
      </div>
    </Paper>
  )
}

export default HistoryView
