import { Button, Paper, Typography } from '@material-ui/core'
import s from './UserManagement.module.css'
import { useEffect, useState } from 'react'
import PopUp from '../common/PopUp'
import userService from '../../service/UserService'
import LoadingScreen from '../common/LoadingScreen'
import ValidationDialog from './ValidationDialog/ValidationDialog'
import ResetDialog from './ResetDialog/ResetDialog'

const UserManagement = () => {
  const [user, setUser] = useState(null)
  const [qr, setQr] = useState(null)
  const [showValidate, setShowValidate] = useState(false)
  const [showReset, setShowReset] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [popup, setPopup] = useState({
    message: '',
    severity: 'success',
    open: false,
  })

  const fetchUser = async () => {
    setIsFetching(true)
    const data = await userService.getUser()

    if (data) {
      setUser(data)
    }
    setIsFetching(false)
  }

  useEffect(() => {
    fetchUser()
  }, [])

  useEffect(() => {
    if (qr) {
      setShowValidate(true)
    }
  }, [qr])

  const requestQR = async () => {
    const response = await userService.mfaGenerate()

    if (response.status === 200) {
      setQr(response.data)
      return
    }
    setPopup({
      open: true,
      severity: 'error',
      message: 'Error requesting QR code: ' + response.data.message,
    })
  }

  const validateCode = async (otpCode) => {
    const response = await userService.mfaValidate(otpCode)

    if (response.status === 200) {
      setPopup({ open: true, severity: 'success', message: 'Success' })
      await fetchUser()
      setShowValidate(false)
      setQr(null)
      return
    }
    setPopup({
      open: true,
      severity: 'error',
      message: 'Validation error: ' + response.data.message,
    })
  }

  const resetMfa = async (email, otpCode) => {
    const response = await userService.mfaReset(email, otpCode)

    if (response.status === 200) {
      setPopup({ open: true, severity: 'success', message: 'MFA was successfully reset' })
      await fetchUser()
      setShowReset(false)
      return
    }
    setPopup({ open: true, severity: 'error', message: 'Error: ' + response.data.message })
  }

  const closePopup = () => setPopup((prev) => ({ ...prev, open: false }))

  if (isFetching) {
    return <LoadingScreen />
  }

  return (
    <Paper className={s.root}>
      <PopUp {...popup} closePopup={closePopup} />
      <Typography variant="h5" gutterBottom>
        User #{user?.name}
      </Typography>
      <div className="flex-line align-center" style={{ gap: 12 }}>
        {user?.mfaEnabled ? (
          <>
            <b>MFA enabled</b>
            <Button variant="outlined" onClick={() => setShowReset(true)}>
              Reset
            </Button>
          </>
        ) : (
          <>
            <b>User has no valid MFA</b>
            <Button variant="outlined" onClick={requestQR}>
              Request
            </Button>
          </>
        )}
      </div>
      <ValidationDialog
        qr={qr}
        open={showValidate}
        handleSubmit={validateCode}
        handleClose={() => setShowValidate(false)}
      />
      <ResetDialog
        open={showReset}
        handleSubmit={resetMfa}
        handleClose={() => setShowReset(false)}
      />
    </Paper>
  )
}

export default UserManagement
