import s from './ConfirmWindow.module.css'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'

const ConfirmWindow = ({ open, handleSubmit, handleClose, audience }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <div className={s.dialog}>
        <DialogTitle className={s.dialogTitle}>
          <div className={s.dialogTitle}>Send notifications</div>
        </DialogTitle>
        <DialogContent className={s.DialogContent}>
          <div className={s.dialogContentText}>Please confirm sending message to {audience}</div>
        </DialogContent>
        <DialogActions style={{ width: '100%' }}>
          <div className={s.dialogActions}>
            <Button onClick={handleClose} variant="contained" color="secondary">
              No
            </Button>
            <Button onClick={handleSubmit} variant="contained" color="primary" autoFocus>
              Yes
            </Button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default ConfirmWindow
