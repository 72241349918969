import Typography from '@material-ui/core/Typography'
import debounce from 'lodash.debounce'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import clientPositionService from '../../service/ClientPositionService'
import marketService from '../../service/MarketService'
import requestService from '../../service/RequestService'
import configurationService from '../../service/ConfigurationService'
import CircularProgress from '@material-ui/core/CircularProgress'

import { checkIsOpenRequest } from '../../utilities/isOpenRequest'
import PopUp from '../common/PopUp'
import RequestInputBox from '../RequestView/RequestInputBox/RequestInputBox'
import RequestMarketBox from '../RequestView/RequestMarketBox/RequestMarketBox'
import s from '../RequestView/RequestView.module.css'
import { BINANCE_SPOT } from '../../model/Exchange'
import { SHORT } from '../../model/RequestType'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useIntl } from 'react-intl'
import { isEmpty } from 'lodash'

const DEFAULT_LEVERAGE = '5'
const DEFAULT_ISOLATED = true

const getDefaultCurrency = (account, exchange, currencies, currency) => {
  if (account.exchange === exchange) {
    if (currencies.includes(currency)) {
      return currency
    }
  }

  return ''
}

export const useExchangesApi = (client) => {
  const [currencies, setCurrencies] = useState({ leftCurrencies: [], rightCurrencies: [] })

  const call = async () => {
    if (client) {
      const leftCurrencies = await configurationService.getEligibleCurrencies(client.leftAccount.exchange)
      const rightCurrencies = await configurationService.getEligibleCurrencies(client.rightAccount.exchange)

      setCurrencies({ leftCurrencies, rightCurrencies })
    }
  }

  useEffect(() => {
    call()
  }, [])

  return currencies
}

const RequestCreate = ({ type, side, guid, request = {}, position = {}, viewForm }) => {
  const clientInfo = useSelector((state) => state.clients.clientInfo)
  const client = useMemo(() => clientInfo, []) // freeze client in memory on open dialog
  const currencies = useExchangesApi(client)

  const history = useHistory()
  const intl = useIntl()

  const initPosition = useMemo(() => position, []) // exist on update/close position
  const initRequest = useMemo(() => request, []) // exist on repeat request from list
  const isEmptyForm = useMemo(() => isEmpty(initPosition), []) // we open create position form
  const isOpenRequest = useMemo(() => checkIsOpenRequest(type || initRequest.type, side || initRequest.orderSide), [])
  const isCloseRequest = useMemo(() => type && side && !isOpenRequest, [])

  const [market, setMarket] = useState({
    currentDifference: '',
    futuresPrice: '',
    spotPrice: '',
    pristine: true, // ui marker
  })
  const [initialized, setInitialized] = useState(false)
  const [calculating, setCalculating] = useState(false)
  const [executing, setExecuting] = useState(false)
  const [futuresMarketPrice, setFuturesMarketPrice] = useState(null)
  const [popup, setPopup] = useState({
    message: '',
    severity: 'success',
    open: false,
  })

  const title = useMemo(() => {
    return type && side
      ? isOpenRequest
        ? `Open ${type} (${side})`
        : `Close ${type} (${side})`
      : 'Request info'
  }, [])

  const initialValues = useMemo(() => {
    let initForm = {}

    if (isEmptyForm) {
      initForm = {
        symbol: '',
        leftCurrency: '',
        rightCurrency: '',
        amount: 0,
        lotAmount: 0,
        futuresPremium: 0,
        currentPosition: 0,
        leverage: DEFAULT_LEVERAGE,
        isolated: DEFAULT_ISOLATED,
      }

      if (isOpenRequest) {
        initForm.asset = initPosition?.quoteAsset ?? initPosition?.baseAsset
        initForm.available = ''
        initForm.futuresP = ''
      }

      return initForm
    }

    let rightCurrency = initPosition?.asset?.right ?? initPosition?.quoteAsset

    if (position.exchangeSide === 'LEFT') {
      for (const rightPosition of client?.rightAccount?.openPositions || []) {
        if (rightPosition.symbol === initPosition.symbol) {
          rightCurrency = rightPosition.quoteAsset
        }
      }
    }

    initForm = {
      amount: 0,
      lotAmount: 0,
      futuresPremium: 0,
      currentPosition: 0,
      ...initPosition,
      ...initRequest,
      symbol: initPosition.baseAsset,
      leftCurrency: initPosition.symbol.replace(initPosition.baseAsset, ''),
      rightCurrency,
      asset: isOpenRequest ? initPosition?.quoteAsset : initPosition?.baseAsset,
    }

    return initForm
  }, [])

  // fill currencies after load
  useEffect(() => {
    if (isEmptyForm) {
      const leftCurrency = getDefaultCurrency(client.leftAccount, client.leftAccount.exchange, currencies.leftCurrencies, 'USDT')
      setFieldValue('leftCurrency', leftCurrency)
      setFieldValue('rightCurrency', getDefaultCurrency(client.rightAccount, client.rightAccount.exchange, currencies.rightCurrencies, 'USD') || leftCurrency)
      setInitialized(true)
    } else {
      readPosition(guid, initPosition.symbol, '', client.leftAccount.exchange)
        .then(() => {
          setInitialized(true)
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencies])

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      symbol: yup
        .string()
        .trim(intl.formatMessage({ id: 'messages.space' }))
        .required(intl.formatMessage({ id: 'messages.required' })),
      amount: yup.number().required(intl.formatMessage({ id: 'messages.required' })),
      lotAmount: yup.number().required(intl.formatMessage({ id: 'messages.required' })),
      futuresPremium: yup.number().required(intl.formatMessage({ id: 'messages.required' })),
    })
  }, [intl])

  const startExecution = useCallback(async (formValues) => {
    setExecuting(true)

    const executionRequest = {
      type,
      side,
      guid,
      amount: formValues.amount,
      futuresPremium: formValues.futuresPremium,
      lotAmount: formValues.lotAmount,
      leverage: formValues.leverage,
      symbol: `${formValues.symbol}${formValues.leftCurrency}`,
      isolated: formValues.isolated,
      asset: {
        base: formValues.symbol,
        left: formValues.leftCurrency,
        right: formValues.rightCurrency,
      }
    }

    try {
      const request = await requestService.startExecution(executionRequest)

      if (request.status === 200) {
        setPopup({
          message: 'Request execution started successfully',
          severity: 'success',
          open: true,
        })

        history.push(`/request/${request.data.id}`)
      } else {
        throw Error(request.data.message)
      }
    } catch (err) {
      setPopup({
        message: 'Could not start request: ' + err,
        severity: 'error',
        open: true,
      })

      setExecuting(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    values,
    errors,
    touched,
    setTouched,
    handleSubmit,
    setFieldValue,
    validateForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: startExecution,
  })

  const readPositionDebounced = debounce(getFuturesCurrentPrice, 400)

  useEffect(() => {
    async function calculateBnbAvailability() {
      const bnbPrice = await marketService.getMarketPrice('BNBUSDT', BINANCE_SPOT)

      const availableSpot = clientInfo?.rightAccount?.balance?.BNB?.free
        ? clientInfo.rightAccount.balance.BNB.free * bnbPrice?.bidPrice
        : 0
      const availableFutures = clientInfo?.leftAccount?.balance?.BNB?.free
        ? clientInfo.leftAccount.balance.BNB.free * bnbPrice?.bidPrice
        : 0

      if (
        Number(availableSpot) < clientInfo?.estimatedTotalBalance * 0.0002 ||
        Number(availableFutures) < clientInfo?.estimatedTotalBalance * 0.00008
      ) {
        setPopup({
          message: 'BNB amount is insufficient',
          severity: 'error',
          open: true,
        })
      }
    }

    calculateBnbAvailability()
  }, [])

  async function getFuturesCurrentPrice(symbol, leftAsset) {
    const completedSymbol = symbol.length > 6 ? symbol : `${symbol}${leftAsset}`
    const data = await marketService.getMarketPrice(
      completedSymbol,
      client.leftAccount.exchange,
    )

    setFuturesMarketPrice(data?.bidPrice)
  }

  async function readPosition(guid, symbol, leftAsset, exchange) {
    try {
      const completedSymbol = symbol.length > 3 ? symbol : `${symbol}${leftAsset}`
      const position = await clientPositionService.getClientPosition(guid, completedSymbol, exchange)

      const hasOpenPosition = position.currentPosition && Number(position.currentPosition) !== 0
      const leverage = !hasOpenPosition ? DEFAULT_LEVERAGE : position.leverage
      const isolated = !hasOpenPosition && !position.isolated ? true : position.isolated

      setFieldValue('leverage', leverage)
      setFieldValue('isolated', isolated)
      setFieldValue('asset', isOpenRequest ? position.quoteAsset : position.baseAsset)
    } catch (e) {
      console.log(`Cannot find position for client ${guid} and symbol ${symbol} on ${exchange}`)
    }
  }

  const calculateDifference = useCallback(async (formValues) => {
    const invalidFields = await validateForm()

    if (!isEmpty(invalidFields)) {
      setTouched(invalidFields)
      setErrors(invalidFields)

      return
    }

    setCalculating(true)

    try {
      const amount = type === SHORT && isOpenRequest ? 'quoteAmount' : 'baseAmount'
      const req = {
        guid,
        side,
        symbol: `${formValues.symbol}${formValues.leftCurrency}`,
        asset: {
          base: formValues.symbol,
          left: formValues.leftCurrency,
          right: formValues.rightCurrency,
        },
        [amount]: formValues.lotAmount === 0 ? '' : formValues.lotAmount,
      }
      const marketDetails = await marketService.getMarketDetails(req)

      if (marketDetails.error) {
        throw new Error(marketDetails.message)
      }

      setMarket(marketDetails)
    } catch (e) {
      setPopup({
        message: 'Cannot calculate difference: ' + e.message,
        severity: 'error',
        open: true,
      })
    } finally {
      setCalculating(false)
    }
  }, [])

  const handleLotsChange = useCallback((value) => {
    setFieldValue('lotAmount', value)
  }, [setFieldValue])

  const handleInputChange = useCallback((event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.id

    setFieldValue(name, value)
  }, [setFieldValue])

  useEffect(() => {
    if (values.symbol && values.symbol.length >= 3 && values.leftCurrency) {
      readPositionDebounced(values.symbol, values.leftCurrency)
    } else {
      setFuturesMarketPrice(null)
    }
  }, [values.symbol, values.leftCurrency])

  const changeRequest = (name, value) => () => setFieldValue(name, value)

  const closePopup = () => setPopup((prev) => ({ ...prev, open: false }))

  return (
    <div className={s.root}>
      {!viewForm && (
        <>
          <PopUp {...popup} closePopup={closePopup} />
          <Typography className={s.title} variant="h5">
            {title}
          </Typography>
        </>
      )}
      <div className={s.formContainer}>
        {!initialized && (
          <div className={s.formContainerLoader}>
            <CircularProgress size={16} marginRight={5} />
            <div className={s.formContainerLoaderLabel}>Initialization...</div>
          </div>
        )}
        {executing && (
          <div className={s.formContainerLoader}>
            <CircularProgress size={16} marginRight={5} />
            <div className={s.formContainerLoaderLabel}>Opening...</div>
          </div>
        )}
        <RequestInputBox
          values={values}
          errors={errors}
          touched={touched}
          cid={client?.cid}
          currencies={currencies}
          client={client}
          onChangeHandler={handleInputChange}
          onClickHandler={changeRequest}
          onLotsChange={handleLotsChange}
          calculate={calculateDifference}
          isOpenRequest={isOpenRequest}
          isCloseRequest={isCloseRequest}
          spotBalance={client?.rightAccount?.balance}
          openPositions={client?.leftAccount?.openPositions}
          futuresBalance={client?.leftAccount?.balance}
          futuresMarketPrice={futuresMarketPrice}
          type={type}
          className={s.inputBox}
          viewForm={viewForm}
          isEmptyForm={isEmptyForm}
        />
        {!viewForm && (
          <RequestMarketBox
            className={s.marketBox}
            onSubmit={handleSubmit}
            market={market}
            loader={calculating && (
              <div className={s.formContainerLoader}>
                <CircularProgress size={16} marginRight={5} />
                <div className={s.formContainerLoaderLabel}>Calculation...</div>
              </div>
            )}
          />
        )}
      </div>
    </div>
  )
}

export default RequestCreate
