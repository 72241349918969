import Typography from '@material-ui/core/Typography'
import ClientCard from './ClientCard/ClientCard'
import suspendingService from '../../service/SuspendingService'
import { Button, Divider } from '@material-ui/core'
import { useClients } from '../../hooks/use-clients'
import { useState, useCallback } from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

const HomeClient = () => {
  const { activeClients, archivedClients } = useClients()

  const [allExpanded, setAllExpanded] = useState(false)
  const [archivedHidden, setArchivedHidden] = useState(true)

  const changeClientState = useCallback(async (client) => {
    if (client.suspended) {
      await suspendingService.activateClient(client.cid)
    } else {
      await suspendingService.suspendClient(client.cid)
    }
  }, [])

  const changeClientPositionState = useCallback(async (cid, position) => {
    if (position.suspended) {
      await suspendingService.activateClientSymbol(cid, position.symbol)
    } else {
      await suspendingService.suspendClientSymbol(cid, position.symbol)
    }
  }, [])

  const toggleAllExpanded = useCallback(() => {
    setAllExpanded((prev) => !prev)
  }, [])

  const toggleArchivedHidden = useCallback(() => {
    setArchivedHidden((prev) => !prev)
  }, [])

  return (
    <div className="column">
      <Typography variant="h4" gutterBottom>
        Clients
      </Typography>
      <Button
        onClick={toggleAllExpanded}
        variant={'contained'}
        style={{ marginLeft: 'auto', marginBottom: 20 }}
      >
        {allExpanded ? 'Shrink all' : 'Expand all'}
      </Button>
      {activeClients.map(
        (client) =>
          (
            <ClientCard
              key={client.guid}
              client={client}
              changeClientState={changeClientState}
              changeClientPositionState={changeClientPositionState}
              allExpanded={allExpanded}
            />
          ),
      )}

      <Divider />

      <div
        onClick={toggleArchivedHidden}
        className="centered-line cursor"
        style={{ margin: '24px 0' }}
      >
        <Typography variant="h4">Archived Clients</Typography>
        <ArrowDropDownIcon
          style={{
            transition: 'ease-out .1s',
            transform: archivedHidden ? 'rotate(90deg)' : '',
            fontSize: 30,
          }}
          fontSize="small"
        />
      </div>
      {!archivedHidden &&
        archivedClients.map(
          (client) =>
            (
              <ClientCard
                key={client.guid}
                client={client}
                changeClientState={changeClientState}
                changeClientPositionState={changeClientPositionState}
              />
            ),
        )}
    </div>
  )
}

export default HomeClient
