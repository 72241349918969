import instance from './axiosConfig'

class NotificationsService {
  async sendNotification(message, destination) {
    return await instance.post(`/api/notification/sendMessage`, { message, destination })
  }
}

const notificationsService = new NotificationsService()
export default notificationsService
