//todo: dynamic config (depends on .env)

const config = {
  cognito: {
    //REGION: "eu-west-1",
    //USER_POOL_ID: "eu-west-1_X1uXDZudQ",
    APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    //IDENTITY_POOL_ID: "eu-west-1:7fe2aa54-e3a6-4630-add0-6ae6b9652e1f",
    REDIRECT: process.env.REACT_APP_REDIRECT_URL,
    BASE_URL: 'https://auth.internal-fun-chegonibudj.com',
  },
}

export default config
