import instance from './axiosConfig'

class ConfigurationService {
  async getFullConfig() {
    const response = await instance.get(`/api/config`)
    return response.data
  }

  async updateConfig(config) {
    const response = await instance.post(`/api/config`, config)
    return response.data
  }

  async getExchangeConfig() {
    const response = await instance.get(`/api/exchange/config`)
    return response.data
  }

  async updateExchangeConfig(config) {
    return await instance.put(`/api/exchange/config`, config)
  }

  async getEligibleCurrencies(exchange) {
    if (!exchange) {
      return []
    }

    const response = await instance.get(`/api/exchange/eligible-currencies?exchange=${exchange}`)

    return response.data
  }
}

const configurationService = new ConfigurationService()
export default configurationService
