import s from './ValidationDialog.module.css'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import { useState } from 'react'
import { useEffect } from 'react'

const ValidationDialog = ({ open, handleSubmit, handleClose, qr }) => {
  const [otpCode, setOtpCode] = useState('')

  const handleChange = (e) => {
    setOtpCode(e.target.value)
  }

  useEffect(() => {
    if (!open) {
      setOtpCode('')
    }
  }, [open])

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className={s.dialog}>
        <DialogTitle className={s.dialogTitle}>
          <div className={s.dialogTitle}>{qr ? 'Scan QR Code' : 'Enter OTP code'}</div>
        </DialogTitle>
        <DialogContent className={s.dialogContent}>
          {qr && <img src={qr} alt="QR Code" />}
        </DialogContent>
        <DialogActions style={{ width: '100%' }}>
          <div className={s.dialogActions}>
            <TextField
              label={'OTP code'}
              value={otpCode}
              onChange={handleChange}
              variant="outlined"
              size="small"
            />
            <Button
              onClick={() => handleSubmit(otpCode)}
              variant="contained"
              color="primary"
              autoFocus
            >
              Confirm
            </Button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default ValidationDialog
