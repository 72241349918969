import clientService from '../../../../service/ClientService'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import s from './RefreshModalButton.module.css'
import IconButton from '@material-ui/core/IconButton'
import RefreshIcon from '@material-ui/icons/Refresh'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import React, { useState } from 'react'
import { Button, makeStyles, Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'
import PopUp from '../../../common/PopUp'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const RefreshModalButton = ({ refreshing, cid }) => {
  const [open, setOpen] = useState(false)
  const [changingStatus, setChangingStatus] = useState(false)
  const [popup, setPopup] = useState({
    message: '',
    severity: 'success',
    open: false,
  })

  const classes = useStyles()

  const startRefresh = async () => {
    setChangingStatus(true)
    await clientService.startRefresh(cid)
    setChangingStatus(false)
  }

  const stopRefresh = async () => {
    setChangingStatus(true)
    await clientService.stopRefresh(cid)
    setChangingStatus(false)
  }

  const status = refreshing ? (
    <span style={{ color: 'green' }}>ON</span>
  ) : (
    <span style={{ color: 'red' }}>OFF</span>
  )

  const closePopup = () => setPopup((prev) => ({ ...prev, open: false }))

  return (
    <>
      <Button
        style={{ justifyContent: 'flex-start' }}
        color="primary"
        aria-label="start refresh"
        onClick={() => setOpen(true)}
      >
        <RefreshIcon />
        &nbsp;Start Refresh
      </Button>
      <Modal className={s.modal} open={open} onClose={() => setOpen(false)}>
        <Paper className={s.paper}>
          <PopUp {...popup} closePopup={closePopup} />
          <Backdrop open={changingStatus} className={classes.backdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className={s.heading}>
            <Typography variant="h6">Client Refresh</Typography>
            <IconButton size="small" onClick={() => setOpen(false)}>
              <CloseRoundedIcon />
            </IconButton>
          </div>
          <Typography variant="h4">Current refresh status: {status}</Typography>
          <Button
            onClick={refreshing ? stopRefresh : startRefresh}
            variant={'contained'}
            color={refreshing ? 'secondary' : 'primary'}
            disabled={changingStatus}
          >
            {refreshing ? 'STOP' : 'START'}
          </Button>
        </Paper>
      </Modal>
    </>
  )
}

export default RefreshModalButton
