import { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { clientsActions } from '../redux/slices/clients-slice'
import clientPositionService from '../service/ClientPositionService'
import webSocketService, { CLIENT_TOPIC } from '../service/WebSocketService'
import equal from 'fast-deep-equal'

export const recalcClientSpotBalance = (client) => {
  const newSpotBalance = { ...client.rightAccount.balance }

  client.leftAccount.openPositions.forEach((position) => {
    if (!client.rightAccount.balance[position.baseAsset]) {
      newSpotBalance[position.baseAsset] = {
        asset: position.baseAsset,
        free: 0,
        locked: 0,
        total: 0,
        value: null,
        isPosition: true,
      }
    }
  })

  return {
    ...client,
    rightAccount: { ...client.rightAccount, balance: newSpotBalance },
  }
}

export const useClients = () => {
  const [clients, setClients] = useState(new Map())

  const [subscribeId, setSubscribeId] = useState(null)

  const dispatch = useDispatch()

  const parseClients = useCallback((clients) => {
    const newClientsMap = new Map()

    const newClients = clients?.map(client => {
      const parsed = recalcClientSpotBalance(client)
      newClientsMap.set(parsed.guid, parsed)

      return parsed
    })

    setClients(newClientsMap)
    dispatch(clientsActions.setClients(newClients))
  }, [])

  useEffect(() => {
    async function fetchData() {
      dispatch(clientsActions.setIsFetching(true))

      const id = webSocketService.subscribe(CLIENT_TOPIC, (message) => handleUpdate(message))
      setSubscribeId(id)

      const clients = await clientPositionService.getAllClientPositions()
      parseClients(clients)

      dispatch(clientsActions.setIsFetching(false))
    }
    fetchData()

    return () => {
      if (subscribeId) {
        webSocketService.unsubscribe(CLIENT_TOPIC, subscribeId)
      }
    }
  }, [])

  const updateClient = useCallback((client) => {
    const parsed = recalcClientSpotBalance(client)
    setClients(prev => {
      prev.set(parsed.guid, parsed)

      return new Map(prev)
    })
    dispatch(clientsActions.setClient(parsed))
  }, [dispatch])

  const prevClient = useRef(new Map())

  const handleUpdate = (message) => {
    if (message.client) {
      if (!equal(prevClient.current.get(message.client.guid), message.client)) {
        prevClient.current.set(message.client.guid, message.client)
        updateClient(message.client, 'one')
      }
    }

    if (message.clients) {
      for (const client of message.clients) {
        if (!equal(prevClient.current.get(client.guid), client)) {
          prevClient.current.set(client.guid, client)
          updateClient(client, 'list')
        }
      }
    }
  }

  const { activeClients, archivedClients } = useMemo(() => {
    const activeClients = []
    const archivedClients = []

    for (const [,client] of clients) {
      if (client.archived) {
        archivedClients.push(client)
      } else {
        activeClients.push(client)
      }
    }

    return { activeClients, archivedClients }
  }, [clients])

  return { clients, activeClients, archivedClients }
}
