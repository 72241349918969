import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

instance.interceptors.request.use(function (config) {
  const headers = authHeader()
  if (headers) {
    config.headers = { ...config.headers, ...headers }
  }
  return config
})

instance.interceptors.response.use(undefined, (err) => {
  return err.response
})

export function authHeader() {
  const token = localStorage.getItem('token')
  if (token) {
    return { Authorization: 'Bearer ' + token }
  } else {
    return {}
  }
}

class UserService {
  async getUser() {
    const response = await instance.get(`/api/user/me`)
    return response.data
  }

  async mfaGenerate() {
    return await instance.post(`/api/mfa/generate`)
  }

  async mfaValidate(otpCode) {
    return await instance.post(`/api/mfa/validate`, String(otpCode), {
      headers: {
        'Content-Type': 'text/plain',
      },
    })
  }

  async mfaReset(approverEmail, approverOTP) {
    return await instance.post(`/api/mfa/reset`, { approverEmail, approverOTP })
  }

  async mfaAuthenticate(otpCode) {
    return await instance.post(`/api/mfa/authenticate`, String(otpCode), {
      headers: {
        'Content-Type': 'text/plain',
      },
    })
  }

  async placeOrder(guid, symbol, amount, side, exchange, reduceOnly) {
    const response = await instance.post(`/api/client/${guid}/order`, {
      symbol,
      amount,
      side,
      exchange,
      reduceOnly,
    })
    return response
  }
}

const userService = new UserService()
export default userService
