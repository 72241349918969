import Dialog from '@material-ui/core/Dialog'
import s from './MarginDialog.module.css'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import TextField from '@material-ui/core/TextField'
import { USDT } from '../../../../../model/Asset'
import Button from '@material-ui/core/Button'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import PopUp from '../../../../common/PopUp'
import futuresService from '../../../../../service/FuturesService'

const titleStyle = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(titleStyle)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const MarginDialog = ({
  onClose,
  onSuccess,
  open,
  guid,
  futuresBalance,
  symbol,
  exchange,
  action = 'add',
}) => {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [available, setAvailable] = useState(0)
  const [amount, setAmount] = useState('')
  const [popup, setPopup] = useState({
    message: '',
    severity: 'success',
    open: false,
  })

  const calculateAvailable = useCallback(() => {
    const assetBalance = futuresBalance[USDT]
    return assetBalance ? assetBalance.free : '0'
  }, [futuresBalance])

  const fetchFreeMargin = async () => {
    setLoading(true)

    const data = await futuresService.getRemovableMargin(symbol, guid, exchange)
    setAvailable(data.amount)

    setLoading(false)
  }

  useEffect(() => {
    if (action === 'remove' && open) {
      fetchFreeMargin()
    }
  }, [action, open])

  useEffect(() => {
    setError('')
    setAmount('')
    setPopup((prev) => ({ ...prev, open: false }))
  }, [open])

  useEffect(() => {
    if (action === 'add') {
      setAvailable(calculateAvailable())
    }
  }, [futuresBalance, action])

  const changeAmount = (value) => {
    setAmount(value)
  }

  const confirmTransfer = async () => {
    if (!amount) {
      setError(`Margin amount ${amount.transfer} is missing`)
      return
    }
    if (error) {
      setError('')
    }
    setLoading(true)
    try {
      let response
      if (action === 'add') {
        response = await futuresService.addMarginToPosition(symbol, guid, amount)
      } else {
        response = await futuresService.removeMarginFromPosition(symbol, guid, amount)
      }
      if (response.error) {
        throw new Error(response.message)
      }

      setAvailable((prev) => prev - amount)
      setPopup({
        message: `Margin operation successfully done`,
        severity: 'success',
        open: true,
      })
      onSuccess(guid)
    } catch (e) {
      setPopup({
        message: `Margin operation failed. Error: ${e.message}`,
        severity: 'error',
        open: true,
      })
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    onClose()
  }

  const closePopup = () => setPopup((prev) => ({ ...prev, open: false }))

  return (
    <Dialog
      PaperProps={{ style: { width: 550, padding: '0 16px' } }}
      onClose={handleClose}
      open={open}
    >
      <PopUp {...popup} closePopup={closePopup} />
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <CircularProgress color="primary" />
      </Backdrop>
      <DialogTitle id="dialog-title" onClose={handleClose}>
        {action === 'add' ? `Add margin to ${symbol}` : `Remove margin from ${symbol}`}
      </DialogTitle>
      <div className={s.transfer + ' column'}>
        <TextField
          id="amount"
          label="Amount"
          variant="outlined"
          fullWidth
          onChange={(e) => changeAmount(e.target.value)}
          type="number"
          value={amount}
          error={!!error}
          helperText={error}
        />
        <Typography variant="body1">
          {action === 'add' ? 'Available futures balance: ' : 'Free margin available: '}
          <strong className={'link'} onClick={() => changeAmount(available)}>
            {available}
          </strong>{' '}
          USDT{' '}
        </Typography>
        <Button variant="contained" onClick={(e) => confirmTransfer()}>
          Confirm
        </Button>
      </div>
    </Dialog>
  )
}

MarginDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  guid: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired,
  exchange: PropTypes.string.isRequired,
  action: PropTypes.string,
  futuresBalance: PropTypes.object.isRequired,
}

export default MarginDialog
