import { parseDate } from '../../../utilities/number-helpers'
import { Box } from '@material-ui/core'

const getDateColor = (value) => {
  const now = new Date().getTime()
  if (value) {
    if (value < now) {
      return 'error.main'
    } else {
      return 'text.primary'
    }
  }
}

export const COLUMNS = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Last execution',
    accessor: 'lastExecutionTs',
    Cell: ({ value }) => parseDate(value),
  },
  {
    Header: 'Next execution',
    accessor: 'nextExecutionTs',
    Cell: ({ value }) => <Box color={getDateColor(value)}>{parseDate(value)}</Box>,
  },
]
